import {assign, isEmpty, isNumber, keys, pick, reduce} from 'lodash';
import {EDITABLE_FIELDS_NAMES, REQUIRED_FIELDS_LABELS, REQUIRED_PASSWORD_FIELDS_LABELS} from '../constants/account';
import {
	PASSWORD_RECOVERY_COMPLETED,
	PASSWORD_RECOVERY_ERRORS,
	PASSWORD_RECOVERY_FAILED,
	PASSWORD_RECOVERY_REQUESTED,
	PASSWORD_RECOVERY_VALIDATED,
	PASSWORD_UPDATE_FAILED,
	PASSWORD_UPDATE_SUCCESS,
	USER_UPDATE_FAILED
} from '../constants/user-details';
import {loginAttemptSuccess} from './login';
import {
	passwordRecoveryAttempt as passwordRecoveryAttemptApi,
	passwordReset,
	passwordRestoreValidate,
	updateUserDetails,
	updateUserPassword
} from '../mah-api';
import {gettext} from '../util/l10n';
import {push} from 'react-router-redux';

const accountPasswordRecoveryRequested = (email) => {
	return {
		type: PASSWORD_RECOVERY_REQUESTED,
		payload: {
			email
		}
	};
};

const accountPasswordRecoveryFailed = () => {
	return {
		type: PASSWORD_RECOVERY_FAILED,
		payload: {}
	};
};

const saveAccountFailed = (errorDetails, userData) => {
	return {
		type: USER_UPDATE_FAILED,
		payload: {
			error: errorDetails.validation_errors,
			userData
		}
	};
};

const updatePasswordFailed = (errorDetails) => {
	return {
		type: PASSWORD_UPDATE_FAILED,
		payload: {
			error: errorDetails.validation_errors
		}
	};
};

const updatePasswordSuccess = () => {
	return {
		type: PASSWORD_UPDATE_SUCCESS,
		payload: {}
	};
};

const basicDataValidation = (userData) => {
	const requiredFields = keys(REQUIRED_FIELDS_LABELS),
		errors = reduce(requiredFields, (errors, fieldName) => {
			const fieldValue = userData[fieldName],
				valueIsEmpty = isEmpty(fieldValue) && !isNumber(fieldValue);
			if (valueIsEmpty) {
				errors[fieldName] = [`${REQUIRED_FIELDS_LABELS[fieldName]} should not be empty`];
			}
			return errors;
		}, {});

	if (!isEmpty(errors)) {
		return Promise.reject({
			response: {validation_errors: errors}
		});
	}
	return Promise.resolve();
};

const basicPasswordValidation = (passwordData) => {
	const requiredFields = keys(REQUIRED_PASSWORD_FIELDS_LABELS),
		errors = reduce(requiredFields, (errors, fieldName) => {
			const fieldValue = passwordData[fieldName],
				valueIsEmpty = isEmpty(fieldValue) && !isNumber(fieldValue);
			if (valueIsEmpty) {
				errors[fieldName] = [`${REQUIRED_PASSWORD_FIELDS_LABELS[fieldName]} should not be empty`];
			}
			return errors;
		}, {});


	if (passwordData['new_password'] !== passwordData['confirm_password']) {
		errors['new_password'] = ['Passwords should match'];
		errors['confirm_password'] = [gettext('Passwords should match')];
	}

	if (passwordData['new_password'].length < 6) {
		errors['new_password'] = [gettext('The new password must be at least 6 characters.')];
	}

	if (!isEmpty(errors)) {
		return Promise.reject({
			response: {validation_errors: errors}
		});
	}
	return Promise.resolve();
};

export const saveAccountAttempt = (userData) => {
	return (dispatch) => {
		const dataToSend = pick(userData, EDITABLE_FIELDS_NAMES);
		return basicDataValidation(dataToSend)
			.then(() => updateUserDetails(dataToSend))
			.then(user => {
				dispatch(loginAttemptSuccess(user));
				dispatch(push('/'));
			})
			.catch(error => dispatch(saveAccountFailed(error.response, userData)));
	};
};

export const changePasswordAttempt = (passwordData) => {
	return (dispatch) => {
		return basicPasswordValidation(passwordData)
			.then(() => updateUserPassword(passwordData))
			.then(() => {
				dispatch(updatePasswordSuccess());
			})
			.catch(error => dispatch(updatePasswordFailed(error.response)));
	};
};

export const passwordRecoveryAttempt = (email) => {
	return (dispatch) => {
		return passwordRecoveryAttemptApi(email)
			.then(() => {
				dispatch(accountPasswordRecoveryRequested(email));
				dispatch(push('/password-recovery-email-sent'));
			})
			.catch(() => {
				dispatch(accountPasswordRecoveryFailed());
			});
	};
};

const passwordRecoveryValidated = () => {
	return {
		type: PASSWORD_RECOVERY_VALIDATED,
		payload: {
			passwordRecoveryResult: {success: true}
		}
	};
};

const passwordRecoveryErrors = (errors) => {
	const passwordRecoveryErrors = errors.validation_errors || {generic: gettext('Cant validate token')};

	return {
		type: PASSWORD_RECOVERY_ERRORS,
		payload: {
			passwordRecoveryErrors
		}
	};
};

const passwordRecoveryCompleted = () => {
	return {
		type: PASSWORD_RECOVERY_COMPLETED,
		payload: {}
	};
};

export const passwordRecoveryReset = (passwordData, activationToken) => {
	return (dispatch) => {
		return passwordReset(assign({}, passwordData, {restore_key: activationToken}))
			.then(() => {
				dispatch(passwordRecoveryCompleted(activationToken));
				dispatch(push('/auth/password/restore/complete'));
			})
			.catch(err => {
				dispatch(passwordRecoveryErrors(err));
			});
	};
};

export const passwordRecoveryValidate = (activationToken) => {
	return (dispatch) => {
		return passwordRestoreValidate({restore_key: activationToken})
			.then(() => {
				dispatch(passwordRecoveryValidated(activationToken));
			})
			.catch((err) => {
				dispatch(passwordRecoveryErrors(err));
			});
	};
};