import {fetchUserSelf, loginAttempt, logoutUser} from '../mah-api';
import {LOGIN_ATTEMPT, LOGIN_ATTEMPT_FAILURE, LOGIN_ATTEMPT_SUCCESS, USER_LOGGED_OUT} from '../constants/user-details';
import {REDIRECTED, SET_REDIRECT_URL} from '../constants/iteractions';
import {push} from 'react-router-redux';
import {loadActiveBookings, loadCurrentVessel, loadUserVessels, resetBookingPrice} from './';
import {ensureClassifiersLoaded} from '../util/action-util/index';
import {Cookies} from 'react-cookie';
import {socialLoginAttempt} from '../mah-api/index';
import logger from '../util/logger';

const cookie = new Cookies();

const redirected = () => {
	return {
		type: REDIRECTED,
		payload: {}
	};
};

const saveUrlForRedirect = (url) => {
	return {
		type: SET_REDIRECT_URL,
		payload: {
			url
		}
	};
};

const loginInProgress = () => {
	return {
		type: LOGIN_ATTEMPT,
		payload: {}
	};
};

export const loginAttemptSuccess = (user) => {
	return {
		type: LOGIN_ATTEMPT_SUCCESS,
		payload: {
			user
		}
	};
};

const loginSuccess = (user) => {
	return ensureClassifiersLoaded(['country', 'currency', 'size_unit'], (dispatch) => {
		dispatch(loginAttemptSuccess(user));
		dispatch(resetBookingPrice());
		dispatch(loadUserVessels());
		dispatch(loadCurrentVessel());
		dispatch(loadActiveBookings());
	});
};
const userLoggedOut = () => {
	return {
		type: USER_LOGGED_OUT,
		payload: {}
	};
};

const loginFailed = (username, errorDetails) => {
	return {
		type: LOGIN_ATTEMPT_FAILURE,
		payload: {
			username,
			error: errorDetails.error
		}
	};
};

export const logout = () => {
	return dispatch => {
		cookie.remove('activeVesselId');
		dispatch(userLoggedOut());
		return logoutUser()
			.then(() => {
				dispatch(push('/login'));
			})
			.catch(() => {
				dispatch(push('/login'));
			});
	};
};

export const validateLogin = (nextUrl) => {
	return (dispatch, getState) => {
		if (getState().auth.notLoggedIn) {
			dispatch(saveUrlForRedirect(nextUrl));
			return dispatch(push('/login'));
		}
		return fetchUserSelf()
			.then(user => {
				dispatch(loginSuccess(user));
				dispatch(push(nextUrl));
				dispatch(redirected());
			})
			.catch(() => {
				dispatch(saveUrlForRedirect(nextUrl));
				dispatch(userLoggedOut());
				dispatch(push('/login'));
			});
	};
};

export const validateLoggedOut = (nextUrl) => {
	return (dispatch, getState) => {
		if (getState().auth.notLoggedIn) {
			return dispatch(push(nextUrl));
		}
		return fetchUserSelf()
			.then(user => {
				dispatch(loginSuccess(user));
				dispatch(push('/'));
				dispatch(redirected());
			})
			.catch(() => {
				dispatch(userLoggedOut());
				dispatch(push(nextUrl));
			});
	};
};

export const login = (username, password, redirect) => {
	return dispatch => {
		dispatch(loginInProgress());
		return loginAttempt(username, password)
			.then(user => {
				dispatch(loginSuccess(user));
				dispatch(redirected());
				if (redirect) {
					redirect();
				}
			})
			.catch(error => dispatch(loginFailed(username, error.response)));
	};
};

export const oAuthLoginAttempt = (provider, loginDetails, redirect) => {
	return dispatch => {
		dispatch(loginInProgress());
		return socialLoginAttempt(provider, loginDetails)
			.then(() => {
				if (redirect) {
					redirect();
				}
				dispatch(redirected());
			})
			.catch(error => {
				logger.error('unable to social login', error);
				if (redirect) {
					redirect(error);
				}
			});
	};
};