import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {filter, has, isEmpty} from 'lodash';
import {gettext} from '../../util/l10n';
import {detectUserCountry} from '../../actions';
import SimpleInputField from '../core/fields/simple-input';

const RegistrationFields = [
	{name: 'firstname', fieldLabel: 'first name'},
	{name: 'lastname', fieldLabel: 'last name'},
	{name: 'username', fieldLabel: 'email'},
	{name: 'password', fieldLabel: 'password', hasSecureValue: true}
];

class UserRegistrationForm extends Component {
	constructor(props, context) {
		super(props, context);
		this.state = {};
		this.handleFieldChange = this.handleFieldChange.bind(this);
		this.onSubmit = this.onSubmit.bind(this);
	}

	componentWillMount() {
		this.props.detectUserCountry();
	}

	areNotEmpty(...items) {
		return !filter(items, (item) => isEmpty(this.state[item])).length;
	}

	handleFieldChange(propertyName) {
		return (value) => {
			return this.setState({[propertyName]: value});
		};
	}

	onSubmit(e) {
		e.preventDefault();
		const {firstname, lastname, username, password} = this.state,
			{country} = this.props;
		let registrationData = {firstname, lastname, username, password};
		if (!isEmpty(country)) {
			registrationData.country_iso3 = country.iso3;
		}
		this.props.onRegistrationAttempt(registrationData);
	}

	render() {
		const registerButtonIsActive = this.areNotEmpty('username', 'password', 'firstname', 'lastname') ? 'available' : '',
			registerButtonClasses = `btn btn-block ${registerButtonIsActive}`,
			registerAttributes = {};

		if (!registerButtonIsActive) {
			registerAttributes.disabled = 'disabled';
		}

		return (
			<form onSubmit={this.onSubmit} className="user-registration-form">
				<fieldset>
					{RegistrationFields.map((field) => {
						const hasError = has(this.props.fieldErrors, field.name),
							errorMessages = hasError ? this.props.fieldErrors[field.name] : '';

						return (
							<SimpleInputField key={field.name} {...field}
											  value={this.state[field.name]}
											  handleChange={this.handleFieldChange(field.name)}
											  errorMessages={errorMessages}/>
						);
					})}
					<div className="form-group">
						<button {...registerAttributes} type="submit"
								className={registerButtonClasses}>{gettext('Register')}</button>
					</div>
				</fieldset>
			</form>
		);
	}
}

UserRegistrationForm.propTypes = {
	onRegistrationAttempt: PropTypes.func.isRequired,
	detectUserCountry: PropTypes.func.isRequired,
	fieldErrors: PropTypes.object,
	country: PropTypes.object
};

const mapStateToProps = (state) => {
	return {
		fieldErrors: state.registration.errors || {},
		country: state.location.country || {}
	};
};

const mapDispatchToProps = {
	detectUserCountry
};

const UserRegistrationFormConnected = connect(mapStateToProps, mapDispatchToProps)(UserRegistrationForm);

export default UserRegistrationFormConnected;