import React from 'react';
import {MenuItem} from '../menu-item/menu-item';
import {gettext} from '../../../util/l10n';
const items = [
	{
		id: 'map',
		link: '/',
		icon: 'public',
		text: gettext('Map')
	},
	{
		id: 'vessels',
		link: '/vessels',
		icon: 'directions_boat',
		text: gettext('Vessels')
	},
	{
		id: 'account',
		link: '/account',
		icon: 'account_circle',
		text: gettext('Account')
	},
	{
		id: 'about',
		link: '/about',
		icon: 'info_outline',
		text: gettext('About')
	}
];

const MenuItems = (props) => {
	const toggleMenu = (e) => {
		if (e.target === itemsList) {
			props.toggleMenu();
		}
	};
	let itemsList;

	return (
		<ul className="menu-items" ref={(elem) => itemsList = elem} onClick={toggleMenu}>
			{items.map(menuItem => {
				const menuKey = `menu${menuItem.id}`;
				return (
					<MenuItem key={menuKey} {...menuItem} classNames="menu-item"/>
				);
			})}
		</ul>
	);
};

export default MenuItems;