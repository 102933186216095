import {gettext} from '../util/l10n';

export const CHECK_IN_SUCCESS = '@@mah-user/CHECK_IN_SUCCESS';
export const CHECK_IN_FAILED = '@@mah-user/CHECK_IN_FAILED';
export const CHECK_IN_CURRENT_STATE = '@@mah-user/CHECK_IN_CURRENT_STATE';
export const CHECK_OUT_SUCCESS = '@@mah-user/CHECK_OUT_SUCCESS';
export const CHECK_OUT_FAILED = '@@mah-user/CHECK_OUT_FAILED';

export const CHECK_IN_FIELD_NAMES = ['key_previous_harbour', 'notes_user', 'end_date_time'];

export const CHECK_OUT_FIELDS = [
	[{
		name: 'key_next_harbour', textName: 'text_key_next_harbour',
		fieldLabel: 'Next harbour', type: 'auto-fill', autoCompleteType: 'harbours'
	}]
];

export const CHECK_IN_TYPES = {
	FAST: {
		name: 'fast',
		needsOrder: false
	},
	BOOKING_BASED: {
		name: 'booking-based',
		needsOrder: true
	}
};

const CHECK_IN_FIELDS = [
	{
		forTypes: [CHECK_IN_TYPES.BOOKING_BASED.name],
		row: [{name: 'end_date_time', fieldLabel: 'Check out date', type: 'datetime'}]
	},
	{
		forTypes: [CHECK_IN_TYPES.FAST.name, CHECK_IN_TYPES.BOOKING_BASED.name],
		row: [{
			name: 'key_previous_harbour', textName: 'text_key_previous_harbour',
			fieldLabel: 'Previous harbour', type: 'auto-fill', autoCompleteType: 'harbours'
		}]
	},
	{
		forTypes: [CHECK_IN_TYPES.FAST.name, CHECK_IN_TYPES.BOOKING_BASED.name],
		row: [{name: 'notes_user', fieldLabel: 'Notes to harbour master', type: 'text'}]
	},
	{
		forTypes: [CHECK_IN_TYPES.FAST.name],
		row: [{name: 'rules_agreement', fieldLabel: 'I have read and agree to harbour rules', type: 'checkbox'}]
	}
];

export const CHECK_IN_FIELDS_ERRORS = {
	rules_agreement: gettext('You should agree to harbour rules'),
	end_date_time: gettext('You should select check out date')
};

const CHECK_IN_FIELDS_REQUIREMENTS = [
	{
		forTypes: [CHECK_IN_TYPES.FAST.name],
		field: 'rules_agreement'
	},
	{
		forTypes: [CHECK_IN_TYPES.BOOKING_BASED.name],
		field: 'end_date_time'
	}
];

export const checkInFieldsByType = (checkInType) => {
	const typeName = checkInType.name;

	return CHECK_IN_FIELDS
		.filter(fieldInfo => fieldInfo.forTypes.indexOf(typeName) !== -1)
		.map(fieldInfo => fieldInfo.row);
};

export const checkInRequiredFieldsByType = (checkInType) => {
	const typeName = checkInType.name;

	return CHECK_IN_FIELDS_REQUIREMENTS
		.filter(fieldInfo => fieldInfo.forTypes.indexOf(typeName) !== -1)
		.map(fieldInfo => fieldInfo.field);
};