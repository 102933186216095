import {assignIn, omit} from 'lodash';
import {REGISTRATION_FAILED, REGISTRATION_SUCCESS} from '../constants/user-details';

const registration = (state = {}, action) => {
	const newState = assignIn({}, omit(state, 'errors'));
	switch (action.type) {
		case REGISTRATION_FAILED:
			return {errors: action.payload};
		case REGISTRATION_SUCCESS:
			return assignIn(newState, action.payload);
	}
	return newState;
};

export default registration;