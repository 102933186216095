import fetch from 'isomorphic-fetch';
import fetchJsonp from 'fetch-jsonp';
import {merge, range, reduce} from 'lodash';

const defaultOptions = {
	xhrFields: {
		withCredentials: true
	},
	credentials: 'include'
};

const checkResponseStatus = (response) => {
	if (response.status >= 200 && response.status < 300) {
		return response;
	} else {
		return response.json()
			.then(json => {
				const error = new Error(response.statusText);
				error.response = json;
				error.status = response.status;
				error.statusText = response.statusText;
				throw error;
			});
	}
};

export const doPost = (url, data = {}, options = {}) => {
	let fetchOptions = merge({}, defaultOptions,
		options,
		{
			body: JSON.stringify(data),
			method: 'POST',
			headers: {
				'Accept': 'application/json',
				'Content-Type': 'application/json'
			}
		});
	return fetch(url, fetchOptions)
		.then(checkResponseStatus)
		.then(response => response.json());
};

export const doPut = (url, data = {}, options = {}) => {
	let fetchOptions = merge({}, defaultOptions,
		options,
		{
			body: JSON.stringify(data),
			method: 'PUT',
			headers: {
				'Accept': 'application/json',
				'Content-Type': 'application/json'
			}
		});
	return fetch(url, fetchOptions)
		.then(checkResponseStatus)
		.then(response => response.json());
};

export const doDelete = (url, options = {}) => {
	let fetchOptions = merge({}, defaultOptions,
		options,
		{
			method: 'DELETE',
			headers: {
				'Accept': 'application/json',
				'Content-Type': 'application/json'
			}
		});
	return fetch(url, fetchOptions)
		.then(checkResponseStatus)
		.then(response => response.json());
};

const makeGetRequest = (fetchFn, processResponseFn, url, options = {}) => {
	let fetchOptions = merge({}, defaultOptions,
		options,
		{
			method: 'GET',
			headers: {
				'Accept': 'application/json',
				'Content-Type': 'application/json'
			}
		});
	return fetchFn(url, fetchOptions)
		.then(processResponseFn)
		.then(response => response.json());
};

export const doGet = (url, options = {}) => {
	return makeGetRequest(fetch, checkResponseStatus, url, options);
};

export const doJsonpGet = (url, options = {}) => {
	return makeGetRequest(fetchJsonp, (r) => r, url, options);
};

export const getAllPages = (originalUrl) => {
	return doGet(`${originalUrl}&page=1`)
		.then((data) => {
			const pagination = data.pagination || {last_page: 1},
				pagesToFetch = range(2, pagination.last_page + 1),
				initialData = [data];

			return Promise.all(
				pagesToFetch.map(pageNr => doGet(`${originalUrl}&page=${pageNr}`))
			).then(fetchResults => {
				return reduce(fetchResults, (allData, data) => allData.concat(data), initialData);
			});
		});
};