/* globals require */
window.jQuery = require('jquery');
require('bootstrap-material-design');

import 'babel-polyfill';
import React from 'react';
import {render} from 'react-dom';
import {browserHistory} from 'react-router';
import {syncHistoryWithStore} from 'react-router-redux';
import configureStore from './store/configure-store';
import Root from './containers/core/root';
import {reValidateCache} from './util/cache/cache-provider';

reValidateCache();

const store = configureStore({
	auth: {}
});
const history = syncHistoryWithStore(browserHistory, store);

render(
	<Root store={store} history={history}/>,
	document.getElementById('mah-root')
);
