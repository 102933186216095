import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {find, isEmpty, negate, pickBy, reduce} from 'lodash';
import {connect} from 'react-redux';
import {gettext} from '../../../util/l10n';
import {CHECK_OUT_FIELDS} from '../../../constants/check-in-out';
import {checkOutAttempt, cleanAutoComplete, requestAutoComplete} from '../../../actions';
import {checkOutFields} from './check-out-fields';
import {isEmptyField} from '../../../util/field-util';

const cleanState = () => {
	const checkOutDefaults = reduce(CHECK_OUT_FIELDS, (state, field) => {
		state[field] = '';
		return state;
	}, {});
	checkOutDefaults.crew_total = 1;
	return checkOutDefaults;
};

class CheckOutModal extends Component {
	constructor(props) {
		super(props);
		this.state = cleanState();
		this.handleFieldChange = this.handleFieldChange.bind(this);
		this.hideModal = this.hideModal.bind(this);
		this.checkEscClicked = this.checkEscClicked.bind(this);
		this.onSubmit = this.onSubmit.bind(this);
		this.updateCounter = this.updateCounter.bind(this);
		this.increaseCounter = this.increaseCounter.bind(this);
		this.decreaseCounter = this.decreaseCounter.bind(this);
		this.fillStateFromProps = this.fillStateFromProps.bind(this);
	}

	updateCounter(amount) {
		const {crew_total} = this.state;
		if (crew_total + amount < 1) {
			return;
		}
		this.setState({
			crew_total: crew_total + amount
		});
	}

	increaseCounter(e) {
		e.preventDefault();
		this.updateCounter(1);
	}

	decreaseCounter(e) {
		e.preventDefault();
		this.updateCounter(-1);
	}

	onSubmit(e) {
		e.preventDefault();
		const {activeVesselId, checkOutAttempt, harbour} = this.props,
			{crew_total, key_next_harbour, text_key_next_harbour} = this.state,
			checkOutData = pickBy({
				key_harbour: harbour.id,
				key_user_vessel: activeVesselId,
				key_next_harbour,
				next_harbour: text_key_next_harbour,
				crew_total
			}, negate(isEmptyField));
		checkOutAttempt(checkOutData);
	}

	hideModal(e) {
		e.preventDefault();
		const {hideModal} = this.props;
		hideModal();
	}

	checkEscClicked(e) {
		if (e.which === 27) {
			this.hideModal(e);
		}
	}

	fillStateFromProps(props) {
		const {crew_total, key_next_harbour, next_harbour} = props.current,
			{harboursMarkers} = props,
			nextState = pickBy({
				crew_total,
				key_next_harbour
			}, negate(isEmptyField));
		if (key_next_harbour) {
			let selectedHarbour = find(harboursMarkers, {id: key_next_harbour});
			if (selectedHarbour) {
				nextState.text_key_next_harbour = selectedHarbour.title;
			}
		} else if (!isEmpty(next_harbour)) {
			nextState.text_key_next_harbour = next_harbour;
		}
		this.setState(nextState);
	}

	componentWillMount() {
		this.fillStateFromProps(this.props);
	}

	componentWillReceiveProps(nextProps) {
		this.fillStateFromProps(nextProps);
	}

	handleFieldChange(propertyName) {
		return (value) => {
			this.setState({[propertyName]: value});
		};
	}

	render() {
		const {save_errors, requestAutoComplete, autocomplete, cleanAutoComplete} = this.props,
			handleFieldChange = this.handleFieldChange,
			{crew_total = 1} = this.state,
			reduceCrewTotalClassName = 'material-icons md-24' + (crew_total > 1 ? ' md-active' : ' md-inactive');
		let fieldsErrors = {};
		if (!isEmpty(save_errors)) {
			fieldsErrors.key_next_harbour = save_errors;
		}
		const submitAvailabilityClass = isEmpty(fieldsErrors) ? 'available' : '',
			submitClassNames = `btn btn-main-action ${submitAvailabilityClass}`;

		return (
			<div className="generic-modal-window">
				<div className="generic-modal-window-header-container">
					<div className="generic-modal-window-header-content">
						{gettext('Check out')}
					</div>
					<div className="check-out-modal-counter-controls">
						<a className="btn btn-wout-back" onClick={this.decreaseCounter}>
							<i className={reduceCrewTotalClassName}>remove_circle</i>
						</a>
						<div className="check-out-modal-counter-controls-value">
							{crew_total}
						</div>
						<a className="btn btn-wout-back" onClick={this.increaseCounter}>
							<i className="material-icons md-24 md-active">add_circle</i>
						</a>
					</div>
					<div className="check-out-modal-counter-controls-description">
						{gettext('Passengers')}
					</div>
				</div>
				<form className="generic-modal-window-body container"
					  onKeyUp={this.checkEscClicked}
					  onSubmit={this.onSubmit}>
					{checkOutFields({
						handleFieldChange, requestAutoComplete,
						autocomplete, cleanAutoComplete,
						save_errors: fieldsErrors
					}, this.state)}
					<div className="row generic-modal-window-buttons">
						<button className="btn btn-wout-back available"
								onClick={this.hideModal}>{gettext('Cancel')}</button>
						<button type="submit" className={submitClassNames}>{gettext('Check Out')}</button>
					</div>
				</form>
			</div>
		);
	}
}

CheckOutModal.propTypes = {
	hideModal: PropTypes.func.isRequired,
	autocomplete: PropTypes.object.isRequired,
	checkOutAttempt: PropTypes.func.isRequired,
	save_errors: PropTypes.string,
	current: PropTypes.object,
	activeVesselId: PropTypes.number,
	harboursMarkers: PropTypes.array,
	harbour: PropTypes.object
};

const mapStateToProps = (state) => {
	return {
		save_errors: state.checkInOut.check_out_errors || '',
		current: state.checkInOut.current_checkout || {},
		autocomplete: state.autocomplete || {},
		activeVesselId: state.vessels.activeVesselId || -1,
		harboursMarkers: state.harbours.markers || []
	};
};

const mapDispatchToProps = {
	requestAutoComplete,
	cleanAutoComplete,
	checkOutAttempt
};

export const CheckOut = connect(mapStateToProps, mapDispatchToProps)(CheckOutModal);