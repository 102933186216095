import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {gettext} from '../../../util/l10n';
import _ from 'lodash';
import {push} from 'react-router-redux';

class RegisterUserOkPage extends Component {
	constructor(props) {
		super(props);
		this.onBackToLogin = this.onBackToLogin.bind(this);
	}

	onBackToLogin() {
		const {dispatch} = this.props;
		dispatch(push('/login'));
	}

	render() {
		return (
			<div className="registration-ok col-md-12">
				<div className="registration-information-section text-center">
					<div className="mail-icon"><i className="material-icons md-80 md-active">email</i></div>
					<div className="after-registration-instructions">
						{gettext('Please click on the verification link in the email which we sent to:')}
					</div>
					<div className="registration-confirmation-sent-to">
						{this.props.username}
					</div>
				</div>
				<div className="footer-with-button">
					<button className="btn btn-block available" onClick={this.onBackToLogin}
							type="submit">{gettext('Back to sign in')}</button>
				</div>
			</div>
		);
	}
}

RegisterUserOkPage.propTypes = {
	username: PropTypes.string
};

const mapStateToProps = (state) => {
	return {
		username: _.get(state, 'registration.user.username')
	};
};

export const RegisterUserOkPageContainer = connect(mapStateToProps)(RegisterUserOkPage);