import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {filter, first, isArray, isEmpty, isNumber, uniqBy} from 'lodash';
import {gettext} from '../../../util/l10n';

class SelectInputField extends Component {
	constructor(props, context) {
		super(props, context);
		this.handleChange = this.handleChange.bind(this);
		this.displayErrors = this.displayErrors.bind(this);
		this.notEmptyOption = this.notEmptyOption.bind(this);
	}

	notEmptyOption(option) {
		if (isEmpty(option)) {
			return false;
		}
		return isNumber(option.value) || !isEmpty(option.value);
	}

	handleChange(e) {
		this.props.handleChange(e.target.value);
	}

	displayErrors() {
		const {errorWrapperClassNames, errorMessages} = this.props;
		const errorMessage = isArray(errorMessages) ? first(errorMessages) : errorMessages,
			errorTextClassName = 'errors-text' + (errorWrapperClassNames ? ` ${errorWrapperClassNames}` : ''),
			errorIconClassName = 'errors-icon' + (errorWrapperClassNames ? ` ${errorWrapperClassNames}` : '');

		return (
			<div>
				<p className={errorTextClassName} data-tip={gettext(errorMessage)}>{gettext(errorMessage)}</p>
				<p className={errorIconClassName} data-tip={gettext(errorMessage)}>
					<i className="material-icons md-error md-18">report_problem</i>
				</p>
			</div>
		);
	}

	render() {
		const {isReadOnly} = this.props,
			elementKey = 'select-field-' + this.props.name,
			hasError = !isEmpty(this.props.errorMessages) ? ' has-mah-error' : '',
			customClassNames = !isEmpty(this.props.classNames) ? this.props.classNames : '',
			fieldsGroupClassName = `form-group ${hasError} ${customClassNames} label-static`,
			selectAttributes = isReadOnly ? {disabled: 'disabled'} : {};

		let {valuesList} = this.props;
		valuesList = filter(valuesList, this.notEmptyOption);
		valuesList = uniqBy(valuesList, 'value');
		return (
			<div className={fieldsGroupClassName}>
				<label htmlFor={elementKey} className="control-label">{gettext(this.props.fieldLabel)}</label>
				<select className="form-control" id={elementKey} value={this.props.value} onChange={this.handleChange}
						{...selectAttributes}>
					{valuesList.map((fieldSingleValue) => {
						return (
							<option key={fieldSingleValue.value} value={fieldSingleValue.value}>
								{gettext(fieldSingleValue.label)}
							</option>
						);
					})}
				</select>

				{this.displayErrors()}
			</div>
		);
	}
}

SelectInputField.propTypes = {
	name: PropTypes.string.isRequired,
	fieldLabel: PropTypes.string.isRequired,
	handleChange: PropTypes.func.isRequired,
	valuesList: PropTypes.arrayOf(PropTypes.object).isRequired,
	value: PropTypes.any,
	errorMessages: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
	classNames: PropTypes.string,
	errorWrapperClassNames: PropTypes.string,
	isReadOnly: PropTypes.bool
};

export default SelectInputField;