import {RE_ACTIVATION_FAILED, RE_ACTIVATION_SUCCEED} from '../constants/re-activation';

const reActivation = (state = {}, action) => {
	switch (action.type) {
		case RE_ACTIVATION_FAILED:
			return {reactivation: true};
		case RE_ACTIVATION_SUCCEED:
			return {reactivation: true};
	}
	return {};
};

export default reActivation;