const base64 = {
	decode(encodedData) {
		let b64 = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/=',
			o1, o2, o3, h1, h2, h3, h4, bits, i = 0, ac = 0, dec = '', tmpArr = [];

		if (!encodedData) {
			return encodedData;
		}
		encodedData += '';
		do {
			// unpack four hexets into three octets using index points in b64
			h1 = b64.indexOf(encodedData.charAt(i++));
			h2 = b64.indexOf(encodedData.charAt(i++));
			h3 = b64.indexOf(encodedData.charAt(i++));
			h4 = b64.indexOf(encodedData.charAt(i++));
			bits = h1 << 18 | h2 << 12 | h3 << 6 | h4;
			o1 = bits >> 16 & 0xff;
			o2 = bits >> 8 & 0xff;
			o3 = bits & 0xff;
			if (h3 === 64) {
				tmpArr[ac++] = String.fromCharCode(o1);
			} else if (h4 === 64) {
				tmpArr[ac++] = String.fromCharCode(o1, o2);
			} else {
				tmpArr[ac++] = String.fromCharCode(o1, o2, o3);
			}
		} while (i < encodedData.length);
		dec = tmpArr.join('');
		return decodeURIComponent(escape(dec.replace(/\0+$/, '')));
	}
};

export default base64;