import {has, read, write} from './cache-provider';

const classifierTTL = 30 * 24 * 60 * 60 * 1000;

export const storeClassifier = (classifierName, classifier) => {
	return write(classifierName, JSON.stringify(classifier), {ttl: classifierTTL});
};

export const getClassifier = (classifierName) => {
	if (has(classifierName)) {
		return JSON.parse(read(classifierName));
	}
	return null;
};