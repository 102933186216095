import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {assignIn, first, isEmpty, reduce} from 'lodash';
import {Link} from 'react-router';
import {connect} from 'react-redux';
import {gettext} from '../../../util/l10n';
import {loadMultipleClassifiers, logout, saveAccountAttempt, toggleMenu} from '../../../actions';
import {push} from 'react-router-redux';
import accountFields from './account-fields';
import {mapClassifiersToLists} from '../../../util/classifier';
import {isEmptyField} from '../../../util/field-util';
import ReactTooltip from 'react-tooltip';
import {FIELDS_NAMES} from '../../../constants/account';

class AccountEditPage extends Component {
	constructor(props) {
		super(props);
		this.state = {};
		this.toggleMenu = this.toggleMenu.bind(this);
		this.handleFieldChange = this.handleFieldChange.bind(this);
		this.onSubmit = this.onSubmit.bind(this);
		this.buildFieldsValuesLists = this.buildFieldsValuesLists.bind(this);
		this.getDefaultValueFromClassifier = this.getDefaultValueFromClassifier.bind(this);
		this.getMissingClassifiers = this.getMissingClassifiers.bind(this);
		this.accountDataFilled = this.accountDataFilled.bind(this);
		this.displayEditPasswordPopup = this.displayEditPasswordPopup.bind(this);
		this.gotoPaymentMethods = this.gotoPaymentMethods.bind(this);
		this.propagateState = this.propagateState.bind(this);
		this.logout = this.logout.bind(this);
	}

	logout(e) {
		e.preventDefault();
		const {logout, push} = this.props;
		logout();
		push('/');
	}

	displayEditPasswordPopup(e) {
		e.preventDefault();
		const {showModal} = this.props;
		showModal('changePassword');
	}

	gotoPaymentMethods(e) {
		e.preventDefault();
		const {push} = this.props;
		push('/payment-methods');
	}

	getDefaultValueFromClassifier(classifiers, classifierName, parameterName = classifierName) {
		if (!isEmpty(classifiers) && !isEmpty(classifiers[classifierName])) {
			return {
				[parameterName]: first(classifiers[classifierName]).value
			};
		}
		return {};
	}

	getMissingClassifiers(forState, mappedClassifiers) {
		let missingClassifiers = {};
		if (isEmpty(forState.country_iso3)) {
			assignIn(missingClassifiers, this.getDefaultValueFromClassifier(mappedClassifiers, 'country', 'country_iso3'));
		}
		if (isEmpty(forState.currency)) {
			assignIn(missingClassifiers, this.getDefaultValueFromClassifier(mappedClassifiers, 'currency'));
		}
		if (isEmpty(forState.size_unit)) {
			assignIn(missingClassifiers, this.getDefaultValueFromClassifier(mappedClassifiers, 'size_unit'));
		}
		return missingClassifiers;
	}

	propagateState(fromProps) {
		const {user, failed_on} = fromProps,
			displayData = isEmpty(failed_on) ? user : failed_on,
			mappedClassifiers = mapClassifiersToLists(fromProps.classifiers),
			newState = assignIn({}, displayData);

		assignIn(newState, this.getMissingClassifiers(newState, mappedClassifiers));

		this.setState(newState);
	}

	componentWillMount() {
		this.propagateState(this.props);
	}

	componentWillReceiveProps(nextProps) {
		this.propagateState(nextProps);
	}

	buildFieldsValuesLists() {
		return mapClassifiersToLists(this.props.classifiers);
	}

	handleFieldChange(propertyName) {
		return (value) => {
			this.setState({[propertyName]: value});
		};
	}

	toggleMenu(e) {
		this.props.toggleMenu();
		e.preventDefault();
	}

	onSubmit(e) {
		e.preventDefault();
		const {saveAccountAttempt} = this.props;
		let accountDetails = assignIn({}, this.state);
		saveAccountAttempt(accountDetails);
	}

	accountDataFilled() {
		return reduce(FIELDS_NAMES, (dataFilled, fieldName) => {
			return dataFilled && !isEmptyField(this.state[fieldName]);
		}, true);
	}

	render() {
		const {save_errors} = this.props,
			valuesLists = this.buildFieldsValuesLists(),
			saveAccountIsAvailable = this.accountDataFilled() ? 'available' : '',
			saveAccountClassNames = `btn btn-block ${saveAccountIsAvailable}`;
		return (
			<div className="account-edit">
				<div className="account-edit-header">
					<div className="account-edit-header-left">
						<Link onClick={this.toggleMenu} to="#">
							<button className="menu-button">
								<i className="material-icons md-white md-24">menu</i>
							</button>
						</Link>
						<div className="account-edit-title">
							{gettext('Account')}
						</div>
					</div>
					<div className="account-edit-header-logout">
						<Link to="#" onClick={this.logout}>
							<button className="menu-button">
								<i className="material-icons md-white md-24">exit_to_app</i>
							</button>
						</Link>
					</div>
				</div>
				<form onSubmit={this.onSubmit}>
					<fieldset>
						<div className="account-edit-form container">
							{accountFields({
								handleFieldChange: this.handleFieldChange,
								valuesLists,
								save_errors
							}, this.state)}
							<div className="form-group row account-buttons">
								<button onClick={this.gotoPaymentMethods}
										className="btn btn-block btn-inverse">
									{gettext('Payment methods')}
								</button>
								<button onClick={this.displayEditPasswordPopup}
										className="btn btn-block btn-inverse">
									{gettext('Edit password')}
								</button>
								<button type="submit" className={saveAccountClassNames}>
									{gettext('Save')}
								</button>
							</div>
						</div>
					</fieldset>
				</form>
				<ReactTooltip type="error" event="click" globalEventOff="mouseout"/>
			</div>
		);
	}
}

AccountEditPage.propTypes = {
	toggleMenu: PropTypes.func.isRequired,
	push: PropTypes.func.isRequired,
	saveAccountAttempt: PropTypes.func.isRequired,
	loadMultipleClassifiers: PropTypes.func.isRequired,
	logout: PropTypes.func.isRequired,
	showModal: PropTypes.func.isRequired,
	hideModal: PropTypes.func.isRequired,
	classifiers: PropTypes.object.isRequired,
	user: PropTypes.object
};

const mapStateToProps = (state) => {
	return {
		user: state.auth.user || {},
		classifiers: {
			size_unit: state.classifier.size_unit || [],
			country: state.classifier.country || [],
			currency: state.classifier.currency || []
		},
		save_errors: state.account.user_save_errors || {},
		failed_on: state.account.failed_on || {}
	};
};

const mapDispatchToProps = {
	toggleMenu,
	push,
	saveAccountAttempt,
	loadMultipleClassifiers,
	logout
};

export const AccountEdit = connect(mapStateToProps, mapDispatchToProps)(AccountEditPage);