import React from 'react';
import GeoPoint from '../../util/geo/geo-point';
import {bearingBetween} from '../../util/geo/geo-utils';
import {isEmpty} from 'lodash';

const harbourImageByType = (type) => {
	switch (type) {
		case 'home':
		case 'checked-in':
			return type;
		default:
			return 'dire';
	}
};

const harbourDirection = (type, harbour, center) => {
	switch (type) {
		case 'home':
		case 'checked-in':
			return {};
		default: {
			const hasBearing = !(isEmpty(harbour) || isEmpty(center)),
				bearing = hasBearing ?
					bearingBetween(center, new GeoPoint(harbour.lat_degrees, harbour.lon_degrees))
					: 0;
			return {transform: `rotate(${bearing}deg)`};
		}
	}
};

export const harbourIcon = (type, harbour = {}, center = {}) => {
	const harbourImage = harbourImageByType(type),
		rotation = harbourDirection(type, harbour, center),
		harbourImageSource = `/img/icons/harbours/${harbourImage}.png`,
		harbourImageSourceSet = `/img/icons/harbours/${harbourImage}@2x.png 2x, /img/icons/harbours/${harbourImage}@3x.png 3x`;
	return (
		<div className="harbour-item-icon">
			<img style={rotation} src={harbourImageSource} srcSet={harbourImageSourceSet}/>
		</div>
	);
};