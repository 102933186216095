import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {isFunction, transform} from 'lodash';
import {connect} from 'react-redux';
import {gettext} from '../../../util/l10n';
import {addCreditCardAttempt, cleanError} from '../../../actions';
import * as logger from '../../../util/logger';
import {getHostedFieldsInstance} from '../../../util/braintree-utils';
import {Loading} from '../../../components';
import SingleCheckboxField from '../../../components/core/fields/single-checkbox';

class AddCreditCardModal extends Component {
	constructor(props) {
		super(props);
		this.state = {
			hostedFieldsInstance: null,
			errors: {},
			saveCreditCard: !isFunction(props.passCreditCardData)
		};
		this.hideModal = this.hideModal.bind(this);
		this.controlButtons = this.controlButtons.bind(this);
		this.handleHostedFieldsChanges = this.handleHostedFieldsChanges.bind(this);
		this.checkEscClicked = this.checkEscClicked.bind(this);
		this.onSubmit = this.onSubmit.bind(this);
		this.getFormContent = this.getFormContent.bind(this);
		this.getStoreCardCheckbox = this.getStoreCardCheckbox.bind(this);
	}

	onSubmit(e) {
		e.preventDefault();
		const {addCreditCardAttempt, passCreditCardData, hideModal} = this.props,
			{hostedFieldsInstance, saveCreditCard} = this.state,
			tokenizeOptions = {};

		if (saveCreditCard) {
			tokenizeOptions.vault = true;
		}

		hostedFieldsInstance.tokenize(tokenizeOptions, (err, payload) => {
			if (err) {
				logger.error(err);
				this.setState({
					errors: {
						'card-number': gettext('Error while processing card.')
					}
				});
				return;
			}
			if (isFunction(passCreditCardData)) {
				if (saveCreditCard) {
					addCreditCardAttempt(payload.nonce, false);
				}
				hideModal();
				passCreditCardData({saveCreditCard, payload});
			} else {
				addCreditCardAttempt(payload.nonce);
			}
		});
	}

	hideModal(e) {
		e.preventDefault();
		const {hideModal} = this.props;

		hideModal();
	}

	checkEscClicked(e) {
		if (e.which === 27) {
			this.hideModal(e);
		}
	}

	classesForField(fieldId, basicClasses) {
		const error = this.state.errors[fieldId];

		if (!error) {
			return basicClasses;
		}

		return basicClasses + ' has-mah-error';
	}

	errorsForField(fieldId) {
		const error = this.state.errors[fieldId];

		if (!error) {
			return '';
		}

		return (
			<div>
				<p className="errors-text" data-tip={error}>{error}</p>
				<p className="errors-icon" data-tip={error}>
					<i className="material-icons md-error md-18">report_problem</i>
				</p>
			</div>
		);
	}

	handleHostedFieldsChanges(hostedFieldsInstance) {
		hostedFieldsInstance.on('validityChange', (event) => {
			const errors = transform(event.fields, (errors, field, fieldKey) => {
				if (!field.isEmpty && !field.isValid) {
					errors[field.container.id] = gettext(`Invalid ${fieldKey}`);
				}
				return errors;
			}, {});

			this.setState({errors});
		});
	}

	componentWillReceiveProps(nextProps) {
		const {saveErrors, cleanError} = nextProps,
			{errors} = this.state;

		if (saveErrors) {
			errors['card-number'] = saveErrors;
			this.setState({errors});
			cleanError();
		}
	}

	componentDidMount() {
		getHostedFieldsInstance({
			number: {
				selector: '#card-number',
				placeholder: '4111 1111 1111 1111'
			},
			cvv: {
				selector: '#cvv',
				placeholder: '123'
			},
			expirationMonth: {
				selector: '#expiration-month',
				placeholder: 'MM'
			},
			expirationYear: {
				selector: '#expiration-year',
				placeholder: 'YY'
			},
			postalCode: {
				selector: '#postal-code',
				placeholder: '90210'
			}
		})
			.then(hostedFieldsInstance => {
				if (!this.state.hostedFieldsInstance) {
					this.handleHostedFieldsChanges(hostedFieldsInstance);
					this.setState({hostedFieldsInstance});
				}
			})
			.catch(error => {
				logger.error(error);
			});
	}

	controlButtons() {
		const {hostedFieldsInstance} = this.state,
			addCardAvailableClass = hostedFieldsInstance ? 'available' : '',
			addCardClasses = `btn btn-main-action ${addCardAvailableClass}`;

		return (
			<div className="row add-credit-card-buttons">
				<button className="btn btn-wout-back available"
						onClick={this.hideModal}>{gettext('Cancel')}</button>
				<button type="submit" className={addCardClasses}>{gettext('Add Card')}</button>
			</div>
		);
	}

	getStoreCardCheckbox() {
		if (!isFunction(this.props.passCreditCardData)) {
			return '';
		}
		const {saveCreditCard} = this.state;
		return (
			<div className="row">
				<SingleCheckboxField
					name="store-credit-card"
					fieldLabel={gettext('Save card details')}
					value={saveCreditCard}
					handleChange={(checked) => this.setState({saveCreditCard: checked})}/>
			</div>
		);
	}

	getFormContent() {
		const {hostedFieldsInstance} = this.state,
			loadingClassName = !hostedFieldsInstance ? ' loading' : '',
			formClassNames = `add-credit-card-modal-body ${loadingClassName}`;

		return (
			<form className={formClassNames}
				  onKeyUp={this.checkEscClicked}
				  onSubmit={this.onSubmit}>
				<Loading radiusMax={10} radiusMin={6} color="#254ea2" className="add-credit-card-loading"/>
				<div className="row">
					<div className={this.classesForField('card-number', 'form-group col-xs-12 label-floating')}>
						<label className="control-label">{gettext('Card Number')}</label>
						<div className="form-control" id="card-number"></div>
						{this.errorsForField('card-number')}
					</div>
				</div>
				<div className="row">
					<div className={this.classesForField('expiration-month', 'form-group col-xs-6 label-floating')}>
						<label className="control-label">{gettext('Expiration Date')}</label>
						<div className="form-control" id="expiration-month"></div>
						{this.errorsForField('expiration-month')}
					</div>
					<div className={this.classesForField('expiration-year', 'form-group col-xs-6 label-floating')}>
						<div className="form-control" id="expiration-year"></div>
						{this.errorsForField('expiration-year')}
					</div>
				</div>
				<div className="row">
					<div className={this.classesForField('cvv', 'form-group col-xs-12 label-floating')}>
						<label className="control-label">{gettext('Security Code')}</label>
						<div className="form-control" id="cvv"></div>
						{this.errorsForField('cvv')}
					</div>
				</div>
				<div className="row">
					<div className={this.classesForField('postal-code', 'form-group col-xs-12 label-floating')}>
						<label className="control-label">{gettext('Zipcode')}</label>
						<div className="form-control" id="postal-code"></div>
						{this.errorsForField('postal-code')}
					</div>
				</div>
				{this.getStoreCardCheckbox()}
				{this.controlButtons()}
			</form>
		);
	}

	render() {
		const formContent = this.getFormContent();

		return (
			<div className="add-credit-card-modal">
				<div className="add-credit-card-modal-header">
					<div>{gettext('Add a bank card')}</div>
				</div>
				{formContent}
			</div>
		);
	}
}

AddCreditCardModal.propTypes = {
	hideModal: PropTypes.func.isRequired,
	saveErrors: PropTypes.string,
	addCreditCardAttempt: PropTypes.func.isRequired,
	passCreditCardData: PropTypes.func
};

const mapStateToProps = (state) => {
	return {
		saveErrors: state.creditCards.saveErrors || '',
	};
};

const mapDispatchToProps = {
	addCreditCardAttempt,
	cleanError
};

export const AddCreditCard = connect(mapStateToProps, mapDispatchToProps)(AddCreditCardModal);