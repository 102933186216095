import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {Link} from 'react-router';
import {gettext} from '../../util/l10n';
import {getCreditCardIcon} from '../credit-card-icon/credit-card-icon';

class PaymentMethodItem extends Component {
	constructor(props) {
		super(props);
		this.removeCreditCard = this.removeCreditCard.bind(this);
	}

	removeCreditCard(e) {
		e.preventDefault();
		this.props.removeCreditCard(this.props.token, this.props.last4);
	}

	render() {
		const {last4, expirationDate, imageUrl} = this.props,
			cardNumber = `**** ${last4}`,
			creditCardIcon = getCreditCardIcon(imageUrl);

		return (
			<li className="items-list-single-item">
				<div className="items-list-single-item-icon items-list-payment-method-item-icon">
					{creditCardIcon}
				</div>
				<div className="items-list-single-item-details">
					<div className="items-list-single-item-description">
						<div className="items-list-single-item-name">
							{cardNumber}
						</div>
						<div className="items-list-single-item-type">
							{expirationDate}
						</div>
					</div>
					<div className="items-list-single-item-actions">
						<Link to="#" onClick={this.removeCreditCard}
							  className="remove-items-list-single-item">{gettext('Delete')}</Link>
					</div>
				</div>
			</li>
		);
	}
}

PaymentMethodItem.propTypes = {
	removeCreditCard: PropTypes.func.isRequired
};

export {PaymentMethodItem};