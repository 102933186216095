import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {assignIn, reduce} from 'lodash';
import {connect} from 'react-redux';
import {gettext} from '../../../util/l10n';
import {isEmptyField} from '../../../util/field-util';
import {changePasswordFields} from './change-password-fields';
import {PASSWORD_FIELD_NAMES} from '../../../constants/account';
import {changePasswordAttempt} from '../../../actions';

const cleanState = () => {
	return reduce(PASSWORD_FIELD_NAMES, (state, field) => {
		state[field] = '';
		return state;
	}, {});
};

class ChangePasswordModal extends Component {
	constructor(props) {
		super(props);
		this.state = cleanState();
		this.handleFieldChange = this.handleFieldChange.bind(this);
		this.dataFilled = this.dataFilled.bind(this);
		this.hideModal = this.hideModal.bind(this);
		this.checkEscClicked = this.checkEscClicked.bind(this);
		this.onSubmit = this.onSubmit.bind(this);
	}

	onSubmit(e) {
		e.preventDefault();
		const passwordData = assignIn({}, this.state),
			{changePasswordAttempt} = this.props;
		changePasswordAttempt(passwordData);
	}

	hideModal(e) {
		e.preventDefault();
		const {hideModal} = this.props;
		hideModal();
	}

	checkEscClicked(e) {
		if (e.which === 27) {
			this.hideModal(e);
		}
	}

	dataFilled() {
		return reduce(PASSWORD_FIELD_NAMES, (dataFilled, fieldName) => {
			return dataFilled && !isEmptyField(this.state[fieldName]);
		}, true);
	}

	handleFieldChange(propertyName) {
		return (value) => {
			this.setState({[propertyName]: value});
		};
	}

	render() {
		const {save_errors} = this.props,
			handleFieldChange = this.handleFieldChange,
			saveIsAvailable = this.dataFilled() ? 'available' : '',
			saveButtonClassNames = `btn btn-wout-back ${saveIsAvailable}`;
		return (
			<div className="edit-password-modal">
				<div className="edit-password-modal-header">
					{gettext('Change password')}
				</div>
				<form className="edit-password-modal-body"
					  onKeyUp={this.checkEscClicked}
					  onSubmit={this.onSubmit}>
					{changePasswordFields({
						handleFieldChange,
						save_errors
					}, this.state)}
					<div className="row edit-password-buttons">
						<button className="btn btn-wout-back available"
								onClick={this.hideModal}>{gettext('Cancel')}</button>
						<button type="submit" className={saveButtonClassNames}>{gettext('Save')}</button>
					</div>
				</form>
			</div>
		);
	}
}

ChangePasswordModal.propTypes = {
	hideModal: PropTypes.func.isRequired,
	changePasswordAttempt: PropTypes.func.isRequired,
	save_errors: PropTypes.object
};

const mapStateToProps = (state) => {
	return {
		save_errors: state.account.password_save_errors || {}
	};
};

const mapDispatchToProps = {
	changePasswordAttempt
};

export const ChangePassword = connect(mapStateToProps, mapDispatchToProps)(ChangePasswordModal);