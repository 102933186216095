import React, {Component} from 'react';
import PropTypes from 'prop-types';

class DeleteConfirmationModal extends Component {
	constructor(props) {
		super(props);
		this.checkEscClicked = this.checkEscClicked.bind(this);
		this.onSubmit = this.onSubmit.bind(this);
		this.onReset = this.onReset.bind(this);
	}

	onSubmit(e) {
		e.preventDefault();
		this.props.setDeleteResults('confirm');
	}

	onReset(e) {
		e.preventDefault();
		this.props.setDeleteResults('cancel');
	}

	checkEscClicked(e) {
		if (e.which === 27) {
			this.onReset(e);
		}
	}

	render() {
		const {Header, confirmText, cancelText} = this.props;
		return (
			<div className="delete-confirmation-modal">
				<div className="delete-confirmation-modal-header">
					<Header/>
				</div>
				<form className="delete-confirmation-modal-body"
					  onSubmit={this.onSubmit}
					  onKeyUp={this.checkEscClicked}
					  onReset={this.onReset}>
					<div className="row delete-confirmation-buttons">
						<button type="reset" className="btn btn-wout-back available">{cancelText}</button>
						<button type="submit" className="btn btn-wout-back available">{confirmText}</button>
					</div>
				</form>
			</div>
		);
	}
}

DeleteConfirmationModal.modalClassNames = 'small-modal';

DeleteConfirmationModal.propTypes = {
	setDeleteResults: PropTypes.func.isRequired,
	Header: PropTypes.oneOfType([PropTypes.element, PropTypes.func]),
	confirmText: PropTypes.string.isRequired,
	cancelText: PropTypes.string.isRequired
};

export {DeleteConfirmationModal};