import {calculateOrder, submitPayment as submitPaymentToBraintree} from '../mah-api';
import {
	CHECK_IN_PAYMENT_SUCCESS,
	ORDER_CALCULATED,
	ORDER_CALCULATION_FAILED,
	ORDER_CLEAN_CALCULATION_ERRORS,
	ORDER_RESET
} from '../constants/orders';
import {SERVER_FORMAT, toMoment} from '../util/formatter';
import * as logger from '../util/logger';
import {completeCheckIn, loadActiveBookings} from './';

const orderCalculated = (orderDetails) => {
	return {
		type: ORDER_CALCULATED,
		payload: {
			orderDetails
		}
	};
};

const orderCalculationFailed = (error) => {
	return {
		type: ORDER_CALCULATION_FAILED,
		payload: {
			error
		}
	};
};

export const cleanOrderCalculationErrors = () => {
	return {
		type: ORDER_CLEAN_CALCULATION_ERRORS,
		payload: {}
	};
};

const checkInPaymentSuccess = () => {
	return {
		type: CHECK_IN_PAYMENT_SUCCESS,
		payload: {}
	};
};

export const resetOrderDetails = () => {
	return {
		type: ORDER_RESET,
		payload: {}
	};
};

export const loadOrderDetails = (harbour) => {
	return (dispatch, getState) => {
		const {checkInOut, vessels, orders} = getState(),
			{activeVesselId} = vessels,
			{checkInState} = checkInOut,
			{end_date_time} = checkInState,
			{orderDetails} = orders,
			endDateTime = toMoment(end_date_time, true).endOf('day').format(SERVER_FORMAT),
			bookingDetails = {
				booking_details: {
					key_harbour: harbour.id,
					key_user_vessel: activeVesselId,
					end_date_time: endDateTime,
					interval_type: 'daily'
				}
			};

		if (orderDetails && orderDetails.invoice_id) {
			bookingDetails.invoice_id = orderDetails.invoice_id;
		}

		calculateOrder(bookingDetails)
			.then(orderDetails => {
				dispatch(orderCalculated(orderDetails));
			})
			.catch(error => {
				const {response} = error;
				dispatch(orderCalculationFailed(response));
			});
	};
};

export const submitPayment = (card, checkInData, harbour) => {
	return (dispatch, getState) => {
		const {orders} = getState(),
			{orderDetails} = orders,
			payment_order = {
				invoice_id: orderDetails.invoice_id,
				store_payment_method: false
			};
		if (card.token) {
			payment_order.token = card.token;
		} else {
			payment_order.nonce = card.nonce;
		}
		submitPaymentToBraintree({payment_order, checkin: checkInData})
			.then(harbourJournal => {
				dispatch(completeCheckIn(harbourJournal, harbour));
				dispatch(checkInPaymentSuccess());
				dispatch(loadActiveBookings());
			})
			.catch(e => {
				logger.error('unable to submit payment to braintree', e);
			});
	};
};