import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {Link} from 'react-router';

class HarbourListItem extends Component {
	constructor(props) {
		super(props);
	}

	render() {
		const {name, harbourDetails, harbourIcon, harbourClicked} = this.props,
			handleClick = (e) => {
				e.preventDefault();
				harbourClicked();
			};
		return (
			<li className="harbour-item">
				<Link className="harbour-item-link" onClick={handleClick} to="/">
					{harbourIcon}
					<div className="harbour-item-details">
						<div className="harbour-item-name">
							{name}
						</div>
						<div className="harbour-item-type">
							{harbourDetails}
						</div>
					</div>
				</Link>
			</li>
		);
	}
}

HarbourListItem.propTypes = {
	harbourIcon: PropTypes.object,
	id: PropTypes.any,
	name: PropTypes.string,
	harbourDetails: PropTypes.string,
	harbourClicked: PropTypes.func.isRequired
};

export {HarbourListItem};