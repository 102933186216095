import {REDIRECTED, SET_REDIRECT_URL} from '../constants/iteractions';

const redirects = (state = {}, action) => {
	switch (action.type) {
		case SET_REDIRECT_URL:
			return {pathname: action.payload.url};
		case REDIRECTED:
			return {};
		default:
			return state;
	}
};

export default redirects;