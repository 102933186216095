import {
	ACTIVE_BOOKINGS_LOADED,
	BOOKING_CALCULATED,
	BOOKING_CALCULATION_FAILED,
	RESET_BOOKING_PRICE
} from '../constants/booking';
import * as logger from '../util/logger';
import {calculateBooking, loadActiveBookings as loadActiveBookingsApi} from '../mah-api';
import {SERVER_FORMAT, toMoment} from '../util/formatter';

const loadActiveBookings = () => {
	return (dispatch) => {
		return loadActiveBookingsApi()
			.then(activeBookings => {
				dispatch(activeBookingsLoaded(activeBookings));
			})
			.catch(error => {
				logger.error('Can\'t load active bookings', error);
			});
	};
};

const activeBookingsLoaded = (activeBookings) => {
	return {
		type: ACTIVE_BOOKINGS_LOADED,
		payload: {activeBookings}
	};
};

const recalculateBooking = (harbour, end_date_time) => {
	return (dispatch, getState) => {
		const {user} = getState().auth,
			{currency} = user;

		dispatch(resetBookingPrice());
		const {vessels, orders} = getState(),
			{activeVesselId} = vessels,
			{orderDetails} = orders,
			endDateTime = toMoment(end_date_time, true).endOf('day').format(SERVER_FORMAT),
			bookingDetails = {
				booking_details: {
					key_harbour: harbour.id,
					key_user_vessel: activeVesselId,
					end_date_time: endDateTime,
					interval_type: 'daily'
				}
			};

		if (orderDetails && orderDetails.invoice_id) {
			bookingDetails.invoice_id = orderDetails.invoice_id;
		}

		calculateBooking(bookingDetails)
			.then(bookingPrice => {
				dispatch(bookingCalculated(bookingPrice));
			})
			.catch(error => {
				const {response} = error;

				dispatch(bookingCalculationFailed(response, currency));
			});
	};
};

const resetBookingPrice = () => {
	return (dispatch, getState) => {
		const {user = {}} = getState().auth,
			{currency} = user;

		dispatch({
			type: RESET_BOOKING_PRICE,
			payload: {
				currency
			}
		});
	};
};

const bookingCalculationFailed = (error, currency) => {
	return {
		type: BOOKING_CALCULATION_FAILED,
		payload: {
			error,
			currency
		}
	};
};

const bookingCalculated = (bookingPrice) => {
	return {
		type: BOOKING_CALCULATED,
		payload: {bookingPrice}
	};
};

export {loadActiveBookings, activeBookingsLoaded, recalculateBooking, resetBookingPrice};