import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {Link} from 'react-router';
import {gettext} from '../../util/l10n';
import {getVesselIcon} from '../vessel-icon/vessel-icon';

class VesselItem extends Component {
	constructor(props) {
		super(props);
		this.removeVessel = this.removeVessel.bind(this);
		this.setActiveVessel = this.setActiveVessel.bind(this);
	}

	setActiveVessel() {
		const {id, setActiveVessel} = this.props;
		setActiveVessel(id);
	}

	removeVessel(e) {
		e.preventDefault();
		const {id, name} = this.props;
		this.props.removeVessel({id, name});
	}

	render() {
		const {name, type, id, getVesselType} = this.props;
		const editVesselLink = `/vessel/edit/${id}`,
			vesselIcon = getVesselIcon(type);
		return (
			<li className="items-list-single-item">
				<div className="items-list-single-item-icon items-list-vessel-item-icon">
					{vesselIcon}
				</div>
				<div className="items-list-single-item-details">
					<div className="items-list-single-item-description" onClick={this.setActiveVessel}>
						<div className="items-list-single-item-name">
							{name}
						</div>
						<div className="items-list-single-item-type">
							{getVesselType(type)}
						</div>
					</div>
					<div className="items-list-single-item-actions">
						<Link to={editVesselLink} className="edit-items-list-single-item">{gettext('Edit')}</Link>
						<Link to="#" onClick={this.removeVessel}
							  className="remove-items-list-single-item">{gettext('Delete')}</Link>
					</div>
				</div>
			</li>
		);
	}
}

VesselItem.propTypes = {
	getVesselType: PropTypes.func.isRequired,
	removeVessel: PropTypes.func.isRequired,
	setActiveVessel: PropTypes.func.isRequired
};

export {VesselItem};