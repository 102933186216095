import React from 'react';
import {range} from 'lodash';

const loading = (props) => {
	const {
			color = '#fff',
			radiusMax = 15,
			radiusMin = 9,
			opacityMin = 0.5,
			opacityMax = 1,
			circlesAmount = 3,
			className = '',
			style = {}
		} = props,
		oddCircle = `${radiusMax};${radiusMin};${radiusMax}`,
		oddCircleOpacity = `${opacityMax};${opacityMin};${opacityMax}`,
		evenCircle = `${radiusMin};${radiusMax};${radiusMin}`,
		evenCircleOpacity = `${opacityMin};${opacityMax};${opacityMin}`,
		width = (circlesAmount + 1) * radiusMax * 2,
		height = radiusMax * 2,
		viewBox = `0 0 ${width} ${height}`,
		marginLeft = radiusMax;

	return (
		<svg style={style} className={className} width={width} height={height} viewBox={viewBox}
			 xmlns="http://www.w3.org/2000/svg"
			 fill={color}>
			{range(0, circlesAmount).map(circleNr => {
				const circleKey = `animated-circle-${circleNr}`,
					margin = marginLeft + circleNr * radiusMax * 3,
					isOdd = circleNr % 2 === 0,
					startRadius = isOdd ? radiusMax : radiusMin,
					startOpacity = isOdd ? opacityMax : opacityMin,
					radiusAnimation = isOdd ? oddCircle : evenCircle,
					opacityAnimation = isOdd ? oddCircleOpacity : evenCircleOpacity;

				return (
					<circle key={circleKey} cx={margin} cy={radiusMax} r={startRadius}>
						<animate attributeName="r" from={startRadius} to={startRadius}
								 begin="0s" dur="0.8s"
								 values={radiusAnimation} calcMode="linear"
								 repeatCount="indefinite"/>
						<animate attributeName="fill-opacity" from={startOpacity} to={startOpacity}
								 begin="0s" dur="0.8s"
								 values={opacityAnimation} calcMode="linear"
								 repeatCount="indefinite"/>
					</circle>
				);
			})}
		</svg>
	);
};

export {loading};