/* global google */
'use strict';

const pointRegexPattern = '\\(([^,]+),([^,]+)\\)';

export default class GeoPoint {
	constructor(lat, lng) {
		this.lat = parseFloat(lat);
		this.lng = parseFloat(lng);
	}

	static fromGoogleLatLng(latLng) {
		return new GeoPoint(latLng.lat(), latLng.lng());
	}

	toGoogleLatLng() {
		if (typeof google === 'undefined') {
			throw new Error('Cant produce LatLng for google, as google is undefined');
		}
		return new google.maps.LatLng(this.lat, this.lng);
	}

	toBrowserPosition() {
		return {coords: {latitude: this.lat, longitude: this.lng}};
	}

	static fromString(point) {
		const pointRegex = new RegExp(pointRegexPattern, 'ig'),
			execResults = pointRegex.exec(point);
		if (!execResults) {
			return null;
		}
		return new GeoPoint(parseFloat(execResults[1]), parseFloat(execResults[2]));
	}

	toString() {
		return `(${this.lat},${this.lng})`;
	}
}