import {gettext} from '../util/l10n';
import {flatMapDeep, map, without} from 'lodash';

export const FIELDS_NAMES = ['firstname', 'lastname',
	'username', 'phone', 'country_iso3',
	'size_unit', 'currency'];

export const EDITABLE_FIELDS_NAMES = without(FIELDS_NAMES, ['username']);

export const REQUIRED_FIELDS_LABELS = {
	firstname: gettext('First name'),
	lastname: gettext('Last name'),
	phone: gettext('Phone'),
	country_iso3: gettext('Country'),
	size_unit: gettext('Units'),
	currency: gettext('Currency')
};

export const CHANGE_PASSWORD_FIELDS = [
	[{name: 'current_password', fieldLabel: 'Previous password', type: 'password'}],
	[{name: 'new_password', fieldLabel: 'New password', type: 'password'}],
	[{name: 'confirm_password', fieldLabel: 'Re-type new password', type: 'password'}]
];

export const PASSWORD_FIELD_NAMES = map(flatMapDeep(CHANGE_PASSWORD_FIELDS), (field) => field.name);

export const REQUIRED_PASSWORD_FIELDS_LABELS = {
	current_password: gettext('Previous password'),
	new_password: gettext('New password'),
	confirm_password: gettext('Re-type new password')
};