import {filterByRegex, read, write} from './cache-provider';
import {findOuterBounds} from '../geo/geo-utils';
import GeoBounds from '../geo/geo-bounds';
import {keys} from 'lodash';

const boundCacheKeyPrefix = 'map-bound-';

const determineBoundKey = (bounds) => {
	return `${boundCacheKeyPrefix}${bounds}`;
};

const getAllBounds = () => {
	return filterByRegex(new RegExp(boundCacheKeyPrefix + '.+', 'ig'));
};

export const storeBounds = (bounds, harbours) => {
	return write(determineBoundKey(bounds), JSON.stringify(harbours));
};

export const findHarbours = (forBounds) => {
	const parsedBounds = keys(getAllBounds()).map((key) => GeoBounds.fromString(key)),
		boundsKey = findOuterBounds(forBounds, parsedBounds);
	if (!boundsKey) {
		return null;
	}
	return getBounds(determineBoundKey(boundsKey));
};

export const getBounds = (boundKey) => {
	return JSON.parse(read(boundKey));
};