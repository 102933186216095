import {combineReducers} from 'redux';
import {routerReducer as routing} from 'react-router-redux';
import auth from './auth';
import registration from './registration';
import redirects from './redirects';
import maps from './maps';
import harbours from './harbours';
import menu from './menu';
import vessels from './vessels';
import classifier from './classifier';
import autocomplete from './autocomplete';
import account from './account';
import modal from './modal';
import checkInOut from './check-in-out';
import activation from './activation';
import reActivation from './re-activation';
import harbourDetails from './harbour-details';
import userLocation from './user-location';
import animations from './animations';
import location from './location';
import creditCards from './credit-cards';
import orders from './orders';
import booking from './booking';
import invoice from './invoice';

const mahReducers = combineReducers({
	auth,
	booking,
	invoice,
	redirects,
	registration,
	routing,
	harbourDetails,
	maps,
	harbours,
	menu,
	vessels,
	classifier,
	autocomplete,
	account,
	modal,
	checkInOut,
	activation,
	reActivation,
	userLocation,
	animations,
	location,
	creditCards,
	orders
});

export default mahReducers;