import {CLASSIFIER_LOADED} from '../constants/classifier';
import {noop} from 'lodash';
import {ensureClassifiersLoaded} from '../util/action-util/index';

export const classifierLoaded = (classifierName, classifierData) => {
	return {
		type: CLASSIFIER_LOADED,
		payload: {
			[classifierName]: classifierData
		}
	};
};

export const loadMultipleClassifiers = (classifiers) => {
	return ensureClassifiersLoaded(classifiers, noop);
};