import {gettext} from '../l10n';
import {isEmpty} from 'lodash';
import {distanceInNauticalMiles} from '../geo/geo-utils';
import GeoPoint from '../geo/geo-point';

const distanceToHarbour = (harbour, center) => {
	const hasDistance = !(isEmpty(center) || isEmpty(harbour));
	return hasDistance ?
		distanceInNauticalMiles(center, new GeoPoint(harbour.lat_degrees, harbour.lon_degrees))
		: null;
};

const harbourDetailsFor = (type, harbour, center) => {
	switch (type) {
		case 'home':
			return gettext('On a leave');
		case 'checked-in':
			return gettext('Checked in');
		default: {
			const distance = distanceToHarbour(harbour, center),
				units = gettext('nautical miles');
			return distance ? `${distance} ${units}` : '';
		}
	}
};

export {harbourDetailsFor};