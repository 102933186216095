import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {cloneDeep, last} from 'lodash';
import {modalDeactivated} from '../../actions';

const modalReadyPage = (Component, modalComponents) => {

	class EmptyComponent extends React.Component {
		render() {
			return (
				<div></div>
			);
		}
	}

	class ModalReadyPage extends React.Component {
		constructor(props) {
			super(props);
			this.state = {
				modalsStack: []
			};
			this.showModal = this.showModal.bind(this);
			this.hideModal = this.hideModal.bind(this);
			this.getComponentName = this.getComponentName.bind(this);
			this.getModalComponent = this.getModalComponent.bind(this);
			this.getModalProps = this.getModalProps.bind(this);
			this.hasModalActivated = this.hasModalActivated.bind(this);
			this.getModalComponentClassNames = this.getModalComponentClassNames.bind(this);
			this.closeModalOnEsc = this.closeModalOnEsc.bind(this);
		}

		getComponentName() {
			const {modalsStack} = this.state,
				currentModal = last(modalsStack);

			return currentModal && currentModal.componentName;
		}

		hasModalActivated() {
			const componentName = this.getComponentName(),
				{modalShouldHide} = this.props;
			if (!componentName || modalShouldHide) {
				return false;
			}
			return modalComponents.hasOwnProperty(componentName);
		}

		getModalComponent() {
			const componentName = this.getComponentName(),
				hasModalActivated = this.hasModalActivated();

			if (!hasModalActivated) {
				return EmptyComponent;
			}
			return modalComponents[componentName];
		}

		getModalProps() {
			const {modalsStack} = this.state,
				currentModal = last(modalsStack);

			return currentModal && currentModal.componentProps;
		}

		showModal(componentName, componentProps) {
			const {modalsStack} = this.state,
				updatedModalsStack = cloneDeep(modalsStack);

			updatedModalsStack.push({componentName, componentProps});
			this.setState({modalsStack: updatedModalsStack});
		}

		hideModal() {
			const {modalDeactivated} = this.props,
				{modalsStack} = this.state,
				updatedModalsStack = cloneDeep(modalsStack);

			updatedModalsStack.pop();
			this.setState({modalsStack: updatedModalsStack});
			if (!updatedModalsStack.length) {
				modalDeactivated();
			}
		}

		getModalComponentClassNames() {
			let ModalComponent = this.getModalComponent(),
				overlayContentClassNames = 'with-modal-overlay-content',
				modelWindowDataClassNames = 'modal-window-data';

			if (ModalComponent.overlayClassNames) {
				overlayContentClassNames = `${overlayContentClassNames} ${ModalComponent.overlayClassNames}`;
			}
			if (ModalComponent.modalClassNames) {
				modelWindowDataClassNames = `${modelWindowDataClassNames} ${ModalComponent.modalClassNames}`;
			}

			return {
				overlayContentClassNames,
				modelWindowDataClassNames
			};
		}

		closeModalOnEsc(e) {
			const componentProps = this.getModalProps(),
				ignoreClose = componentProps && componentProps.closeOnOutsideClick === false;

			if (ignoreClose) {
				return;
			}
			if (e.target === this.refs.modalOverlay) {
				return this.hideModal();
			}
		}

		componentDidUpdate() {
			const {modalShouldHide, modalDeactivated} = this.props;
			if (modalShouldHide) {
				this.setState({modalsStack: []});
				modalDeactivated();
			}
		}

		render() {
			const ModalComponent = this.getModalComponent(),
				modalComponentClassNames = this.getModalComponentClassNames(),
				{overlayContentClassNames, modelWindowDataClassNames} = modalComponentClassNames,
				componentProps = this.getModalProps(),
				hasModalActivated = this.hasModalActivated(),
				componentWithModalClass = 'component-with-modal' + (hasModalActivated ? ' modal-activated' : '');

			return (
				<div className={componentWithModalClass}>
					<div className="with-modal-main-content">
						<Component showModal={this.showModal} hideModal={this.hideModal} {...this.props}/>
					</div>
					<div className={overlayContentClassNames} onClick={this.closeModalOnEsc} ref="modalOverlay">
						<div className={modelWindowDataClassNames}>
							<ModalComponent showModal={this.showModal} hideModal={this.hideModal} {...componentProps}/>
						</div>
					</div>
				</div>
			);
		}
	}

	ModalReadyPage.propTypes = {
		modalShouldHide: PropTypes.bool,
		modalDeactivated: PropTypes.func.isRequired
	};

	const mapStateToProps = (state) => {
		return {
			modalShouldHide: state.modal.modalShouldHide
		};
	};

	const mapDispatchToProps = {
		modalDeactivated
	};

	return connect(mapStateToProps, mapDispatchToProps)(ModalReadyPage);
};

export {modalReadyPage};