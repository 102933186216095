import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {Provider} from 'react-redux';
import routes from '../../routes';
import $ from 'jquery';
import {gettext} from '../../util/l10n';

export default class Root extends Component {
	componentWillMount() {
		$('body.mah-home-loading').removeClass('mah-home-loading');
		$('title').text(gettext('Marina Ahoy Home'));
	}

	render() {
		const {store, history} = this.props;
		return (
			<Provider store={store}>
				{routes(history, store)}
			</Provider>
		);
	}
}

Root.propTypes = {
	store: PropTypes.object.isRequired,
	history: PropTypes.object.isRequired
};
