import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {Link} from 'react-router';
import {connect} from 'react-redux';
import {goBack} from 'react-router-redux';
import {gettext} from '../../../util/l10n';
import {serviceIcon} from '../../../components/service-icon/service-icon';

class HarbourServicesPage extends Component {
	constructor(props) {
		super(props);
		this.goBack = this.goBack.bind(this);
	}

	goBack(e) {
		e.preventDefault();
		this.props.goBack();
	}

	render() {
		const {harbourServices} = this.props;
		return (
			<div className="harbour-services-information">
				<div className="harbour-services-information-header">
					<Link onClick={this.goBack} to="#">
						<button className="menu-button">
							<i className="material-icons md-white md-24">arrow_back</i>
						</button>
					</Link>
					<div className="harbour-services-information-title">
						{gettext('Services')}
					</div>
				</div>
				<div className="harbour-services-information-list">
					{harbourServices.map(service => {
						const serviceKeyCode = `full-service-code-${service.code}`;
						return (
							<div key={serviceKeyCode} className="harbour-services-information-item">
								<div className="harbour-services-information-item-icon">
									{serviceIcon(service.code)}
								</div>
								<div className="harbour-services-information-item-text">
									{gettext(service.code)}
								</div>
							</div>
						);
					})}
				</div>
			</div>
		);
	}
}

HarbourServicesPage.propTypes = {
	goBack: PropTypes.func.isRequired,
	harbourServices: PropTypes.array
};

const mapStateToProps = (state) => {
	return {
		harbourServices: state.harbours.activeHarbourServices || []
	};
};

const mapDispatchToProps = {
	goBack
};

export const HarbourServices = connect(mapStateToProps, mapDispatchToProps)(HarbourServicesPage);