import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {Marker} from 'react-google-maps';
import {activateHarbourMarker} from '../../actions';
import _ from 'lodash';
import {connect} from 'react-redux';

class GoogleMapMarkers extends Component {
	constructor(props) {
		super(props);
		this.markerClicked = this.markerClicked.bind(this);
	}

	shouldComponentUpdate(nextProps) {
		const {markers} = this.props,
			newMarkers = nextProps.markers,
			sameMarkers = _.isEqual(markers, newMarkers);

		return !sameMarkers;
	}

	markerClicked(marker) {
		const {activateHarbourMarker} = this.props;
		activateHarbourMarker(marker);
	}

	render() {
		return (
			<div>
				{this.props.markers.map(marker => (
					<Marker onClick={() => this.markerClicked(marker)} {...marker} {...this.props}/>))
				}
			</div>
		);
	}
}

GoogleMapMarkers.propTypes = {
	markers: PropTypes.arrayOf(PropTypes.object),
	activateHarbourMarker: PropTypes.func.isRequired
};

const mapStateToProps = (state) => {
	return {
		markers: state.harbours.markers
	};
};

const mapDispatchToProps = {
	activateHarbourMarker
};

const GoogleMapMarkersConnected = connect(mapStateToProps, mapDispatchToProps)(GoogleMapMarkers);

export {GoogleMapMarkersConnected as GoogleMapMarkers};