import {PASSWORD_UPDATE_SUCCESS} from '../constants/user-details';
import {CHECK_IN_SUCCESS, CHECK_OUT_SUCCESS} from '../constants/check-in-out';
import {MODAL_DEACTIVATED} from '../constants/modal';
import {CREDIT_CARD_ADDED} from '../constants/credit-cards';
import {CHECK_IN_PAYMENT_SUCCESS} from '../constants/orders';

const modal = (state = {}, action) => {
	switch (action.type) {
		case PASSWORD_UPDATE_SUCCESS:
		case CHECK_IN_SUCCESS:
		case CHECK_OUT_SUCCESS:
		case CREDIT_CARD_ADDED:
		case CHECK_IN_PAYMENT_SUCCESS:
			return {modalShouldHide: true};
		case MODAL_DEACTIVATED:
			return {};
	}
	return {};
};

export default modal;