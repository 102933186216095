import React, {Component} from 'react';
import {gettext} from '../../util/l10n';

class ReActivationSucceed extends Component {
	constructor(props) {
		super(props);
	}

	render() {
		return (
			<div className="activation-succeed-section text-center">
				<div className="mail-icon">
					<i className="material-icons md-80 md-inactive">info_outline</i>
				</div>
				<div className="after-activation-instructions">
					<div>
						{gettext('If email address and password matched you will shortly receive new activation token.')}
					</div>
				</div>
			</div>
		);
	}
}

ReActivationSucceed.propTypes = {};

export {ReActivationSucceed};