import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {assign, assignIn, has, isEmpty, negate, pickBy, reduce} from 'lodash';
import {connect} from 'react-redux';
import {gettext} from '../../../util/l10n';
import {
	checkInAttempt,
	cleanAutoComplete,
	cleanOrderCalculationErrors,
	recalculateBooking,
	requestAutoComplete,
	resetOrderDetails,
	setCurrentCheckInState
} from '../../../actions';
import {checkInFields} from './check-in-fields';
import {isEmptyField} from '../../../util/field-util';
import {PaymentAmount} from '../../../components';
import {CHECK_IN_FIELDS_ERRORS, checkInRequiredFieldsByType} from '../../../constants/check-in-out';

class CheckInModal extends Component {
	constructor(props) {
		super(props);
		this.state = {
			validationErrors: {}
		};
		this.handleFieldChange = this.handleFieldChange.bind(this);
		this.hideModal = this.hideModal.bind(this);
		this.checkEscClicked = this.checkEscClicked.bind(this);
		this.onSubmit = this.onSubmit.bind(this);
		this.updateCounter = this.updateCounter.bind(this);
		this.increaseCounter = this.increaseCounter.bind(this);
		this.decreaseCounter = this.decreaseCounter.bind(this);
		this.updateStateFields = this.updateStateFields.bind(this);
		this.validationErrors = this.validationErrors.bind(this);
	}

	updateStateFields(fields) {
		const {checkInState, setCurrentCheckInState} = this.props;

		setCurrentCheckInState(assign({}, checkInState, fields));
	}

	updateCounter(amount) {
		const {checkInState} = this.props,
			{crew_total} = checkInState;

		if (crew_total + amount < 1) {
			return;
		}

		this.updateStateFields({
			crew_total: crew_total + amount
		});
	}

	increaseCounter(e) {
		e.preventDefault();
		this.updateCounter(1);
	}

	decreaseCounter(e) {
		e.preventDefault();
		this.updateCounter(-1);
	}

	componentWillReceiveProps(nextProps) {
		const {bookingCalculationErrors} = nextProps;

		if (isEmpty(bookingCalculationErrors)) {
			return;
		}
		const validationErrors = assign({}, this.state.validationErrors, bookingCalculationErrors);

		this.setState({validationErrors});
	}

	componentWillMount() {
		const {orderValidationErrors, cleanOrderCalculationErrors} = this.props;

		if (has(orderValidationErrors, 'validation_errors.booking_details')) {
			const validationErrors = assign({}, this.state.validationErrors,
				orderValidationErrors.validation_errors.booking_details);

			this.setState({validationErrors});
			cleanOrderCalculationErrors();
		}
	}

	validationErrors() {
		const {checkInType, checkInState} = this.props;

		return reduce(checkInRequiredFieldsByType(checkInType), (errors, fieldName) => {
			const fieldValue = checkInState[fieldName];

			if (isEmptyField(fieldValue)) {
				errors[fieldName] = CHECK_IN_FIELDS_ERRORS[fieldName];
			}
			return errors;
		}, {});
	}

	onSubmit(e) {
		e.preventDefault();
		const validationErrors = this.validationErrors();
		if (!isEmpty(validationErrors)) {
			this.setState({validationErrors});
			return;
		}

		const {harbour, showModal, activeVesselId, checkInAttempt, checkInType, checkInState, resetOrderDetails} = this.props,
			{crew_total, key_previous_harbour, notes_user, text_key_previous_harbour, end_date_time} = checkInState,
			checkInData = pickBy({
				key_harbour: harbour.id,
				key_user_vessel: activeVesselId,
				key_previous_harbour,
				previous_harbour: text_key_previous_harbour,
				notes_user,
				crew_total,
				end_date_time
			}, negate(isEmptyField));

		if (checkInType.needsOrder) {
			resetOrderDetails();
			showModal('processOrderModal', {
				harbour,
				closeOnOutsideClick: false
			});
		} else {
			checkInAttempt(checkInData, harbour);
		}
	}

	hideModal(e) {
		e.preventDefault();
		const {hideModal} = this.props;
		hideModal();
	}

	checkEscClicked(e) {
		if (e.which === 27) {
			this.hideModal(e);
		}
	}

	handleFieldChange(propertyName) {
		const {activeHarbour, recalculateBooking} = this.props;

		return (value) => {
			if (propertyName === 'end_date_time') {
				recalculateBooking(activeHarbour, value);
			}
			this.updateStateFields({
				[propertyName]: value
			});
		};
	}

	getBookingCalculationDetails() {
		const {
			checkInType, bookingPrice
		} = this.props;

		if (!checkInType.needsOrder) {
			return '';
		}

		return (
			<div className="row">
				<PaymentAmount currency={bookingPrice.currency} price={bookingPrice.price}
							   className="check-in-total-section"/>
			</div>
		);
	}

	render() {
		const {
				save_errors, requestAutoComplete, autocomplete, cleanAutoComplete,
				showModal, hideModal, checkInType, checkInState
			} = this.props,
			{validationErrors} = assignIn({}, this.state, {validationErrors: this.validationErrors()}),
			handleFieldChange = this.handleFieldChange,
			{crew_total = 1} = checkInState,
			reduceCrewTotalClassName = 'material-icons md-24' + (crew_total > 1 ? ' md-active' : ' md-inactive'),
			submitAvailabilityClass = isEmpty(validationErrors) ? 'available' : '',
			submitClassNames = `btn btn-main-action ${submitAvailabilityClass}`,
			fieldsErrors = assign({}, save_errors, this.state.validationErrors),
			nextStepText = checkInType.needsOrder ? gettext('Proceed') : gettext('Check In'),
			bookingCalculationDetails = this.getBookingCalculationDetails();

		/*
		 // TODO validate why this is needed
		 if (!isEmpty(save_errors)) {
		 fieldsErrors.key_previous_harbour = save_errors;
		 }*/

		const fields = checkInFields(checkInType, {
			handleFieldChange, requestAutoComplete,
			showModal, hideModal,
			autocomplete, cleanAutoComplete,
			save_errors: fieldsErrors
		}, checkInState);

		return (
			<div className="generic-modal-window">
				<div className="generic-modal-window-header-container">
					<div className="generic-modal-window-header-content">
						{gettext('Check in')}
					</div>
					<div className="check-in-modal-counter-controls">
						<a className="btn btn-wout-back" onClick={this.decreaseCounter}>
							<i className={reduceCrewTotalClassName}>remove_circle</i>
						</a>
						<div className="check-in-modal-counter-controls-value">
							{crew_total}
						</div>
						<a className="btn btn-wout-back" onClick={this.increaseCounter}>
							<i className="material-icons md-24 md-active">add_circle</i>
						</a>
					</div>
					<div className="check-in-modal-counter-controls-description">
						{gettext('Passengers')}
					</div>
				</div>
				<form className="generic-modal-window-body container"
					  onKeyUp={this.checkEscClicked}
					  onSubmit={this.onSubmit}>
					{fields}
					{bookingCalculationDetails}
					<div className="row generic-modal-window-buttons">
						<button className="btn btn-wout-back available"
								onClick={this.hideModal}>{gettext('Cancel')}</button>
						<button type="submit" className={submitClassNames}>{nextStepText}</button>
					</div>
				</form>
			</div>
		);
	}
}

CheckInModal.propTypes = {
	hideModal: PropTypes.func.isRequired,
	showModal: PropTypes.func.isRequired,
	autocomplete: PropTypes.object.isRequired,
	checkInAttempt: PropTypes.func.isRequired,
	save_errors: PropTypes.string,
	orderValidationErrors: PropTypes.object,
	bookingCalculationErrors: PropTypes.object,
	cleanOrderCalculationErrors: PropTypes.func.isRequired,
	harbour: PropTypes.object,
	checkInType: PropTypes.object,
	activeVesselId: PropTypes.number,
	harboursMarkers: PropTypes.array,
	checkInState: PropTypes.object,
	setCurrentCheckInState: PropTypes.func.isRequired,
	activeHarbour: PropTypes.object,
	bookingPrice: PropTypes.object,
	resetOrderDetails: PropTypes.func,
};

const mapStateToProps = (state) => {
	return {
		activeHarbour: state.harbours.activeHarbour || {},
		bookingPrice: state.booking.bookingPrice || {},
		bookingCalculationErrors: state.booking.calculateErrors,
		save_errors: state.checkInOut.check_in_errors || '',
		orderValidationErrors: state.orders.calculateErrors || {},
		checkInState: state.checkInOut.checkInState || {},
		autocomplete: state.autocomplete || {},
		activeVesselId: state.vessels.activeVesselId || -1,
		harboursMarkers: state.harbours.markers
	};
};

const mapDispatchToProps = {
	requestAutoComplete,
	cleanAutoComplete,
	checkInAttempt,
	setCurrentCheckInState,
	cleanOrderCalculationErrors,
	recalculateBooking,
	resetOrderDetails
};

export const CheckIn = connect(mapStateToProps, mapDispatchToProps)(CheckInModal);