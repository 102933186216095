import {assignIn} from 'lodash';
import {POSITION_OBTAINED, USER_POSITION_CHANGED} from '../constants/maps';
import {getPositionPoint} from '../util/position';

const maps = (state = {}, action) => {
	let coordinates;
	switch (action.type) {
		case POSITION_OBTAINED:
		case USER_POSITION_CHANGED:
			coordinates = getPositionPoint(action.payload.position.coords);
			return assignIn({}, state, {coordinates});
	}
	return state;
};

export default maps;