import {ACTIVATION_FAILED, ACTIVATION_SUCCEED} from '../constants/activation';
import {activateUser} from '../mah-api/index';

const activationFailed = (response) => {
	return {
		type: ACTIVATION_FAILED,
		payload: {
			error: response.error
		}
	};
};

const activationSucceed = () => {
	return {
		type: ACTIVATION_SUCCEED,
		payload: {
			result: {'success': true}
		}
	};
};

export const activationAttempt = (activationToken) => {
	return (dispatch) => {
		activateUser(activationToken)
			.then(() => dispatch(activationSucceed()))
			.catch((e) => dispatch(activationFailed(e.response)));
	};
};