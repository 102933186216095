import React from 'react';
import {Link} from 'react-router';
import {gettext} from '../../../util/l10n';

export const PasswordRecoverySuccess = () => {
	return (
		<div className="password-recovery">
			<div className="password-recovery-header">
				<Link to="/login">
					<button className="menu-button">
						<i className="material-icons md-white md-24">arrow_back</i>
					</button>
				</Link>
				<div className="password-recovery-title">
					{gettext('Password recovery')}
				</div>
			</div>
			<form>
				<fieldset>
					<div className="password-recovery-form container">
						<div className="form-group row password-recovery-description">
							{gettext('Your password has been reset. Please continue to ')}
							<Link to="/login">{gettext('Login')}</Link>
							{gettext(' page')}
						</div>
					</div>
				</fieldset>
			</form>
		</div>
	);
};