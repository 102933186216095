import React, {Component} from 'react';
import PropTypes from 'prop-types';
import ReactDOM from 'react-dom';
import {isEmpty, partial} from 'lodash';
import {connect} from 'react-redux';
import {gettext} from '../../../util/l10n';
import {goBack, push} from 'react-router-redux';
import {harbourIcon as harbourIconImage, HarbourListItem} from '../../../components';
import {cleanAutoComplete, loadNearbyHarbours, requestAutoComplete, showHarbourDetails} from '../../../actions';
import {harbourDetailsFor} from '../../../util/harbour-details/harbour-details';

class HarboursSearchPage extends Component {
	constructor(props) {
		super(props);
		this.state = {
			searchFor: ''
		};
		this.goBack = this.goBack.bind(this);
		this.harbourByType = this.harbourByType.bind(this);
		this.loadNearbyHarbours = this.loadNearbyHarbours.bind(this);
		this.performSearch = this.performSearch.bind(this);
	}

	loadNearbyHarbours() {
		this.props.loadNearbyHarbours();
	}

	componentDidMount() {
		ReactDOM.findDOMNode(this.refs.searchFor).focus();
		this.loadNearbyHarbours();
	}

	goBack(e) {
		const {goBack} = this.props;
		goBack();
		e.preventDefault();
	}

	performSearch(e) {
		const {performHarboursSearch, resetHarboursSearch} = this.props,
			val = e.target.value;
		this.setState({searchFor: val});
		if (val.length > 0) {
			performHarboursSearch(val);
		} else {
			resetHarboursSearch();
		}
	}

	harbourByType(type) {
		const harbourByType = this.props[type],
			{center, showHarbourDetails} = this.props;
		if (isEmpty(harbourByType)) {
			return '';
		}
		const harbourKeyId = `harbour-${harbourByType.id}`,
			harbourIcon = harbourIconImage(type, harbourByType, center),
			harbourDetails = harbourDetailsFor(type, harbourByType, center),
			harbourClicked = partial(showHarbourDetails, harbourByType, type);

		return (
			<HarbourListItem key={harbourKeyId}
							 harbourIcon={harbourIcon}
							 id={harbourByType.id}
							 name={harbourByType.name}
							 harbourDetails={harbourDetails}
							 harbourClicked={harbourClicked}
			/>
		);
	}

	render() {
		const {center, harbours, foundHarbours, showHarbourDetails} = this.props,
			{searchFor} = this.state,
			hasNearbyHarbours = !isEmpty(harbours) && isEmpty(foundHarbours) && !searchFor.length,
			nearbyHarboursGroupClass = hasNearbyHarbours ? 'harbours-items-group' : 'harbours-items-group no-items',
			displayHarbours = hasNearbyHarbours ? harbours : foundHarbours;

		return (
			<div className="harbours-search">
				<div className="harbours-search-header">
					<div className="harbours-search-header-left">
						<a href="#" onClick={(e) => this.goBack(e)}>
							<button className="back-button">
								<i className="material-icons md-white md-24">arrow_back</i>
							</button>
						</a>
						<div className="harbours-search-title">
							<input ref="searchFor" value={this.state.searchFor} onChange={this.performSearch}
								   type="text"
								   className="harbour-search-input"
								   placeholder={gettext('Search for a harbor')}/>
						</div>
					</div>
				</div>
				<div className="harbours-search-items">
					<ul className="harbours-items">
						<li className={nearbyHarboursGroupClass}>
							{gettext('NEARBY HARBOURS')}
						</li>
						{displayHarbours.map((harbour) => {
							const harbourKeyId = `harbour-${harbour.id}`,
								harbourIcon = harbourIconImage('direction', harbour, center),
								harbourDetails = harbourDetailsFor('direction', harbour, center),
								harbourClicked = partial(showHarbourDetails, harbour);
							return (
								<HarbourListItem key={harbourKeyId}
												 harbourIcon={harbourIcon}
												 id={harbour.id}
												 name={harbour.name}
												 harbourDetails={harbourDetails}
												 harbourClicked={harbourClicked}
								/>
							);
						})}
					</ul>
				</div>
			</div>
		);
	}
}

HarboursSearchPage.propTypes = {
	push: PropTypes.func.isRequired,
	goBack: PropTypes.func.isRequired,
	harbours: PropTypes.array,
	foundHarbours: PropTypes.array,
	center: PropTypes.object,
	loadNearbyHarbours: PropTypes.func.isRequired,
	performHarboursSearch: PropTypes.func.isRequired,
	resetHarboursSearch: PropTypes.func.isRequired,
	showHarbourDetails: PropTypes.func.isRequired
};

const mapStateToProps = (state) => {
	return {
		harbours: state.harbours.nearby || [],
		foundHarbours: state.autocomplete.harbours || [],
		center: state.harbours.searchCenter || {}
	};
};

const mapDispatchToProps = {
	push,
	goBack,
	loadNearbyHarbours,
	performHarboursSearch: partial(requestAutoComplete, 'harbours'),
	resetHarboursSearch: partial(cleanAutoComplete, 'harbours'),
	showHarbourDetails
};

export const HarboursSearch = connect(mapStateToProps, mapDispatchToProps)(HarboursSearchPage);