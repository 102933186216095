import React from 'react';

export const serviceIcon = (code) => {
	const iconsNormalSize = `/img/icons/services/${code}_@2x.png`,
		iconsDoubleSize = `/img/icons/services/${code}_@2x.png`,
		iconsTripleSize = `/img/icons/services/${code}_@3x.png`,
		imageSrcSet = `${iconsDoubleSize} 2x, ${iconsTripleSize} 3x`,
		codeKey = `service-code-${code}`;
	return (
		<img key={codeKey} src={iconsNormalSize} srcSet={imageSrcSet}/>
	);
};