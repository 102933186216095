import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {Link} from 'react-router';

class MenuItem extends Component {

	render() {
		return (
			<li className={this.props.classNames}>
				<Link to={this.props.link}>
					<i className="material-icons md-white md-24">{this.props.icon}</i>
					<p>{this.props.text}</p>
				</Link>
			</li>
		);

	}
}

MenuItem.propTypes = {
	link: PropTypes.string.isRequired,
	icon: PropTypes.string.isRequired,
	text: PropTypes.string.isRequired,
	classNames: PropTypes.string
};

export {MenuItem};