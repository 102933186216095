import {
	PASSWORD_RECOVERY_COMPLETED,
	PASSWORD_RECOVERY_ERRORS,
	PASSWORD_RECOVERY_FAILED,
	PASSWORD_RECOVERY_REQUESTED,
	PASSWORD_RECOVERY_VALIDATED,
	PASSWORD_UPDATE_FAILED,
	USER_UPDATE_FAILED
} from '../constants/user-details';
import {assign, omit} from 'lodash';

const account = (state = {}, action) => {
	const updatedState = omit(assign({}, state), ['user_save_errors', 'failed_on', 'password_save_errors', 'restore_password_error', 'passwordRecoveryErrors']);
	switch (action.type) {
		case PASSWORD_UPDATE_FAILED:
			return assign(updatedState, {password_save_errors: action.payload.error});
		case USER_UPDATE_FAILED:
			return assign(updatedState, {user_save_errors: action.payload.error, failed_on: action.payload.userData});
		case PASSWORD_RECOVERY_REQUESTED:
			return assign(updatedState, {passwordRequestedFor: action.payload.email});
		case PASSWORD_RECOVERY_FAILED:
			return assign(updatedState, {restore_password_error: true});
		case PASSWORD_RECOVERY_ERRORS:
			return assign(updatedState, {passwordRecoveryErrors: action.payload.passwordRecoveryErrors});
		case PASSWORD_RECOVERY_VALIDATED:
			return assign(updatedState, {passwordRecoveryResult: action.payload.passwordRecoveryResult});
		case PASSWORD_RECOVERY_COMPLETED:
			delete updatedState.passwordRecoveryResult;
			return updatedState;
	}
	return updatedState;
};

export default account;