import {
	CURRENT_VESSEL_CHANGED,
	FIELDS_MEASURES_NAMES,
	FIELDS_NAMES,
	REQUIRED_FIELDS_LABELS,
	SAVE_VESSEL_FAILED,
	SINGLE_VESSEL_LOADED,
	SINGLE_VESSEL_RESET,
	VESSEL_CHECK_INS_LOADED,
	VESSEL_REMOVED,
	VESSELS_LOADED
} from '../constants/vessels';
import {
	createVessel,
	fetchHarbourDetails,
	fetchUserVessels,
	fetchVesselDetails,
	removeVessel as removeVesselApi,
	updateVessel
} from '../mah-api';
import _ from 'lodash';
import {Cookies} from 'react-cookie';
import {ensureClassifiersLoaded} from '../util/action-util/index';
import * as logger from '../util/logger';
import {isEmptyField} from '../util/field-util';
import {push} from 'react-router-redux';
import {deactivateHarbourMarker, loadCheckedInHarbour, loadHomeHarbour, resetHomeHarbour} from './harbours';
const cookie = new Cookies();

const VESSELS_LIST_TTL = 60 * 60 * 1000;

const vesselRemoved = (vesselId) => {
	return {
		type: VESSEL_REMOVED,
		payload: {
			id: vesselId
		}
	};
};

const vesselsLoaded = (vessels) => {
	return {
		type: VESSELS_LOADED,
		payload: {
			vessels
		}
	};
};

const singleVesselLoaded = (vessel) => {
	return {
		type: SINGLE_VESSEL_LOADED,
		payload: {
			vessel
		}
	};
};

const currentVesselChanged = (activeVesselId) => {
	return {
		type: CURRENT_VESSEL_CHANGED,
		payload: {
			activeVesselId
		}
	};
};

const singleVesselReset = () => {
	return {
		type: SINGLE_VESSEL_RESET,
		payload: {}
	};
};

export const lastChecksInLoaded = (lastCheckIns) => {
	return {
		type: VESSEL_CHECK_INS_LOADED,
		payload: {
			lastCheckIns
		}
	};
};

const saveVesselFailed = (errorDetails, vesselData) => {
	return {
		type: SAVE_VESSEL_FAILED,
		payload: {
			error: errorDetails.validation_errors,
			vesselData
		}
	};
};

const loadUserVessels = (force = false) => {
	return ensureClassifiersLoaded(['vessel_type'], (dispatch, getState) => {
		const userVessels = _.get(getState(), 'vessels.list');
		if (!userVessels || force) {
			setTimeout(() => {
				dispatch(loadUserVessels(true));
			}, VESSELS_LIST_TTL);
			return fetchUserVessels()
				.then(vessels => {
					const activeVesselId = cookie.get('activeVesselId'),
						firstVessel = _.first(vessels);
					if (!activeVesselId && firstVessel) {
						dispatch(changeActiveVessel(firstVessel.id));
					}
					dispatch(vesselsLoaded(vessels));
				})
				.catch(() => {
					dispatch(vesselsLoaded([]));
				});
		}
	});
};

const setFirstUserVesselAsActive = () => {
	return (dispatch, getState) => {
		const userVessels = _.get(getState(), 'vessels.list');
		if (!userVessels) {
			return dispatch(changeActiveVessel(-1));
		}
		let firstVessel = _.first(userVessels);
		dispatch(changeActiveVessel(firstVessel.id));
	};
};

const loadCurrentVessel = () => {
	return ensureClassifiersLoaded(['vessel_type'], (dispatch, getState) => {
		const activeVesselId = _.get(getState(), 'vessels.activeVesselId'),
			vesselIdFromCookie = cookie.get('activeVesselId');
		if (!activeVesselId && vesselIdFromCookie) {
			dispatch(changeActiveVessel(parseInt(vesselIdFromCookie)));
		}
	});
};

const findVesselInCache = (state, vesselId) => {
	const vesselsList = _.get(state, 'vessels.list');
	return !_.isEmpty(vesselsList) ?
		_.find(vesselsList, (vessel) => _.isEqual(parseInt(vessel.id), parseInt(vesselId)))
		: null;
};

const getVesselById = (state, activeVesselId) => {
	return new Promise((resolve, reject) => {
		const cachedVessel = findVesselInCache(state, activeVesselId);
		if (cachedVessel) {
			return resolve(cachedVessel);
		}
		fetchVesselDetails(activeVesselId)
			.then(vessel => resolve(vessel))
			.catch(err => reject(err));
	});
};

const updateVesselConnectedHarbours = (vessel) => {
	return (dispatch) => {
		dispatch(loadCheckedInHarbour(vessel.id));
		if (_.isNil(vessel.key_home_harbour)) {
			dispatch(resetHomeHarbour());
			return;
		}
		dispatch(loadHomeHarbour(vessel.key_home_harbour));
	};
};

const changeActiveVessel = (activeVesselId) => {
	return (dispatch, getState) => {
		dispatch(deactivateHarbourMarker());
		cookie.set('activeVesselId', activeVesselId);
		activeVesselId = parseInt(activeVesselId);
		if (activeVesselId > 0) {
			getVesselById(getState(), activeVesselId)
				.then(vessel => dispatch(updateVesselConnectedHarbours(vessel)))
				.catch(err => logger.error(err));
		}
		dispatch(currentVesselChanged(Number(activeVesselId)));
	};
};

const removeVessel = (vesselId) => {
	return (dispatch) => {
		removeVesselApi(vesselId)
			.then(() => {
				const activeVesselId = cookie.get('activeVesselId');
				dispatch(vesselRemoved(vesselId));
				if (parseInt(activeVesselId) === parseInt(vesselId)) {
					dispatch(setFirstUserVesselAsActive());
				}
			})
			.catch((error) => {
				logger.error('cant remove vessel: ' + vesselId, error);
				dispatch(vesselRemoved(vesselId));
			});
	};
};

const loadVesselDetails = (vesselId) => {
	return ensureClassifiersLoaded(['country', 'size_unit', 'vessel_type'], (dispatch) => {
		fetchVesselDetails(vesselId)
			.then((vessel) => {
				const harbourId = vessel.key_home_harbour;
				if (!harbourId) {
					return vessel;
				}
				return fetchHarbourDetails(harbourId)
					.then((harbourDetails) => {
						vessel.harbourDetails = harbourDetails;
						return vessel;
					});

			})
			.then(vessel => dispatch(singleVesselLoaded(vessel)))
			.catch((error) => {
				logger.error('cant load vessel: ' + vesselId, error);
			});
	});
};

const resetVesselDetails = () => {
	return ensureClassifiersLoaded(['country', 'size_unit', 'vessel_type'], (dispatch) => {
		dispatch(singleVesselReset());
	});
};

const basicVesselDataValidation = (vesselData) => {
	const requiredFields = Object.keys(REQUIRED_FIELDS_LABELS),
		measurementErrors = _.reduce(requiredFields, (errors, fieldName) => {
			const fieldValue = vesselData[fieldName],
				numericFieldValue = parseFloat(fieldValue),
				isNumber = _.isNumber(fieldValue),
				isEmpty = _.isEmpty(fieldValue),
				expectsNumber = _.indexOf(FIELDS_MEASURES_NAMES, fieldName) !== -1,
				invalidString = isEmpty && !isNumber && !expectsNumber,
				emptyNumber = _.isNumber(numericFieldValue) && !_.isFinite(numericFieldValue) && expectsNumber,
				invalidNumber = _.isFinite(numericFieldValue) && numericFieldValue <= 0;

			if (invalidString || emptyNumber) {
				errors[fieldName] = [`${REQUIRED_FIELDS_LABELS[fieldName]} should not be empty`];
			}
			if (emptyNumber) {
				errors[fieldName] = [`${REQUIRED_FIELDS_LABELS[fieldName]} should not be empty`];
			}
			if (invalidNumber) {
				errors[fieldName] = [`${REQUIRED_FIELDS_LABELS[fieldName]} should be positive number`];
			}
			return errors;
		}, {});

	if (!_.isEmpty(measurementErrors)) {
		return Promise.reject({
			response: {validation_errors: measurementErrors}
		});
	}
	return Promise.resolve();
};

const pickDataToSend = (vesselData) => {
	const fieldsNamesToSend = _.union(FIELDS_NAMES, ['id']),
		fieldsToSend = _.pick(vesselData, fieldsNamesToSend);
	return _.pickBy(fieldsToSend, _.negate(isEmptyField));
};

const saveVesselAttempt = (vesselData) => {
	return ensureClassifiersLoaded(['country', 'size_unit', 'vessel_type'], (dispatch) => {
		const {id} = vesselData,
			dataToSend = pickDataToSend(vesselData);
		let saveVesselFn = createVessel;
		if (!_.isNil(id)) {
			saveVesselFn = updateVessel;
		}
		if (dataToSend.key_home_harbour) {
			delete dataToSend.home_harbour;
		} else {
			dataToSend.key_home_harbour = null;
		}
		return basicVesselDataValidation(dataToSend)
			.then(() => saveVesselFn(dataToSend))
			.then(vessel => {
				dispatch(updateVesselConnectedHarbours(vessel));
				dispatch(loadUserVessels(true));
				dispatch(singleVesselReset());
				dispatch(push('/vessels'));
			})
			.catch(error => dispatch(saveVesselFailed(error.response, vesselData)));
	});
};

const addNewVessel = () => {
	return (dispatch) => {
		dispatch(push('/vessel/add'));
	};
};

export {
	loadUserVessels, loadCurrentVessel, changeActiveVessel,
	removeVessel, loadVesselDetails, resetVesselDetails,
	saveVesselAttempt, addNewVessel
};