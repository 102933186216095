import {assignIn} from 'lodash';
import {COUNTRY_OBTAINED} from '../constants/location';

const location = (state = {}, action) => {
	switch (action.type) {
		case COUNTRY_OBTAINED: {
			const {country} = action.payload;
			return assignIn({}, state, {country});
		}
	}
	return state;
};

export default location;