import {getHarbourById, storeHarbourById} from '../util/cache/harbours-cache';
import {fetchHarbourDetails} from './index';
import {reduce} from 'lodash';

export const findHarboursById = (harbourIds) => {
	return Promise.all(
		harbourIds.map(harbourId => findHarbourById(harbourId))
	).then(findResults => {
		return reduce(findResults, (allData, harbour) => allData.concat(harbour), []);
	});
};

export const findHarbourById = (harbourId) => {
	return new Promise((resolve, reject) => {
		harbourId = parseInt(harbourId);
		const cachedHarbour = getHarbourById(harbourId);
		if (cachedHarbour) {
			return resolve(cachedHarbour);
		}
		fetchHarbourDetails(harbourId)
			.then((harbour) => {
				storeHarbourById(harbourId, harbour);
				resolve(harbour);
			})
			.catch(err => reject(err));
	});
};