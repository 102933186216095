import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {isEmpty} from 'lodash';
import {push} from 'react-router-redux';
import {gettext} from '../../../util/l10n';
import {activationAttempt} from '../../../actions';
import {ActivationSucceed} from '../../../components/activation/activation-succeed';
import {ReActivationSucceed} from '../../../components/activation/re-activation-succeed';
import {ActivationFailed} from '../../../components/activation/activation-failed';

class ActivationPage extends Component {
	constructor(props) {
		super(props);
		this.activationAttempt = this.activationAttempt.bind(this);
		this.goToLogin = this.goToLogin.bind(this);
		this.hasActivationSucceed = this.hasActivationSucceed.bind(this);
	}

	hasActivationSucceed() {
		const {activationResult, activationError} = this.props;
		return !isEmpty(activationResult) && isEmpty(activationError);
	}

	componentWillMount() {
		this.activationAttempt();
	}

	activationAttempt() {
		const {activationToken, activationAttempt} = this.props;
		if (!isEmpty(activationToken)) {
			activationAttempt(activationToken);
		}
	}

	goToLogin(e) {
		e.preventDefault();
		const {push} = this.props;
		push('/login');
	}

	render() {
		const {isReactivation} = this.props,
			hasActivationSucceed = this.hasActivationSucceed(),
			ActivationResult = isReactivation ? ReActivationSucceed :
				hasActivationSucceed ? ActivationSucceed : ActivationFailed,
			noFooterGapClass = !hasActivationSucceed && !isReactivation ? ' no-top-margin' : '',
			footerClassName = `footer-with-button ${noFooterGapClass}`;
		return (
			<div className="activation-ok col-md-12">
				<ActivationResult/>
				<div className={footerClassName}>
					<button className="btn btn-block available" onClick={this.goToLogin}
							type="submit">{gettext('Go to Login page')}</button>
				</div>
			</div>
		);
	}
}

ActivationPage.propTypes = {
	activationToken: PropTypes.string,
	activationResult: PropTypes.object,
	push: PropTypes.func.isRequired,
	activationAttempt: PropTypes.func.isRequired,
	isReactivation: PropTypes.bool
};

const mapStateToProps = (state, ownProps) => {
	return {
		activationToken: ownProps.location.query.t,
		activationResult: state.activation.result || {},
		activationError: state.activation.error || '',
		isReactivation: state.reActivation.reactivation
	};
};

const mapDispatchToProps = {
	push,
	activationAttempt
};

export const ActivationPageContainer = connect(mapStateToProps, mapDispatchToProps)(ActivationPage);