import {assign, cloneDeep, find, indexOf, omit, reduce} from 'lodash';
import {
	CHECKED_IN_HARBOURS_LOADED,
	CHECKED_IN_HARBOURS_RESET,
	HARBOUR_ACTIVATED,
	HARBOUR_BERTHS_LOADED,
	HARBOUR_DEACTIVATED,
	HARBOUR_SERVICES_LOADED,
	HARBOURS_LOADED,
	HOME_HARBOUR_LOADED,
	HOME_HARBOUR_RESET,
	NEARBY_HARBOUR_LOADED,
	SEARCH_CENTER_OBTAINED
} from '../constants/harbours';
import {LOCATION_CHANGE} from 'react-router-redux';
import {harbourImageByType} from '../util/maps';
import {getPositionPoint} from '../util/position';

const harboursOfTypeReset = (type, state) => {
	let markersSet = state.markersSet,
		markers = state.markers.map(marker => {
			const markerClone = cloneDeep(marker);
			if (markerClone.type === type) {
				markerClone.icon = harbourImageByType('default');
				markerClone.type = 'default';
			}
			return markerClone;
		});

	return assign({}, omit(state, [type]), {markers: [].concat(markers), markersSet});
};

const harboursOfTypeLoaded = (harbours, type, state) => {
	let markersSet = state.markersSet,
		markers = state.markers.map(marker => {
			const markerClone = cloneDeep(marker);
			if (markerClone.type === type) {
				markerClone.icon = harbourImageByType('default');
				markerClone.type = 'default';
			}
			return markerClone;
		});

	harbours.forEach(harbour => {
		const harbourIcon = {
			position: {lat: parseFloat(harbour.lat_degrees), lng: parseFloat(harbour.lon_degrees)},
			icon: harbourImageByType(type),
			title: harbour.name,
			key: harbour.id,
			type
		};

		if (!markersSet.has(harbour.id)) {
			markersSet.add(harbour.id);
			markers.push(harbourIcon);
		} else {
			let index = indexOf(markers, find(markers, {key: harbour.id}));
			markers.splice(index, 1, harbourIcon);
		}
	});

	return assign({}, state, {markers: [].concat(markers), markersSet, [type]: harbours});
};

const harbourOfTypeActivated = (harbour, type, state) => {
	const harbourIcon = {
		position: {lat: parseFloat(harbour.lat_degrees), lng: parseFloat(harbour.lon_degrees)},
		icon: harbourImageByType(type, 'large'),
		title: harbour.name,
		key: harbour.id,
		type
	};

	let markersSet = state.markersSet,
		markers = state.markers.map(marker => {
			const markerClone = cloneDeep(marker);
			markerClone.icon = harbourImageByType(markerClone.type);
			return markerClone;
		});

	if (!markersSet.has(harbour.id)) {
		markersSet.add(harbour.id);
		markers.push(harbourIcon);
	}

	let index = indexOf(markers, find(markers, {key: harbour.id}));
	markers.splice(index, 1, harbourIcon);

	return assign({}, state, {markers: [].concat(markers), markersSet, activeHarbour: assign({}, harbour, {type})});
};

const harbourServicesActivated = (services, state) => {
	return assign({}, state, {activeHarbourServices: services});
};

const harbourBerthsActivated = (berths, state) => {
	return assign({}, state, {activeHarbourBerths: berths});
};

const harbourDeactivated = (state) => {
	let markersSet = state.markersSet,
		markers = state.markers.map(marker => {
			const markerClone = cloneDeep(marker);
			markerClone.icon = harbourImageByType(markerClone.type);
			return markerClone;
		});

	return assign({}, omit(state, ['activeHarbour', 'activeHarbourServices', 'activeHarbourBerths']), {
		markers: [].concat(markers),
		markersSet
	});
};

const maps = (state = {markers: [], markersSet: new Set()}, action) => {
	let markersSet;
	switch (action.type) {
		case HARBOURS_LOADED: {
			markersSet = state.markersSet;
			let oldMarkers = state.markers,
				newMarkers = reduce(action.payload.harbours, (markers, harbour) => {
					if (markersSet.has(harbour.id)) {
						return markers;
					}
					markers.push({
						position: {lat: parseFloat(harbour.lat_degrees), lng: parseFloat(harbour.lon_degrees)},
						icon: harbourImageByType('default'),
						title: harbour.name,
						key: harbour.id,
						type: 'default'
					});
					markersSet.add(harbour.id);
					return markers;
				}, []);

			return assign({}, state, {markers: [].concat(oldMarkers, newMarkers), markersSet});
		}
		case HOME_HARBOUR_LOADED:
			return harboursOfTypeLoaded([action.payload.harbour], 'home', state);
		case HOME_HARBOUR_RESET:
			return harboursOfTypeReset('home', state);
		case CHECKED_IN_HARBOURS_LOADED:
			return harboursOfTypeLoaded(action.payload.harbours, 'checked-in', state);
		case CHECKED_IN_HARBOURS_RESET:
			return harboursOfTypeReset('checked-in', state);
		case NEARBY_HARBOUR_LOADED:
			return assign({}, state, {nearby: action.payload.harbours});
		case HARBOUR_ACTIVATED:
			return harbourOfTypeActivated(action.payload.harbour, action.payload.type, state);
		case HARBOUR_SERVICES_LOADED:
			return harbourServicesActivated(action.payload.services, state);
		case HARBOUR_BERTHS_LOADED:
			return harbourBerthsActivated(action.payload.berths, state);
		case HARBOUR_DEACTIVATED:
			return harbourDeactivated(state);
		case SEARCH_CENTER_OBTAINED:
			return assign({}, state, {searchCenter: getPositionPoint(action.payload.position.coords)});
		case LOCATION_CHANGE:
			return state;

	}
	return state;
};

export default maps;