import {forEach, keys, transform} from 'lodash';
const defaultTTL = 24 * 60 * 60 * 1000,
	getStore = () => {
		if (typeof window !== 'undefined' && window.localStorage) {
			return window.localStorage;
		}
		return {};
	},
	cache = getStore(),
	expiresAtPrefix = '-expires-at',
	expiresAtPrefixRegex = new RegExp('.*' + expiresAtPrefix + '$', 'ig'),
	runtimeTimeouts = {};

const cachedItems = () => {
	return transform(cache, (result, value, key) => {
		if (!key.match(expiresAtPrefixRegex)) {
			result[key] = value;
		}
		return result;
	}, {});
};

const removeKeyFromCache = (key) => {
	if (runtimeTimeouts[key]) {
		clearTimeout(runtimeTimeouts[key]);
		delete runtimeTimeouts[key];
	}
	return delete cache[key] && delete cache[key + expiresAtPrefix];
};

export const reValidateCache = () => {
	const currentTime = new Date().getTime();
	forEach(cachedItems(), (value, key) => {
		const expiresAt = parseInt(cache[key + expiresAtPrefix]),
			expiresIn = Math.min(expiresAt - currentTime, 0x7FFFFFFF);
		if (expiresIn <= 0) {
			removeKeyFromCache(key);
		} else if (!runtimeTimeouts[key]) {
			runtimeTimeouts[key] = setTimeout(() => removeKeyFromCache(key), expiresIn);
		}
	});
};

export const write = (key, value, options = {ttl: defaultTTL}) => {
	const expiresAt = new Date().getTime() + options.ttl;
	cache[key] = value;
	cache[key + expiresAtPrefix] = expiresAt;
	reValidateCache();
	return {key, value};
};

export const read = (key) => {
	return cache[key];
};

export const has = (key) => {
	return cache.hasOwnProperty(key);
};

export const filterByRegex = (keyRegex) => {
	return transform(cachedItems(), (result, value, key) => {
		if (key.match(keyRegex)) {
			result[key] = value;
		}
		return result;
	}, {});
};

export const invalidate = (key) => {
	return removeKeyFromCache(key);
};

export const invalidateAll = () => {
	keys(cachedItems()).forEach((key) => {
		removeKeyFromCache(key);
	});
};

export {defaultTTL};