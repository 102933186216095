import {has, read, write} from './cache-provider';

const harboursFirstLettersCacheKeyPrefix = 'harbours-by-letters-';
const harboursIdCacheKeyPrefix = 'harbours-by-id-';
const NO_LOCATION_HARBOURS = 'no-location-harbours';

const determineSearchKey = (letters) => {
	return `${harboursFirstLettersCacheKeyPrefix}${letters}`;
};

const determineRadiusSearchKey = (point, radius) => {
	const roundLat = point.lat.toFixed(2),
		roundLng = point.lng.toFixed(2);
	return `${roundLat}-${roundLng}-${radius}`;
};

const determineIdKey = (id) => {
	return `${harboursIdCacheKeyPrefix}${id}`;
};

export const storeHarboursByFirstLetter = (letters, harbours) => {
	return write(determineSearchKey(letters), JSON.stringify(harbours));
};

export const searchHarboursByFirstLetter = (letters) => {
	const cacheKey = determineSearchKey(letters),
		hasCachedHarbours = has(cacheKey),
		cachedHarbours = hasCachedHarbours ? getHarbours(cacheKey) : [];
	if (!hasCachedHarbours) {
		return null;
	}
	return cachedHarbours;
};

export const getHarbours = (cacheKey) => {
	return JSON.parse(read(cacheKey));
};

export const getHarbourById = (harbourId) => {
	const cacheKey = determineIdKey(harbourId),
		hasCachedHarbour = has(cacheKey),
		cachedHarbour = hasCachedHarbour ? getHarbours(cacheKey) : {};
	if (!hasCachedHarbour) {
		return null;
	}
	return cachedHarbour;
};

export const storeHarbourById = (harbourId, harbour) => {
	return write(determineIdKey(harbourId), JSON.stringify(harbour));
};


export const searchCachedHarbours = (point, radius) => {
	const searchKey = point ? determineRadiusSearchKey(point, radius) : NO_LOCATION_HARBOURS,
		hasCachedSearch = has(searchKey);
	if (hasCachedSearch) {
		return JSON.parse(read(searchKey));
	}
	return null;
};

export const storeCachedSearchResults = (point, radius, harbours) => {
	const cacheKey = point ? determineRadiusSearchKey(point, radius) : NO_LOCATION_HARBOURS;
	return write(cacheKey, JSON.stringify(harbours));
};