import {AUTO_COMPLETE_LOADED} from '../constants/autocomplete';
import {searchHarboursByFirstLetter, storeHarboursByFirstLetter} from '../util/cache/harbours-cache';
import {searchHarboursByFirstLetters} from '../mah-api/index';
import {noop} from 'lodash';

const AUTO_COMPLETE_TYPES = {
	harbours: 'harbours'
};

const autoCompleteLoaded = (type, valuesList) => {
	return {
		type: AUTO_COMPLETE_LOADED,
		payload: {
			type,
			valuesList
		}
	};
};

export const cleanAutoComplete = (type) => {
	return (dispatch) => {
		dispatch(autoCompleteLoaded(type, []));
	};
};

const requestHarboursAutoComplete = (letters) => {
	return (dispatch) => {
		let cachedHarbours = searchHarboursByFirstLetter(letters);
		if (cachedHarbours) {
			return dispatch(autoCompleteLoaded(AUTO_COMPLETE_TYPES.harbours, cachedHarbours));
		}

		searchHarboursByFirstLetters(letters)
			.then(harbours => {
				storeHarboursByFirstLetter(letters, harbours);
				return dispatch(autoCompleteLoaded(AUTO_COMPLETE_TYPES.harbours, harbours));
			})
			.catch(noop);
	};
};

export const requestAutoComplete = (type, letters) => {
	return (dispatch) => {
		switch (type) {
			case AUTO_COMPLETE_TYPES.harbours:
				return dispatch(requestHarboursAutoComplete(letters));
		}
	};
};