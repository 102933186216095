import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {Link} from 'react-router';
import {connect} from 'react-redux';
import {gettext} from '../../../util/l10n';
import {push} from 'react-router-redux';
import ReactTooltip from 'react-tooltip';
import SimpleInputField from '../../../components/core/fields/simple-input';
import {assign, isEmpty, isEqual, omit, pick} from 'lodash';
import {passwordRecoveryReset, passwordRecoveryValidate} from '../../../actions';
import {Loading} from '../../../components';

class PasswordRecoveryPage extends Component {
	constructor(props) {
		super(props);
		this.state = {errors: {}, activationToken: props.location.query.t};
		this.goHome = this.goHome.bind(this);
		this.onSubmit = this.onSubmit.bind(this);
		this.handleFieldChange = this.handleFieldChange.bind(this);
		this.validatePasswordRecoveryToken = this.validatePasswordRecoveryToken.bind(this);
		this.calculateValidationErrors = this.calculateValidationErrors.bind(this);
	}

	validatePasswordRecoveryToken() {
		const {passwordRecoveryValidate} = this.props,
			{activationToken} = this.state;

		passwordRecoveryValidate(activationToken);
	}

	componentWillMount() {
		this.validatePasswordRecoveryToken();
	}

	goHome(e) {
		e.preventDefault();
		this.props.push('/');
	}

	componentWillReceiveProps(nextProps) {
		const {errors} = nextProps,
			fieldsErrors = pick(errors, ['new_password', 'confirm_password']),
			genericErrors = omit(errors, ['new_password', 'confirm_password']);

		if (!isEmpty(genericErrors)) {
			fieldsErrors.new_password = gettext('Password reset token is invalid');
		}
		this.setState({errors: assign({}, fieldsErrors), isLoading: false});
	}

	calculateValidationErrors() {
		const errors = {},
			{new_password, confirm_password} = this.state;

		if (isEmpty(new_password)) {
			errors.new_password = gettext('Please type password');
		}
		if (isEmpty(confirm_password)) {
			errors.confirm_password = gettext('Please type password confirmation');
		}
		if (!isEqual(new_password, confirm_password)) {
			errors.confirm_password = gettext('Passwords don\'t match');
		}

		return errors;
	}

	onSubmit(e) {
		e.preventDefault();
		const {errors} = this.state,
			validationErrors = this.calculateValidationErrors();

		if (!isEmpty(validationErrors)) {
			this.setState({errors: assign({}, errors, validationErrors)});
			return;
		}
		const {passwordRecoveryReset} = this.props,
			{activationToken} = this.state;
		this.setState({isLoading: true});
		passwordRecoveryReset(this.state, activationToken);
	}

	handleFieldChange(propertyName) {
		return (value) => {
			this.setState({[propertyName]: value});
		};
	}

	render() {
		const {new_password, confirm_password, errors, isLoading} = this.state,
			{validationResult} = this.props,
			validationErrors = this.calculateValidationErrors(),
			pageIsLoading = isLoading || (isEmpty(errors) && isEmpty(validationResult)),
			submitFormAvailable = isEmpty(validationErrors) ? 'available' : '',
			recoverPasswordClassNames = `btn btn-block ${submitFormAvailable}`,
			passwordRecoveryClassNames = `password-recovery ${pageIsLoading ? 'loading' : ''}`;

		return (
			<div className={passwordRecoveryClassNames}>
				<div className="password-recovery-header">
					<Link onClick={this.goHome} to="#">
						<button className="menu-button">
							<i className="material-icons md-white md-24">arrow_back</i>
						</button>
					</Link>
					<div className="password-recovery-title">
						{gettext('Reset password')}
					</div>
				</div>
				<Loading radiusMax={10} radiusMin={6} color="#254ea2" className="password-recovery-loading"/>
				<form onSubmit={this.onSubmit}>
					<fieldset>
						<div className="password-recovery-form container">
							<div className="form-group row password-recovery-description">
								{gettext('Please type new password and confirmation.')}
							</div>
							<div className="form-group row password-recovery-field">
								<SimpleInputField inputFieldType="text"
												  hasSecureValue={true}
												  fieldLabel={gettext('password')}
												  value={new_password}
												  handleChange={this.handleFieldChange('new_password')}
												  name="new_password"
												  errorMessages={errors.new_password}
								/>
							</div>
							<div className="form-group row password-recovery-field">
								<SimpleInputField inputFieldType="text"
												  hasSecureValue={true}
												  fieldLabel={gettext('confirm password')}
												  value={confirm_password}
												  handleChange={this.handleFieldChange('confirm_password')}
												  name="confirm_password"
												  errorMessages={errors.confirm_password}
								/>
							</div>
							<div className="form-group row password-recovery-buttons">
								<button type="submit"
										className={recoverPasswordClassNames}>{gettext('Reset')}</button>
							</div>
						</div>
					</fieldset>
				</form>
				<ReactTooltip type="error" event="click" globalEventOff="mouseout"/>
			</div>
		);
	}
}

PasswordRecoveryPage.propTypes = {
	push: PropTypes.func.isRequired,
	validationResult: PropTypes.object,
	passwordRecoveryReset: PropTypes.func.isRequired,
	passwordRecoveryValidate: PropTypes.func.isRequired,
	errors: PropTypes.object
};

const mapStateToProps = (state) => {
	return {
		errors: state.account.passwordRecoveryErrors || {},
		validationResult: state.account.passwordRecoveryResult || {}
	};
};

const mapDispatchToProps = {
	push,
	passwordRecoveryReset,
	passwordRecoveryValidate
};

export const PasswordRecovery = connect(mapStateToProps, mapDispatchToProps)(PasswordRecoveryPage);