import React, {Component as ReactComponent} from 'react';
import {connect} from 'react-redux';
import {Link} from 'react-router';
import {gettext} from '../../util/l10n';
import {SocialLogin} from '../social-login/social-login';
import config from '../../config';
import {constant, defer} from 'lodash';
import {oAuthLoginAttempt} from '../../actions';
import {Loading} from '../../components';
import {push} from 'react-router-redux';

const NavLinks = [
	{pathname: '/login', text: gettext('Sign In'), key: 'nav_link_login'},
	{pathname: '/register', text: gettext('Register'), key: 'nav_link_register'}
];

const authorizationComponent = (Component) => {
	class AuthorizationComponent extends ReactComponent {
		constructor(props) {
			super(props);
			this.state = {
				socialLoginInProgress: false
			};
			this.oAuthLoginAttempt = this.oAuthLoginAttempt.bind(this);
			this.oAuthLoginAttemptResult = this.oAuthLoginAttemptResult.bind(this);
			this.getSocialLoginButtons = this.getSocialLoginButtons.bind(this);
			this.navigateForgotPassword = this.navigateForgotPassword.bind(this);
		}

		navigateForgotPassword(e) {
			e.preventDefault();
			const {push} = this.props;

			push('/forgot-password');
		}

		oAuthLoginAttemptResult(error) {
			this.setState({socialLoginInProgress: false});
			if (!error) {
				defer(() => {
					document.location = '/';
				});
			}
		}

		oAuthLoginAttempt(provider) {
			return (loginDetails) => {
				this.setState({socialLoginInProgress: true});
				const {oAuthLoginAttempt} = this.props;

				oAuthLoginAttempt(provider, loginDetails, this.oAuthLoginAttemptResult);
			};
		}

		buildLink(currentLocation) {
			return (link) => {
				const linkActiveClass = link.pathname === currentLocation ? 'active' : '';
				return (
					<li key={link.key} role="presentation" className={linkActiveClass}>
						<Link to={link.pathname}>{link.text}</Link>
					</li>
				);
			};
		}

		getSocialLoginButtons() {
			return (
				<div className="col-md-12 social-login-buttons">
					<SocialLogin
						clientId={config.SOCIAL.FACEBOOK.CLIENT_ID}
						oAuthUrl="https://www.facebook.com/v2.8/dialog/oauth"
						classNames=""
						scopes="public_profile,email"
						onLoginSuccess={this.oAuthLoginAttempt('facebook')}>
						<i className="custom-icon icon-facebook"/>
						<span className="middle-aligned">{gettext('Sign-in with Facebook')}</span>
					</SocialLogin>
					<SocialLogin
						clientId={config.SOCIAL.GOOGLE.CLIENT_ID}
						oAuthUrl="https://accounts.google.com/o/oauth2/v2/auth"
						classNames=""
						scopes="profile email"
						onLoginSuccess={this.oAuthLoginAttempt('google')}>
						<i className="custom-icon icon-google"/>
						<span className="middle-aligned">{gettext('Sign-in with Google')}</span>
					</SocialLogin>
				</div>
			);
		}

		render() {
			const {pathname} = this.props.location,
				{socialLoginInProgress} = this.state,
				inProgressClassName = socialLoginInProgress ? 'loading' : '',
				authorizationComponentClassNames = `col-md-12 authorization-component ${inProgressClassName}`,
				navigationItems = NavLinks.map(this.buildLink(pathname));

			return (
				<div className={authorizationComponentClassNames}>
					<div className="col-md-12 logo-holder">
						<img src="/img/logo/regular/logo.png"
						     srcSet="/img/logo/regular/logo@2x.png 2x, /img/logo/regular/logo@3x.png 3x"/>
					</div>
					<div className="col-md-12 auth-navigation">
						<ul className="nav nav-pills nav-justified">
							{navigationItems}
						</ul>
					</div>
					{this.getSocialLoginButtons()}
					<Loading radiusMax={10} radiusMin={6} color="#254ea2" className="col-md-12 social-login-loading"/>
					<div className="col-md-12 auth-form">
						<Component {...this.props}/>
					</div>
					<div className="col-md-12 forgot-password">
						<a href="/forgot-password" onClick={this.navigateForgotPassword}>{gettext('Forgot your password?')}</a>
					</div>
				</div>
			);
		}
	}

	const mapDispatchToProps = {
		oAuthLoginAttempt,
		push
	};

	return connect(constant({}), mapDispatchToProps)(AuthorizationComponent);
};

export {authorizationComponent};