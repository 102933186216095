import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {Link} from 'react-router';
import {connect} from 'react-redux';
import {gettext} from '../../../util/l10n';
import {toggleMenu} from '../../../actions';
import config from '../../../config';

class AboutPage extends Component {
	constructor(props) {
		super(props);
		this.toggleMenu = this.toggleMenu.bind(this);
	}

	toggleMenu(e) {
		this.props.toggleMenu();
		e.preventDefault();
	}

	render() {
		return (
			<div className="about-information">
				<div className="about-information-header">
					<Link onClick={this.toggleMenu} to="#">
						<button className="menu-button">
							<i className="material-icons md-white md-24">menu</i>
						</button>
					</Link>
					<div className="about-information-title">
						{gettext('About')}
					</div>
				</div>
				<div className="about-information-details">
					<div className="about-information-item">
						<a href="mailto:ahoy@marinaahoy.com">ahoy@marinaahoy.com</a>
					</div>
					<div className="about-information-item">
						<a target="_blank" href="http://www.marinaahoy.com">www.marinaahoy.com</a>
					</div>
					<div className="about-information-item-spacer">

					</div>
					<div className="about-information-item">
						<Link to="/terms">{gettext('Terms & Conditions')}</Link>
					</div>
					<div className="about-information-item">
						<Link to="/privacy">{gettext('Privacy policy')}</Link>
					</div>
					<div className="about-information-item about-information-item-header">
						{`${gettext('APPLICATION VERSION')} ${config.version}`}
					</div>
				</div>
			</div>
		);
	}
}

AboutPage.propTypes = {
	toggleMenu: PropTypes.func.isRequired
};

const mapStateToProps = () => {
	return {};
};

const mapDispatchToProps = {
	toggleMenu
};

export const About = connect(mapStateToProps, mapDispatchToProps)(AboutPage);