import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {GoogleMap, Marker} from 'react-google-maps';
import ScriptjsLoader from 'react-google-maps/lib/async/ScriptjsLoader';
import {
	boundsChanged,
	deactivateHarbourMarker,
	mapCenterChanged,
	userPositionChanged,
	zoomChanged
} from '../../actions';
import {GoogleMapMarkers} from './map-markers';
import _, {assign, debounce, defer, isEmpty, once, pick, throttle} from 'lodash';
import {connect} from 'react-redux';
import {__getInstance, triggerEvent} from 'react-google-maps/lib/utils';
import config from '../../config';
import {hasShallowDiff} from '../../util/compare-utils';
import {getCurrentPosition} from '../../util/position';

const MAP_OPTIONS = {
		zoom: 9,
		mapTypeId: 'terrain',
		options: {
			minZoom: 5,
			fullscreenControl: false,
			mapTypeControl: false,
			overviewMapControl: false,
			rotateControl: false,
			signInControl: false,
			streetViewControl: false,
			zoomControlOptions: {
				position: 8
			}
		}
	},
	LOCATION_UPDATE_INTERVAL = 10 * 1000;

class GoogleMapComponent extends Component {
	constructor(props) {
		super(props);
		this.handleWindowResize = throttle(this.handleWindowResize.bind(this), 500);
		this.mapClicked = this.mapClicked.bind(this);
		this.boundsChanged = this.boundsChanged.bind(this);
		this.mapCenterChanged = this.mapCenterChanged.bind(this);
		this.zoomChanged = this.zoomChanged.bind(this);
		this.mapResizeNeeded = this.mapResizeNeeded.bind(this);
		this.userPositionMarker = this.userPositionMarker.bind(this);
		this.mapLoaded = once(this.mapLoaded.bind(this));
		this.calculateMapCenter = this.calculateMapCenter.bind(this);
	}

	calculateMapCenter() {
		const {mapCenter, coordinates} = this.props;
		if (!isEmpty(coordinates)) {
			return coordinates;
		}
		if (!isEmpty(mapCenter)) {
			return mapCenter;
		}
	}

	mapLoaded() {
		const mapInstance = __getInstance(this._googleMapComponent),
			{controls, onMapLoaded} = this.props;

		controls.forEach((control, index) => {
			control.index = index;
			mapInstance.controls[9].push(control);
		});
		this._locationListener = setInterval(() => {
			getCurrentPosition()
				.then(position => this.props.userPositionChanged(position));
		}, LOCATION_UPDATE_INTERVAL);
		onMapLoaded();
	}

	mapResizeNeeded(nextProps) {
		const hasDiffs = hasShallowDiff(this.props, nextProps,
			'activeHarbour', 'showHarbourDetails', 'updated');

		return this._googleMapComponent && hasDiffs;
	}

	componentDidMount() {
		window.addEventListener('resize', this.handleWindowResize);
	}

	componentWillUnmount() {
		clearInterval(this._locationListener);
		window.removeEventListener('resize', this.handleWindowResize);
	}

	componentWillReceiveProps(nextProps) {
		if (this.mapResizeNeeded(nextProps)) {
			defer(() => {
				this.handleWindowResize();
			});
		}
	}

	shouldComponentUpdate(nextProps) {
		const {coordinates, zoom} = this.props,
			newCoordinates = nextProps.coordinates,
			newzoom = nextProps.zoom,
			sameCoordinates = _.isEqual(coordinates, newCoordinates),
			sameZoom = zoom === newzoom;

		return !sameCoordinates || !sameZoom;
	}

	handleWindowResize() {
		if (this._googleMapComponent) {
			triggerEvent(this._googleMapComponent, 'resize');
		}
	}

	mapClicked() {
		const {deactivateHarbourMarker} = this.props;
		deactivateHarbourMarker();
	}

	boundsChanged(bounds) {
		const {boundsChanged} = this.props;
		boundsChanged(bounds);
	}

	mapCenterChanged(center) {
		const {mapCenterChanged} = this.props;
		mapCenterChanged(center);
	}

	zoomChanged(zoom) {
		const {zoomChanged} = this.props;
		zoomChanged(zoom);
	}

	userPositionMarker() {
		const {userLocation} = this.props,
			{coordinates} = userLocation,
			locationIcon = {
				url: '/img/icons/my-location.png',
				size: {width: 48, height: 48},
				scaledSize: {width: 24, height: 24},
				origin: {x: 0, y: 0},
				anchor: {x: 8, y: 8}
			};
		if (isEmpty(userLocation.coordinates)) {
			return '';
		}
		return (
			<Marker position={coordinates} icon={locationIcon}/>
		);
	}

	render() {
		const googleApiKey = _.get(config, 'MAP.GOOGLE'),
			scriptLoadingQuery = {
				v: '3'
			},
			boundsChanged = debounce(this.boundsChanged, 500),
			positionMarker = this.userPositionMarker(),
			mapOptions = assign({}, MAP_OPTIONS, pick(this.props, 'zoom'));
		if (googleApiKey) {
			scriptLoadingQuery.key = googleApiKey;
		}
		return (
			<ScriptjsLoader
				hostname={'maps.googleapis.com'}
				pathname={'/maps/api/js'}
				query={scriptLoadingQuery}
				loadingElement={<div></div>}
				containerElement={
					<div className="google-map"></div>
				}
				googleMapElement={
					<GoogleMap
						ref={(map) => (this._googleMapComponent = map)}
						center={this.calculateMapCenter()}
						onCenterChanged={() => this.mapCenterChanged(this._googleMapComponent.getCenter())}
						onZoomChanged={() => this.zoomChanged(this._googleMapComponent.getZoom())}
						onBoundsChanged={() => boundsChanged(this._googleMapComponent.getBounds())}
						onClick={() => this.mapClicked()}
						onIdle={() => this.mapLoaded()}
						{...mapOptions}
					>
						<GoogleMapMarkers {...this.props}/>
						{positionMarker}
					</GoogleMap>
				}
			/>
		);
	}
}

GoogleMapComponent.propTypes = {
	coordinates: PropTypes.object,
	mapCenter: PropTypes.object,
	boundsChanged: PropTypes.func.isRequired,
	mapCenterChanged: PropTypes.func.isRequired,
	zoomChanged: PropTypes.func.isRequired,
	deactivateHarbourMarker: PropTypes.func.isRequired,
	markers: PropTypes.arrayOf(PropTypes.object),
	controls: PropTypes.array,
	userLocation: PropTypes.object,
	onMapLoaded: PropTypes.func.isRequired
};

const mapStateToProps = (state) => {
	return {
		coordinates: state.maps.coordinates || {},
		mapCenter: state.maps.mapCenter || {},
		zoom: state.maps.zoom || 9,
		// used for resize events listening
		activeHarbour: state.harbours.activeHarbour,
		showHarbourDetails: state.harbourDetails.showHarbourDetails,
		userLocation: state.userLocation,
		updated: state.animations.updated
	};
};

const mapDispatchToProps = {
	boundsChanged,
	mapCenterChanged,
	deactivateHarbourMarker,
	userPositionChanged,
	zoomChanged
};

const GoogleMapConnectedComponent = connect(mapStateToProps, mapDispatchToProps)(GoogleMapComponent);

export {GoogleMapConnectedComponent as GoogleMapComponent};