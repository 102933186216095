import React, {Component} from 'react';
import PropTypes from 'prop-types';

class SocialLogin extends Component {
	constructor(props) {
		super(props);
		this.handleSocialLogin = this.handleSocialLogin.bind(this);
		this.buildOAuthUrl = this.buildOAuthUrl.bind(this);
		this.handleLoginResult = this.handleLoginResult.bind(this);
	}

	static oAuthAttemptRedirectUri() {
		const {protocol, host} = document.location;

		return `${protocol}//${host}/social-login/`;
	}

	buildOAuthUrl() {
		const {clientId, oAuthUrl, scopes} = this.props,
			redirectUri = SocialLogin.oAuthAttemptRedirectUri();

		return `${oAuthUrl}?client_id=${clientId}&redirect_uri=${redirectUri}&scope=${scopes}&response_type=code`;
	}

	handleLoginResult(result) {
		if (this.popup) {
			this.popup.close();
		}
		delete window.oAuthResultHandler;
		if (result.error || !result.code) {
			return;
		}
		const {clientId, onLoginSuccess} = this.props,
			redirectUri = SocialLogin.oAuthAttemptRedirectUri(),
			{code} = result;

		onLoginSuccess({clientId, redirectUri, code});
	}

	handleSocialLogin(e) {
		e.preventDefault();
		const oAuthUrl = this.buildOAuthUrl();

		this.popup = window.open(oAuthUrl, '_blank', 'menubar=no,toolbar=no,width=500,height=500');
		window.oAuthResultHandler = this.handleLoginResult;
	}

	render() {
		const {classNames} = this.props,
			classes = `btn btn-block btn-social ${classNames}`;

		return (
			<button className={classes}
					onClick={this.handleSocialLogin}
					type="button">
				{this.props.children}
			</button>
		);
	}
}

SocialLogin.propTypes = {
	clientId: PropTypes.string.isRequired,
	oAuthUrl: PropTypes.string.isRequired,
	scopes: PropTypes.string.isRequired,
	onLoginSuccess: PropTypes.func.isRequired,
	classNames: PropTypes.string
};

export {SocialLogin};