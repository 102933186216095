import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {assignIn, flattenDeep, isEmpty, pick, reduce} from 'lodash';
import {connect} from 'react-redux';
import fieldByType from '../core/fields/fields-factory';
import {gettext} from '../../util/l10n';
import {activationResend} from '../../actions';

const RESEND_ACTIVATION_FIELDS = [
	[{name: 'username', fieldLabel: 'email address', type: 'text'}],
	[{name: 'password', fieldLabel: 'password', type: 'password'}]
];

const RESEND_ACTIVATION_FIELDS_NAMES = flattenDeep(RESEND_ACTIVATION_FIELDS).map(field => field.name);

const cleanState = () => {
	return reduce(RESEND_ACTIVATION_FIELDS_NAMES, (state, fieldName) => {
		state[fieldName] = '';
		return state;
	}, {});
};

class ActivationFailedComponent extends Component {
	constructor(props) {
		super(props);
		this.state = cleanState();
		this.handleFieldChange = this.handleFieldChange.bind(this);
		this.isFormFilled = this.isFormFilled.bind(this);
		this.onSubmit = this.onSubmit.bind(this);
	}

	isFormFilled() {
		return reduce(RESEND_ACTIVATION_FIELDS_NAMES, (formFilled, fieldName) => {
			return formFilled && !isEmpty(this.state[fieldName]);
		}, true);
	}

	handleFieldChange(propertyName) {
		return (value) => {
			this.setState({[propertyName]: value});
		};
	}

	onSubmit(e) {
		e.preventDefault();
		const {resendActivationAttempt} = this.props,
			activationData = pick.apply(null, [].concat(this.state).concat(RESEND_ACTIVATION_FIELDS_NAMES));
		resendActivationAttempt(activationData);
	}

	render() {
		const {activationError} = this.props,
			state = this.state,
			handleFieldChange = this.handleFieldChange,
			formIsFilled = this.isFormFilled(),
			resendActivationAttributes = {},
			resendActivationAvailableClassName = formIsFilled ? 'available' : '',
			resendActivationButtonClasses = `btn btn-block ${resendActivationAvailableClassName}`;

		if (!formIsFilled) {
			resendActivationAttributes.disabled = 'disabled';
		}

		return (
			<div className="row">
				<div className="activation-failed-section text-center">
					<div className="mail-icon">
						<i className="material-icons md-80 md-red">warning</i>
					</div>
					<div className="after-activation-instructions">
						<div>
							{gettext(activationError)}
							<br/>
							{gettext('Please fill in the form to get new activation token.')}
						</div>
					</div>
				</div>
				<div className="col-xs-12">
					<fieldset>
						<form onSubmit={this.onSubmit} className="activation-failed-form">
							{RESEND_ACTIVATION_FIELDS.map((fieldsRow) => {
								return fieldsRow.map((field) => {
									const key = field.name,
										handleChange = handleFieldChange(field.name),
										value = state[field.name];

									const fieldProps = assignIn({}, field, {
										key,
										handleChange,
										value
									});
									return fieldByType(field.type, fieldProps, 're-activation');
								});
							})}
							<div className="form-group">
								<button type="submit" className={resendActivationButtonClasses}
										{...resendActivationAttributes}>
									{gettext('Resend Activation Token')}
								</button>
							</div>
						</form>
					</fieldset>
				</div>
			</div>
		);
	}
}

ActivationFailedComponent.propTypes = {
	activationError: PropTypes.string.isRequired,
	resendActivationAttempt: PropTypes.func.isRequired
};


const mapStateToProps = (state) => {
	return {
		activationError: state.activation.error || ''
	};
};

const mapDispatchToProps = {
	resendActivationAttempt: activationResend
};

export const ActivationFailed = connect(mapStateToProps, mapDispatchToProps)(ActivationFailedComponent);