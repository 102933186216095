import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {Link} from 'react-router';
import {connect} from 'react-redux';
import {gettext} from '../../../util/l10n';
import {goBack} from 'react-router-redux';
import ReactTooltip from 'react-tooltip';
import SimpleInputField from '../../../components/core/fields/simple-input';
import {isEmpty} from 'lodash';
import {passwordRecoveryAttempt} from '../../../actions';

class ForgotPasswordPage extends Component {
	constructor(props) {
		super(props);
		this.state = {};
		this.goBack = this.goBack.bind(this);
		this.onSubmit = this.onSubmit.bind(this);
		this.handleFieldChange = this.handleFieldChange.bind(this);
	}

	goBack(e) {
		e.preventDefault();
		this.props.goBack();
	}

	onSubmit(e) {
		e.preventDefault();
		this.props.passwordRecoveryAttempt(this.state.email);
	}

	handleFieldChange(propertyName) {
		return (value) => {
			this.setState({[propertyName]: value});
		};
	}

	render() {
		const {email} = this.state,
			{errors} = this.props,
			submitFormAvailable = !isEmpty(email) ? 'available' : '',
			recoverPasswordClassNames = `btn btn-block ${submitFormAvailable}`;

		return (
			<div className="forgot-password">
				<div className="forgot-password-header">
					<Link onClick={this.goBack} to="#">
						<button className="menu-button">
							<i className="material-icons md-white md-24">arrow_back</i>
						</button>
					</Link>
					<div className="forgot-password-title">
						{gettext('Password recovery')}
					</div>
				</div>
				<form onSubmit={this.onSubmit}>
					<fieldset>
						<div className="forgot-password-form container">
							<div className="form-group row forgot-password-description">
								{gettext('Please enter the email address you used to register.')}
								{gettext('We will send you an email explaining how to reset your password.')}
							</div>
							<div className="form-group row forgot-password-email">
								<SimpleInputField inputFieldType="text"
												  fieldLabel={gettext('email address')}
												  value={email}
												  handleChange={this.handleFieldChange('email')}
												  name="email"
												  errorMessages={errors}
								/>
							</div>
							<div className="form-group row forgot-password-buttons">
								<button type="submit"
										className={recoverPasswordClassNames}>{gettext('Recover')}</button>
							</div>
						</div>
					</fieldset>
				</form>
				<ReactTooltip type="error" event="click" globalEventOff="mouseout"/>
			</div>
		);
	}
}

ForgotPasswordPage.propTypes = {
	goBack: PropTypes.func.isRequired,
	passwordRecoveryAttempt: PropTypes.func.isRequired,
	errors: PropTypes.string
};

const mapStateToProps = (state) => {
	return {
		errors: state.account.restore_password_error ? 'Please re-check email you\'ve entered' : ''
	};
};

const mapDispatchToProps = {
	goBack,
	passwordRecoveryAttempt
};

export const ForgotPassword = connect(mapStateToProps, mapDispatchToProps)(ForgotPasswordPage);