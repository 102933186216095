import {ACTIVATION_FAILED, ACTIVATION_SUCCEED} from '../constants/activation';
import {assign, omit} from 'lodash';

const activation = (state = {}, action) => {
	const updatedState = omit(assign({}, state), 'result', 'error');
	switch (action.type) {
		case ACTIVATION_FAILED:
			return assign(updatedState, {
				error: action.payload.error
			});
		case ACTIVATION_SUCCEED:
			return assign(updatedState, {
				result: action.payload.result
			});
	}
	return updatedState;
};

export default activation;