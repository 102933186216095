import React from 'react';
import PropTypes from 'prop-types';
import {isFunction} from 'lodash';
import {Link} from 'react-router';
import {connect} from 'react-redux';
import {toggleMenu} from '../../actions';

const itemsList = (Component, props) => {

	class ItemsListPage extends React.Component {
		constructor(props) {
			super(props);
			this.toggleMenu = this.toggleMenu.bind(this);
			this.addNewListItem = this.addNewListItem.bind(this);
		}

		toggleMenu(e) {
			e.preventDefault();
			this.props.toggleMenu();
		}

		addNewListItem(e) {
			e.preventDefault();
			switch (props.addNewListItem.type) {
				case 'action':
					this.props.addNewListItem();
					break;
				case 'modal':
					if (isFunction(this.props.showModal)) {
						this.props.showModal(props.addNewListItem.modal);
					}
					break;
				default:
					break;
			}
		}

		render() {
			const {itemsListTitle} = props;

			return (
				<div className="items-list">
					<div className="items-list-header">
						<Link onClick={this.toggleMenu} to="#">
							<button className="menu-button">
								<i className="material-icons md-white md-24">menu</i>
							</button>
						</Link>
						<div className="items-list-title">
							{itemsListTitle}
						</div>
					</div>
					<div className="add-new-list-item">
						<button className="add-new-icon" onClick={this.addNewListItem}>
							<i className="material-icons md-white md-28">add</i>
						</button>
					</div>
					<div className="items-list-items">
						<Component classNames="items-list-list-items" {...this.props}/>
					</div>
				</div>
			);
		}
	}

	ItemsListPage.propTypes = {
		toggleMenu: PropTypes.func.isRequired
	};

	const mapStateToProps = () => {
		return {};
	};

	const mapDispatchToProps = {
		toggleMenu
	};

	if (props.addNewListItem.type === 'action') {
		mapDispatchToProps.addNewListItem = props.addNewListItem.action;
	}

	return connect(mapStateToProps, mapDispatchToProps)(ItemsListPage);
};

export {itemsList};