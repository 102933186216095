import React from 'react';
import PropTypes from 'prop-types';
import MenuItems from './menu-items/menu-items';
import {connect} from 'react-redux';
import {gettext} from '../../util/l10n';
import VesselsMenu from './vessels-menu/vessels-menu';
import {toggleMenu, transitionEnd} from '../../actions';

const pageWithMenu = (Component) => {

	class PageWithMenu extends React.Component {

		constructor(props) {
			super(props);
			this.animationEnd = this.animationEnd.bind(this);
			this.toggleMenu = this.toggleMenu.bind(this);
		}

		toggleMenu() {
			this.props.toggleMenu();
		}

		animationEnd() {
			this.props.transitionEnd();
		}

		componentDidMount() {
			this._pageMenu.addEventListener('transitionend', this.animationEnd);
		}

		componentWillUnmount() {
			this._pageMenu.removeEventListener('transitionend', this.animationEnd);
		}

		render() {
			const hasMenu = this.props.hasMenu,
				menuClassName = 'page-with-menu' + (hasMenu ? ' menu-active' : '');
			return (
				<div className={menuClassName}>
					<div ref={(elem) => this._pageMenu = elem} className="page-menu">
						<div className="menu-container">
							<div className="menu-top-part">
								<div className="menu-icon-keeper" onClick={this.toggleMenu}>
									<img className="menu-logo" src="/img/logo/white/logo.png"
										 srcSet="/img/logo/white/logo@2x.png 2x, /img/logo/white/logo@3x.png 3x"/>
								</div>
								<div className="menu-vessels">
									<div className="menu-vessels-topic">
										{gettext('vessel')}
									</div>
									<div className="menu-vessels-menu">
										<VesselsMenu/>
									</div>
								</div>
							</div>
							<MenuItems toggleMenu={this.toggleMenu}/>
						</div>
						<div className="menu-margin" onClick={this.toggleMenu}></div>
					</div>
					<div className="page-element">
						<Component {...this.props}/>
					</div>
				</div>
			);

		}
	}

	PageWithMenu.propTypes = {
		hasMenu: PropTypes.bool
	};

	const mapStateToProps = (state) => ({
		hasMenu: state.menu.hasMenu || false,
		vessels: state.vessels.list
	});

	const mapDispatchToProps = {
		transitionEnd,
		toggleMenu
	};

	return connect(mapStateToProps, mapDispatchToProps)(PageWithMenu);
};

export {pageWithMenu};