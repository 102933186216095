import {
	ORDER_CALCULATED,
	ORDER_CALCULATION_FAILED,
	ORDER_CLEAN_CALCULATION_ERRORS,
	ORDER_RESET
} from '../constants/orders';
import {MODAL_DEACTIVATED} from '../constants/modal';
import {assign, omit} from 'lodash';

const orders = (state = {orderDetails: {}}, action) => {
	const newState = omit(state, 'calculateErrors');

	switch (action.type) {
		case ORDER_CALCULATED:
			return assign(newState, {
				orderDetails: action.payload.orderDetails
			});
		case ORDER_CALCULATION_FAILED:
			return assign(newState, {
				calculateErrors: action.payload.error
			});
		case ORDER_CLEAN_CALCULATION_ERRORS:
			return assign(newState);
		case MODAL_DEACTIVATED:
		case ORDER_RESET:
			return assign(newState, {orderDetails: {}});
	}
	return state;
};

export default orders;