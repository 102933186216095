import React from 'react';
import PropTypes from 'prop-types';
import {getCurrency} from '../../util/currencies';
import {formatPrice} from '../../util/formatter';
import {gettext} from '../../util/l10n';

const paymentAmount = (props) => {
	const {currency, price, smallerText, bolderText, className = ''} = props,
		smallTextSection = smallerText ? (
			<div className="order-total-text-small">{smallerText}</div>) : '',
		bolderTextSection = bolderText ? (
			<div className="order-total-text-small-bolder">
				{bolderText}
			</div>
		) : '',
		containerClasses = `order-amount-details ${className}`;

	return (
		<div className={containerClasses}>
			<div className="row">
				<div className="col-xs-7 order-total-clarification">
					<div className="order-total-text">
						{gettext('Total to pay')}
					</div>
					{smallTextSection}
					{bolderTextSection}
				</div>
				<div className="col-xs-5 order-amount-price">
					{getCurrency(currency)} {formatPrice(price)}
				</div>
			</div>
		</div>
	);
};

paymentAmount.propTypes = {
	currency: PropTypes.string,
	price: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
	smallerText: PropTypes.string,
	bolderText: PropTypes.string,
	className: PropTypes.string
};

export {paymentAmount};