import React from 'react';
import {assignIn, first, isArray, isEmpty, noop} from 'lodash';
import fieldByType from '../../../components/core/fields/fields-factory';

const VESSEL_FIELDS = [
	[{name: 'name', fieldLabel: 'Vessel name', type: 'text'}],
	[{name: 'reg_code', fieldLabel: 'Registration code', type: 'text'}],
	[
		{name: 'size_unit', fieldLabel: 'Units', type: 'drop-down'},
		{name: 'length', fieldLabel: 'Length', type: 'text'},
		{name: 'width', fieldLabel: 'Width', type: 'text'},
		{name: 'draught', fieldLabel: 'Draught', type: 'text'}
	],
	[
		{name: 'flag', fieldLabel: 'Country', type: 'drop-down'},
		{name: 'type', fieldLabel: 'Type', type: 'drop-down'}
	],
	[
		{
			name: 'key_home_harbour', textName: 'text_key_home_harbour', fieldLabel: 'Home Harbour',
			type: 'auto-fill', autoCompleteType: 'harbours'
		}
	]
];

const FIELDS_ROWS = 12;

const valuesListByName = (fieldName) => {
	switch (fieldName) {
		case 'type':
			return 'vessel_type';
		case 'flag':
			return 'country';
		default:
			return fieldName;
	}
};

const vesselFields = (options, state) => {
	const {
		handleFieldChange, valuesLists, requestAutoComplete,
		cleanAutoComplete, autocomplete, save_errors
	} = options;
	return VESSEL_FIELDS.map((fieldsRow, rowNumber) => {
		const cols = Math.ceil(FIELDS_ROWS / fieldsRow.length),
			classNames = `col-xs-${cols}`,
			rowKey = `fields-row-${rowNumber}`;
		return (
			<div key={rowKey} className="row">
				{fieldsRow.map((field) => {
					const key = field.name,
						handleChange = handleFieldChange(field.name),
						errorMessages = isEmpty(save_errors[field.name]) ? '' :
							isArray(save_errors[field.name]) ? first(save_errors[field.name]) : save_errors[field.name],
						valuesList = valuesLists[valuesListByName(field.name)],
						hasValuesList = !isEmpty(valuesList),
						value = state[field.name] || (hasValuesList ? first(valuesList).value : ''),
						isAutoFill = field.type === 'auto-fill',
						displayText = isAutoFill ? state[field.textName] : '',
						handleTextChange = isAutoFill ? handleFieldChange(field.textName) : noop,
						errorWrapperClassNames = 'col-xs-12';

					const fieldProps = assignIn({}, field, {
						classNames,
						key,
						handleChange,
						handleTextChange,
						errorMessages,
						valuesList,
						requestAutoComplete,
						cleanAutoComplete,
						autocomplete,
						value,
						displayText,
						errorWrapperClassNames
					});
					return fieldByType(field.type, fieldProps, 'vessel');
				})}
			</div>
		);
	});
};

export default vesselFields;