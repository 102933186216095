import {
	CURRENT_VESSEL_CHANGED,
	SAVE_VESSEL_FAILED,
	SINGLE_VESSEL_LOADED,
	SINGLE_VESSEL_RESET,
	VESSEL_CHECK_INS_LOADED,
	VESSEL_REMOVED,
	VESSELS_LOADED
} from '../constants/vessels';
import {USER_LOGGED_OUT} from '../constants/user-details';
import {LOCATION_CHANGE} from 'react-router-redux';
import {assign, filter, omit} from 'lodash';

const vessels = (state = {}, action) => {
	const updatedState = omit(assign({}, state), 'vessel_save_errors');
	switch (action.type) {
		case VESSELS_LOADED:
			return assign(updatedState, {list: action.payload.vessels});
		case VESSEL_REMOVED: {
			const vesselsWithoutRemoved = filter(state.list, (vessel) => vessel.id !== action.payload.id).slice();
			return assign(updatedState, {list: vesselsWithoutRemoved});
		}
		case CURRENT_VESSEL_CHANGED: {
			const {activeVesselId} = action.payload;
			return assign(updatedState, {activeVesselId});
		}
		case USER_LOGGED_OUT:
			return omit(updatedState, 'list', 'invalidateTime');
		case SINGLE_VESSEL_LOADED:
			return assign(updatedState, {current: action.payload.vessel});
		case LOCATION_CHANGE:
		case SINGLE_VESSEL_RESET:
			return assign(updatedState, {current: {}});
		case SAVE_VESSEL_FAILED:
			return assign(updatedState, {vessel_save_errors: action.payload.error, current: action.payload.vesselData});
		case VESSEL_CHECK_INS_LOADED:
			return assign(updatedState, {lastCheckIns: action.payload.lastCheckIns});
	}
	return updatedState;
};

export default vessels;