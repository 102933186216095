import {reduce} from 'lodash';

const harbourDimensionPrefix = 'max_vessel_';
const DIMENSIONS = ['length', 'width', 'draught'];
const calculateHarbourDimension = (harbour, dimension) => harbour[harbourDimensionPrefix + dimension];

export const vesselFitIntoHarbour = (vessel, harbour) => {
	return reduce(DIMENSIONS, (doesFit, dimension) => {
		const vesselDimension = parseFloat(vessel[dimension]),
			harbourDimension = parseFloat(calculateHarbourDimension(harbour, dimension));
		return doesFit && (vesselDimension <= harbourDimension);
	}, true);
};