/* global google */
'use strict';

import {isPointInBounds} from './geo-utils';
import GeoPoint from './geo-point';

const boundsRegexPattern = '\\[([^;]+);([^;]+)\\]';

export default class GeoBounds {
	constructor(sw, ne) {
		if (ne.lat < sw.lat) {
			throw new Error('Incorrect bound latitude for SW and NE provided');
		}

		if (ne.lng < sw.lng) {
			throw new Error('Incorrect bound longitude for SW and NE provided');
		}

		this.sw = sw;
		this.ne = ne;
	}

	static fromGoogleLatLngBounds(latLngBounds) {
		let sw = GeoPoint.fromGoogleLatLng(latLngBounds.getSouthWest()),
			ne = GeoPoint.fromGoogleLatLng(latLngBounds.getNorthEast());
		return new GeoBounds(sw, ne);
	}

	toGoogleLatLngBounds() {
		if (typeof google === 'undefined') {
			throw new Error('Cant produce LatLng for google, as google is undefined');
		}
		return new google.maps.LatLngBounds(this.sw.toGoogleLatLng(), this.ne.toGoogleLatLng());
	}

	static fromString(bounds) {
		const boundsRegex = new RegExp(boundsRegexPattern, 'ig'),
			execResults = boundsRegex.exec(bounds);
		if (!execResults) {
			return null;
		}
		const sw = GeoPoint.fromString(execResults[1]),
			ne = GeoPoint.fromString(execResults[2]);
		return new GeoBounds(sw, ne);
	}

	contains(anotherBounds) {
		return isPointInBounds(anotherBounds.sw, this) && isPointInBounds(anotherBounds.ne, this);
	}

	toString() {
		return `[${this.sw};${this.ne}]`;
	}
}