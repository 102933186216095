import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {assignIn, filter, find, first, isArray, isEmpty, negate, pickBy} from 'lodash';
import {connect} from 'react-redux';
import {gettext} from '../../../util/l10n';
import {loadOrderDetails, loadUserCreditCards, storeCurrentCard, submitPayment} from '../../../actions';
import {Loading, PaymentAmount} from '../../../components';
import {CreditCardInfo} from './credit-card-info';
import {shortDateFormat} from '../../../util/formatter';
import {isEmptyField} from '../../../util/field-util';
import fieldByType from '../../../components/core/fields/fields-factory';

class ProcessOrderModal extends Component {
	constructor(props) {
		super(props);
		this.state = {
			dropDownActive: false,
			validationErrors: {},
			submitInProgress: false
		};
		this.hideModal = this.hideModal.bind(this);
		this.checkEscClicked = this.checkEscClicked.bind(this);
		this.onSubmit = this.onSubmit.bind(this);
		this.handleDropDownActivated = this.handleDropDownActivated.bind(this);
		this.creditCardSelected = this.creditCardSelected.bind(this);
		this.getFirstCreditCard = this.getFirstCreditCard.bind(this);
		this.getLastCreditCard = this.getLastCreditCard.bind(this);
		this.hasCreditCard = this.hasCreditCard.bind(this);
		this.addNewCreditCard = this.addNewCreditCard.bind(this);
		this.agreementCheckbox = this.agreementCheckbox.bind(this);
		this.validationErrors = this.validationErrors.bind(this);
		this.handleFieldChange = this.handleFieldChange.bind(this);
	}

	addNewCreditCard(e) {
		e.preventDefault();
		e.stopPropagation();
		const {showModal, loadUserCreditCards, storeCurrentCard} = this.props;
		showModal('addCreditCard', {
			passCreditCardData: (cardData) => {
				if (cardData.saveCreditCard) {
					return loadUserCreditCards();
				}
				storeCurrentCard(cardData.payload);
			}
		});
	}

	handleDropDownActivated(e) {
		e.preventDefault();
		e.stopPropagation();
		const {submitInProgress} = this.state;

		if (submitInProgress) {
			return;
		}
		this.setState({dropDownActive: !this.state.dropDownActive});
	}

	hasCreditCard() {
		const {currentCard} = this.props;

		return !isEmpty(currentCard);
	}

	firstCreditCard() {
		const {currentCard} = this.props;

		return (
			<CreditCardInfo card={currentCard} outerTag="div"/>
		);
	}

	createCardLink() {
		return (
			<div className="credit-card-item">
				<a className="add-card-link" href="#" onClick={this.addNewCreditCard}>
					<div className="credit-card-number">{gettext('Add a bank card')}</div>
					<i className="material-icons md-24 md-inactive">credit_card</i>
				</a>
			</div>
		);
	}

	getFirstCreditCard() {
		const hasCreditCard = this.hasCreditCard();

		if (hasCreditCard) {
			return this.firstCreditCard();
		} else {
			return this.createCardLink();
		}
	}

	getLastCreditCard() {
		const hasCreditCard = this.hasCreditCard();

		if (hasCreditCard) {
			return this.createCardLink();
		} else {
			return '';
		}
	}

	creditCardSelected(card) {
		const {storeCurrentCard} = this.props;
		storeCurrentCard(card);
		this.setState({dropDownActive: false});
	}

	onSubmit(e) {
		e.preventDefault();
		const {submitInProgress} = this.state;

		if (submitInProgress) {
			return;
		}
		const validationErrors = this.validationErrors();
		if (!isEmpty(validationErrors)) {
			this.setState({validationErrors});
			return;
		}

		this.setState({submitInProgress: true});
		const {currentCard, harbour, submitPayment, activeVesselId, checkInState} = this.props,
			{crew_total, key_previous_harbour, notes_user, text_key_previous_harbour, end_date_time} = checkInState,
			checkInData = pickBy({
				key_harbour: harbour.id,
				key_user_vessel: activeVesselId,
				key_previous_harbour,
				previous_harbour: text_key_previous_harbour,
				notes_user,
				crew_total,
				end_date_time
			}, negate(isEmptyField));

		submitPayment(currentCard, checkInData, harbour);
	}

	hideModal(e) {
		e.preventDefault();
		const {submitInProgress} = this.state;

		if (submitInProgress) {
			return;
		}
		const {hideModal} = this.props;
		hideModal();
	}

	checkEscClicked(e) {
		if (e.which === 27) {
			this.hideModal(e);
		}
	}

	componentWillReceiveProps(nextProps) {
		const {save_errors, currentCard, creditCards, hideModal, storeCurrentCard} = nextProps,
			firstCard = !isEmpty(creditCards) ? first(creditCards) : null;

		this.setState({submitInProgress: false});

		if (!isEmpty(save_errors)) {
			return hideModal();
		}
		if (!currentCard && firstCard) {
			storeCurrentCard(firstCard);
		}
	}

	componentDidMount() {
		const {creditCards, orderDetails, loadUserCreditCards, loadOrderDetails, harbour} = this.props;
		if (!isArray(creditCards)) {
			loadUserCreditCards();
		}
		if (isEmpty(orderDetails)) {
			loadOrderDetails(harbour);
		}
	}

	handleFieldChange(propertyName) {
		return (value) => {
			const {submitInProgress} = this.state;

			if (submitInProgress) {
				return;
			}
			this.setState({
				[propertyName]: value
			});
		};
	}

	validationErrors() {
		const {payment_rules_agreement} = this.state,
			{currentCard} = this.props,
			validationIssues = {};

		if (!payment_rules_agreement) {
			validationIssues.payment_rules_agreement = gettext('You need to accept harbour rules');
		}

		if (isEmpty(currentCard)) {
			validationIssues.payment_rules_agreement = gettext('You have to choose payment method');
		}

		return validationIssues;
	}

	agreementCheckbox() {
		const agreementFieldName = 'payment_rules_agreement',
			value = this.state[agreementFieldName],
			errorWrapperClassNames = 'col-xs-12',
			fieldLabel = 'I have read and agree to harbour rules',
			handleChange = this.handleFieldChange(agreementFieldName),
			errorMessages = this.state.validationErrors[agreementFieldName],
			fieldProps = assignIn({
				name: agreementFieldName,
				handleChange,
				errorMessages,
				value,
				fieldLabel,
				errorWrapperClassNames
			});

		return (
			<div className="order-agreement-details">
				{fieldByType('checkbox', fieldProps, agreementFieldName)}
			</div>
		);
	}

	render() {
		const {creditCards, currentCard = {}, orderDetails, checkInState, vessels, activeVesselId, harbour} = this.props,
			{end_date_time, crew_total} = checkInState,
			{dropDownActive, submitInProgress} = this.state,
			validationErrors = this.validationErrors(),
			currentVessel = find(vessels, (vessel) => vessel.id === activeVesselId) || first(vessels),
			hasEmptyPart = !isArray(creditCards) || isEmpty(orderDetails),
			loadingClassName = hasEmptyPart || submitInProgress ? ' loading' : '',
			submitInProgressClassName = submitInProgress ? ' submit-in-progress' : '',
			formClassNames = `generic-modal-window-body order-window-body ${loadingClassName} ${submitInProgressClassName}`,
			startDate = shortDateFormat(new Date()),
			endDate = shortDateFormat(end_date_time),
			cardsListClassName = 'dropdown-menu cards-list-menu' + (dropDownActive ? ' activated' : ''),
			proceedAndPayAvailabilityClassNames = isEmpty(validationErrors) ? 'available' : '',
			proceedAndPayClassNames = `btn btn-main-action ${proceedAndPayAvailabilityClassNames}`,
			orderPaymentMethodsClassName = 'btn-group btn-group-justified order-data-field order-payment-methods' +
				(dropDownActive ? ' drop-down-activated' : ''),
			creditCardsList = !isEmpty(creditCards) ?
				filter(creditCards, card => card.token !== currentCard.token) :
				[];

		return (
			<div className="generic-modal-window">
				<div className="generic-modal-window-header-container">
					<div className="generic-modal-window-header-content">
						{gettext('Booking order')}
					</div>
				</div>
				<form className={formClassNames}
					  onKeyUp={this.checkEscClicked}
					  onSubmit={this.onSubmit}>
					<Loading radiusMax={10} radiusMin={6} color="#254ea2"
							 className="generic-modal-window-body-loading"/>
					<div className="order-data-fields">
						<div className="row">
							<div className="col-xs-6 order-data-field">
								<div className="order-data-field-header">
									{gettext('Check In')}
								</div>
								<div className="order-data-field-value">
									{startDate}
								</div>
							</div>
							<div className="col-xs-6 order-data-field">
								<div className="order-data-field-header">
									{gettext('Check Out')}
								</div>
								<div className="order-data-field-value">
									{endDate}
								</div>
							</div>
						</div>
						<div className="row">
							<div className="col-xs-6 order-data-field">
								<div className="order-data-field-header">
									{gettext('Vessel')}
								</div>
								<div className="order-data-field-value">
									{currentVessel.name}
								</div>
							</div>
							<div className="col-xs-6 order-data-field">
								<div className="order-data-field-header">
									{gettext('Passengers')}
								</div>
								<div className="order-data-field-value">
									{crew_total}
								</div>
							</div>
						</div>
					</div>
					<div className={orderPaymentMethodsClassName}>
						<div className="order-data-field-header">
							{gettext('Payment method')}
						</div>
						<button onClick={this.handleDropDownActivated} type="button"
								className="order-payment-menu-button">
							{this.getFirstCreditCard()}
						</button>
						<ul className={cardsListClassName}>
							{creditCardsList.map(card => {
								const creditCardKey = `credit-card-${card.token}`;
								return (
									<CreditCardInfo key={creditCardKey} card={card}
													cardSelected={this.creditCardSelected}/>
								);
							})}
							{this.getLastCreditCard()}
						</ul>
						<div className="order-data-fields-separator"/>
					</div>
					<PaymentAmount currency={orderDetails.currency}
								   price={orderDetails.price}
								   smallerText={gettext('You will be checked in to')}
								   bolderText={harbour.name}/>
					{this.agreementCheckbox()}
					<div className="order-actions">
						<div className="row generic-modal-window-buttons">
							<button className="btn btn-wout-back available"
									onClick={this.hideModal}>{gettext('Back')}</button>
							<button type="submit"
									className={proceedAndPayClassNames}>{gettext('Check In & Pay')}</button>
						</div>
					</div>
				</form>
			</div>
		);
	}
}

ProcessOrderModal.propTypes = {
	hideModal: PropTypes.func.isRequired,
	showModal: PropTypes.func.isRequired,

	harbour: PropTypes.object,

	loadUserCreditCards: PropTypes.func.isRequired,
	creditCards: PropTypes.array,
	currentCard: PropTypes.object,
	storeCurrentCard: PropTypes.func.isRequired,

	loadOrderDetails: PropTypes.func.isRequired,
	orderDetails: PropTypes.object,

	vessels: PropTypes.array,
	activeVesselId: PropTypes.number,

	save_errors: PropTypes.any,
	checkInState: PropTypes.object,

	submitPayment: PropTypes.func.isRequired
};

const mapStateToProps = (state) => {
	return {
		creditCards: state.creditCards.list,
		currentCard: state.creditCards.currentCard,
		orderDetails: state.orders.orderDetails || {},

		vessels: state.vessels.list || [],
		activeVesselId: state.vessels.activeVesselId,

		save_errors: state.orders.calculateErrors,
		checkInState: state.checkInOut.checkInState || {}
	};
};

const mapDispatchToProps = {
	loadUserCreditCards,
	loadOrderDetails,
	storeCurrentCard,
	submitPayment
};

export const ProcessOrder = connect(mapStateToProps, mapDispatchToProps)(ProcessOrderModal);