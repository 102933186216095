import {
	CREDIT_CARD_ADD_FAILED,
	CREDIT_CARD_ADDED,
	CREDIT_CARD_CLEAN_ERROR,
	CREDIT_CARD_REMOVED,
	CREDIT_CARDS_LOADED,
	PAYMENT_TOKEN_FETCHED,
	STORE_CURRENT_CARD
} from '../constants/credit-cards';
import {
	getCustomerPaymentToken,
	loadUserCreditCards as loadUserCreditCardsApi,
	removeCreditCard as removeCreditCardApi
} from '../mah-api';
import {isNull} from 'lodash';
import * as logger from '../util/logger';
import {storeCreditCard} from '../mah-api/index';
import {gettext} from '../util/l10n';

const creditCardRemoved = (creditCardToken) => {
	return {
		type: CREDIT_CARD_REMOVED,
		payload: {
			creditCardToken
		}
	};
};

const creditCardsLoaded = (creditCards) => {
	return {
		type: CREDIT_CARDS_LOADED,
		payload: {
			creditCards
		}
	};
};

const removeCreditCard = (creditCardToken) => {
	return (dispatch) => {
		removeCreditCardApi(creditCardToken)
			.then(() => {
				dispatch(creditCardRemoved(creditCardToken));
			})
			.catch((error) => {
				logger.error('cant remove card: ' + creditCardToken, error);
			});
	};
};

const loadUserCreditCards = (force = false) => {
	return (dispatch, getState) => {
		const {creditCards} = getState();
		if (!isNull(creditCards.list) && !force) {
			return;
		}
		loadUserCreditCardsApi()
			.then(creditCards => {
				dispatch(creditCardsLoaded(creditCards));
			})
			.catch(error => {
				logger.error('cant list cards', error);
			});
	};
};

const cleanError = () => {
	return {
		type: CREDIT_CARD_CLEAN_ERROR,
		payload: {}
	};
};

const creditCardAdded = () => {
	return {
		type: CREDIT_CARD_ADDED,
		payload: {}
	};
};

const creditCardAddFailed = () => {
	return {
		type: CREDIT_CARD_ADD_FAILED,
		payload: {
			saveErrors: gettext('We were unable to process your card, please try again in a while.')
		}
	};
};

const addCreditCardAttempt = (nonce, generateCardAdded = true) => {
	return (dispatch) => {
		storeCreditCard(nonce)
			.then(() => {
				if (generateCardAdded) {
					dispatch(creditCardAdded());
				}
				dispatch(loadUserCreditCards(true));
			})
			.catch(error => {
				dispatch(creditCardAddFailed());
				logger.error(error);
			});
	};
};

const paymentTokenFetched = (token) => {
	return {
		type: PAYMENT_TOKEN_FETCHED,
		payload: {
			token
		}
	};
};

const fetchPaymentToken = () => {
	return (dispatch, getState) => {
		const {creditCards} = getState();
		if (creditCards.token) {
			return;
		}
		getCustomerPaymentToken()
			.then(token => {
				dispatch(paymentTokenFetched(token));
			})
			.catch(error => {
				logger.error('cant get token', error);
			});
	};
};

const storeCurrentCard = (card) => {
	return {
		type: STORE_CURRENT_CARD,
		payload: {
			card
		}
	};
};

export {removeCreditCard, loadUserCreditCards, addCreditCardAttempt, fetchPaymentToken, cleanError, storeCurrentCard};