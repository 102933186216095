import {assignIn, omit} from 'lodash';
import {LOGIN_ATTEMPT_FAILURE, LOGIN_ATTEMPT_SUCCESS, USER_LOGGED_OUT} from '../constants/user-details';

const auth = (state = {}, action) => {
	const updatedState = omit(assignIn({}, state), 'loginCredentialsIncorrect');
	switch (action.type) {
		case LOGIN_ATTEMPT_SUCCESS:
			return omit(assignIn(updatedState, action.payload), 'notLoggedIn');
		case USER_LOGGED_OUT:
			return omit(assignIn(updatedState, {notLoggedIn: true}), 'user');
		case LOGIN_ATTEMPT_FAILURE:
			return assignIn(updatedState, {loginCredentialsIncorrect: action.payload.error});
	}
	return updatedState;
};

export default auth;