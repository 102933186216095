import {loadClassifiers} from '../classifier';
import {classifierLoaded} from '../../actions/classifier';
import logger from '../../util/logger';

export const ensureClassifiersLoaded = (classifiers, next) => {
	return (dispatch, getState) => {
		loadClassifiers(...classifiers)
			.then(loadedClassifiers => {
				Object.keys(loadedClassifiers).forEach(classifierKey =>
					dispatch(classifierLoaded(classifierKey, loadedClassifiers[classifierKey])));
				next(dispatch, getState);
			})
			.catch(error => {
				logger.error('Classifiers not fetched', classifiers, error);
				next(dispatch, getState);
			});
	};
};