import React from 'react';
import {assignIn, first, isArray, isEmpty} from 'lodash';
import fieldByType from '../../../components/core/fields/fields-factory';
import {CHANGE_PASSWORD_FIELDS} from '../../../constants/account';

const changePasswordFields = (options, state) => {
	const {
		handleFieldChange, save_errors
	} = options;

	return CHANGE_PASSWORD_FIELDS.map((fieldsRow, rowNumber) => {
		const classNames = 'col-xs-12', rowKey = `fields-row-${rowNumber}`;
		return (
			<div key={rowKey} className="row">
				{fieldsRow.map((field) => {
					const key = field.name,
						handleChange = handleFieldChange(field.name),
						errorMessages = isEmpty(save_errors[field.name]) ? '' :
							isArray(save_errors[field.name]) ? first(save_errors[field.name]) : save_errors[field.name],
						value = state[field.name],
						errorWrapperClassNames = 'col-xs-12';

					const fieldProps = assignIn({}, field, {
						classNames,
						key,
						handleChange,
						errorMessages,
						value,
						errorWrapperClassNames
					});
					return fieldByType(field.type, fieldProps, 'account-password');
				})}
			</div>
		);
	});
};

export {changePasswordFields};