import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {GoogleMapComponent} from '../../../components/google-map/google-map';
import {isEmpty} from 'lodash';
import {Link} from 'react-router';
import {connect} from 'react-redux';
import {gettext} from '../../../util/l10n';
import {push} from 'react-router-redux';
import {locateMe, locationUpdated, obtainCurrentPosition, toggleHarbourDetails, toggleMenu} from '../../../actions';
import {HarbourInformation} from '../../../components/harbour-information/harbour-information';
import GeoPoint from '../../../util/geo/geo-point';
import {forVesselInHarbour, isNowOrFuture} from '../../../util/booking-details/booking-details';

class UserHomePage extends Component {
	constructor(props) {
		super(props);
		this.locateMe = this.locateMe.bind(this);
		this.toggleMenu = this.toggleMenu.bind(this);
		this.openHarboursList = this.openHarboursList.bind(this);
		this.footerSection = this.footerSection.bind(this);
		this.customControls = this.customControls.bind(this);
		this.toggleHarbourDetails = this.toggleHarbourDetails.bind(this);
		this.getCurrentVessel = this.getCurrentVessel.bind(this);
		this.mapLoaded = this.mapLoaded.bind(this);
		this.state = {
			mapLoaded: false
		};
	}

	mapLoaded() {
		this.setState({
			mapLoaded: true
		});
	}

	getCurrentVessel() {
		const {vesselsList, activeVesselId} = this.props;

		return vesselsList.find(vessel => vessel.id === activeVesselId) || {};
	}

	toggleHarbourDetails() {
		this.props.toggleHarbourDetails();
	}

	customControls() {
		let locateMe = document.createElement('div');
		locateMe.className = 'locate-me-icon';
		locateMe.addEventListener('click', () => this.locateMe());
		return [locateMe];
	}

	footerSection() {
		const {
				activeHarbour, currentLocation,
				harbourServices,
				harbourBerths, showModal,
				showHarbourDetails, activeBookings, vesselsList
			} = this.props,
			currentVessel = this.getCurrentVessel();
		if (isEmpty(activeHarbour)) {
			return (
				<button onClick={(e) => this.openHarboursList(e)} className="harbours-list">
					{gettext('HARBOUR LIST')}
				</button>
			);
		}
		const harbourBookings = activeBookings
				.filter(isNowOrFuture)
				.filter(forVesselInHarbour(currentVessel, activeHarbour)),
			hasActiveBooking = harbourBookings.length > 0;

		return (
			<HarbourInformation harbour={activeHarbour}
			                    currentLocation={currentLocation}
			                    currentVessel={currentVessel}
			                    harbourServices={harbourServices}
			                    harbourBerths={harbourBerths}
			                    showModal={showModal}
			                    showMore={showHarbourDetails}
			                    toggleHarbourDetails={this.toggleHarbourDetails}
			                    hasActiveBooking={hasActiveBooking}
			                    activeBookings={harbourBookings}
			                    vesselsList={vesselsList}
			/>
		);
	}

	openHarboursList(e) {
		this.props.push('/harbours');
		e.preventDefault();
	}

	toggleMenu(e) {
		this.props.toggleMenu();
		e.preventDefault();
	}

	locateMe() {
		this.props.locateMe();
	}

	componentDidMount() {
		const {activeHarbour, obtainCurrentPosition, locationUpdated} = this.props;
		if (!!activeHarbour && !isEmpty(activeHarbour)) {
			const harbourCenter = new GeoPoint(activeHarbour.lat_degrees, activeHarbour.lon_degrees);
			locationUpdated(harbourCenter.toBrowserPosition());
		} else {
			obtainCurrentPosition();
		}
	}

	render() {
		const customControls = this.customControls(),
			{mapLoaded} = this.state,
			containerClasses = `user-home ${!mapLoaded ? 'map-loading' : ''}`;

		return (
			<div className={containerClasses}>
				<div className="home-search-menu">
					<div className="home-search">
						<Link onClick={this.toggleMenu} to="#">
							<button className="menu-button">
								<i className="material-icons md-white md-24">menu</i>
							</button>
						</Link>
						<div className="search-action">
							<Link to="/harbours/search">{gettext('Search for a harbor')}</Link>
						</div>
					</div>
				</div>
				<div className="map-loading-placeholder"/>
				<GoogleMapComponent controls={customControls} onMapLoaded={this.mapLoaded}/>
				{this.footerSection()}
			</div>
		);
	}
}

UserHomePage.propTypes = {
	locateMe: PropTypes.func.isRequired,
	obtainCurrentPosition: PropTypes.func.isRequired,
	toggleMenu: PropTypes.func.isRequired,
	push: PropTypes.func.isRequired,
	currentLocation: PropTypes.object,
	locationUpdated: PropTypes.func.isRequired,
	showModal: PropTypes.func.isRequired,
	hideModal: PropTypes.func.isRequired,
	activeHarbour: PropTypes.object,
	showHarbourDetails: PropTypes.bool,
	activeVesselId: PropTypes.number,
	vesselsList: PropTypes.array,
};

const mapStateToProps = (state) => {
	return {
		activeHarbour: state.harbours.activeHarbour || {},
		activeBookings: state.booking.activeBookings || [],
		showHarbourDetails: state.harbourDetails.showHarbourDetails,
		harbourServices: state.harbours.activeHarbourServices || [],
		harbourBerths: state.harbours.activeHarbourBerths || {},
		currentLocation: state.maps.currentLocation || {},
		activeVesselId: state.vessels.activeVesselId || -1,
		vesselsList: state.vessels.list || []
	};
};

const mapDispatchToProps = {
	locateMe,
	obtainCurrentPosition,
	toggleMenu,
	push,
	locationUpdated,
	toggleHarbourDetails
};

export const UserHomePageContainer = connect(mapStateToProps, mapDispatchToProps)(UserHomePage);