import {assignIn} from 'lodash';
import {TRANSITION_ENDED} from '../constants/animations';

const animations = (state = {}, action) => {
	const updated = new Date().getTime();
	switch (action.type) {
		case TRANSITION_ENDED:
			return assignIn({}, state, {updated});
	}
	return state;
};

export default animations;