import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {first, isArray, isEmpty} from 'lodash';
import {gettext} from '../../../util/l10n';

class SingleCheckboxField extends Component {
	constructor(props, context) {
		super(props, context);
		this.handleChange = this.handleChange.bind(this);
		this.displayErrors = this.displayErrors.bind(this);
	}

	handleChange(e) {
		this.props.handleChange(e.target.checked);
	}

	displayErrors() {
		const {errorWrapperClassNames, errorMessages} = this.props;
		const errorMessage = isArray(errorMessages) ? first(errorMessages) : errorMessages,
			errorTextClassName = 'errors-text' + (errorWrapperClassNames ? ` ${errorWrapperClassNames}` : ''),
			errorIconClassName = 'errors-icon' + (errorWrapperClassNames ? ` ${errorWrapperClassNames}` : '');

		return (
			<div>
				<p className={errorTextClassName} data-tip={gettext(errorMessage)}>{gettext(errorMessage)}</p>
				<p className={errorIconClassName} data-tip={gettext(errorMessage)}>
					<i className="material-icons md-error md-18">report_problem</i>
				</p>
			</div>
		);
	}

	render() {
		const {name, errorMessages, classNames, value} = this.props,
			elementKey = `input-field-${name}`,
			hasError = !isEmpty(errorMessages) ? ' has-mah-error' : '',
			customClassNames = !isEmpty(classNames) ? classNames : '',
			fieldsGroupClassName = `form-group with-checkbox ${hasError} ${customClassNames}`;

		return (
			<div className={fieldsGroupClassName}>
				<div className="checkbox">
					<label htmlFor={elementKey}>
						<input id={elementKey} type="checkbox" onChange={this.handleChange} checked={!!value}/>
						<span className="checkbox-material">
							<span className="check"/>
						</span>
						<span className="checkbox-label">{gettext(this.props.fieldLabel)}</span>
					</label>
				</div>
				{this.displayErrors()}
			</div>
		);
	}
}

SingleCheckboxField.propTypes = {
	name: PropTypes.string.isRequired,
	fieldLabel: PropTypes.string.isRequired,
	value: PropTypes.any,
	handleChange: PropTypes.func.isRequired,
	errorMessages: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
	classNames: PropTypes.string,
	errorWrapperClassNames: PropTypes.string
};

export default SingleCheckboxField;