import {RE_ACTIVATION_FAILED, RE_ACTIVATION_SUCCEED} from '../constants/re-activation';
import {resendActivation} from '../mah-api/index';

const reactivationFailed = (response) => {
	return {
		type: RE_ACTIVATION_FAILED,
		payload: {
			error: response.error
		}
	};
};

const reactivationSucceed = () => {
	return {
		type: RE_ACTIVATION_SUCCEED,
		payload: {
			result: {'success': true}
		}
	};
};

export const activationResend = (activationData) => {
	return (dispatch) => {
		resendActivation(activationData)
			.then(() => dispatch(reactivationSucceed()))
			.catch((e) => dispatch(reactivationFailed(e.response)));
	};
};