import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {first, isArray, isEmpty} from 'lodash';
import {gettext} from '../../../util/l10n';
import {isEmptyField} from '../../../util/field-util';
import {longDateFormat} from '../../../util/formatter';

class DateTimePickerField extends Component {
	constructor(props, context) {
		super(props, context);

		this.onClick = this.onClick.bind(this);
		this.displayErrors = this.displayErrors.bind(this);
	}

	onClick() {
		const {showModal, hideModal, handleChange, value} = this.props;
		showModal('datePicker', {
			header: gettext('Select check out date'),
			from: new Date(),
			to: isEmptyField(value) ? new Date() : value,
			onCancel: hideModal,
			onSuccess: (val) => {
				hideModal();
				handleChange(val);
			},
			closeOnOutsideClick: false
		});
	}

	displayErrors() {
		const {errorWrapperClassNames, errorMessages} = this.props;
		const errorMessage = isArray(errorMessages) ? first(errorMessages) : errorMessages,
			errorTextClassName = 'errors-text' + (errorWrapperClassNames ? ` ${errorWrapperClassNames}` : ''),
			errorIconClassName = 'errors-icon' + (errorWrapperClassNames ? ` ${errorWrapperClassNames}` : '');

		return (
			<div>
				<p className={errorTextClassName} data-tip={gettext(errorMessage)}>{gettext(errorMessage)}</p>
				<p className={errorIconClassName} data-tip={gettext(errorMessage)}>
					<i className="material-icons md-error md-18">report_problem</i>
				</p>
			</div>
		);
	}

	render() {
		const {name, errorMessages, classNames, value, fieldLabel, inputFieldType, dateTimeFormat = 'LL'} = this.props,
			elementKey = 'input-field-' + name,
			hasError = !isEmpty(errorMessages) ? ' has-mah-error' : '',
			customClassNames = !isEmpty(classNames) ? classNames : '',
			isEmptyClassName = isEmptyField(value) ? 'is-empty' : '',
			fieldsGroupClassName = `form-group black-readonly ${hasError} ${customClassNames} ${isEmptyClassName} label-floating`,
			formattedValue = longDateFormat(value, dateTimeFormat);

		return (
			<div className={fieldsGroupClassName}>
				<label htmlFor={elementKey} className="control-label">{gettext(fieldLabel)}</label>
				<input type={inputFieldType} value={formattedValue} className="form-control"
					   id={elementKey} onClick={this.onClick} readOnly="readOnly"/>

				{this.displayErrors()}
			</div>
		);
	}
}

DateTimePickerField.propTypes = {
	showModal: PropTypes.func.isRequired,
	hideModal: PropTypes.func.isRequired,
	name: PropTypes.string.isRequired,
	fieldLabel: PropTypes.string.isRequired,
	value: PropTypes.any,
	handleChange: PropTypes.func.isRequired,
	errorMessages: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
	classNames: PropTypes.string,
	errorWrapperClassNames: PropTypes.string,
	inputFieldType: PropTypes.string,
	dateTimeFormat: PropTypes.string
};

export default DateTimePickerField;