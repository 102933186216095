import {getClassifier, storeClassifier} from '../util/cache/classifier';
import {reduce, transform} from 'lodash';
import {fetchClassifier} from '../mah-api/index';
import {gettext} from './l10n';

const SIZE_UNITS = {
	M: gettext('Metric'),
	I: gettext('Imperial')
};

const loadSingleClassifier = (classifierName) => {
	return new Promise((resolve, reject) => {
		const cachedClassifier = getClassifier(classifierName);
		if (cachedClassifier) {
			return resolve(cachedClassifier);
		}
		fetchClassifier(classifierName)
			.then(classifiedData => {
				storeClassifier(classifierName, classifiedData);
				resolve(classifiedData);
			})
			.catch(error => reject(error));
	});
};

export const loadClassifiers = (...classifiersNames) => {
	return Promise.all(
		classifiersNames.map(classifierName => loadSingleClassifier(classifierName))
	).then(classifiersData => {
		return reduce(classifiersData, (classifiers, classifierData, classifierIndex) => {
			classifiers[classifiersNames[classifierIndex]] = classifierData;
			return classifiers;
		}, {});
	});
};

const mapCountries = (countries) => {
	return countries.map((classifier) => {
		return {
			value: classifier.iso3,
			label: classifier.name
		};
	});
};

const mapCurrencies = (currencies) => {
	return currencies.map((classifier) => {
		return {
			value: classifier.code,
			label: classifier.code
		};
	});
};

const mapCountryCodes = (countries) => {
	return countries.map((classifier) => {
		return {
			value: classifier.phone_code,
			secondary_code: classifier.iso3,
			label: '+' + classifier.phone_code
		};
	});
};

const mapSizeUnits = (sizeUnits) => {
	return sizeUnits.map((classifier) => {
		return {
			value: classifier.value,
			label: SIZE_UNITS[classifier.value] || classifier.description
		};
	});
};

const mapDefaultClassifiers = (classifiers) => {
	return classifiers.map((classifier) => {
		return {
			value: classifier.code,
			label: classifier.value
		};
	});
};

const mapVesselTypes = (vesselTypes) => {
	return mapDefaultClassifiers(vesselTypes);
};

export const mapClassifiersToLists = (classifiers) => {
	return transform(classifiers, (valuesLists, classifierValues, classifierKey) => {
		switch (classifierKey) {
			case 'currency':
				valuesLists[classifierKey] = mapCurrencies(classifierValues);
				break;
			case 'country':
				valuesLists[classifierKey] = mapCountries(classifierValues);
				break;
			case 'prefix':
				valuesLists[classifierKey] = mapCountryCodes(classifierValues);
				break;
			case 'size_unit':
				valuesLists[classifierKey] = mapSizeUnits(classifierValues);
				break;
			case 'vessel_type':
				valuesLists[classifierKey] = mapVesselTypes(classifierValues);
				break;
			default:
				valuesLists[classifierKey] = mapDefaultClassifiers(classifierValues);
		}
		return valuesLists;
	}, {});
};