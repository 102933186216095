import React from 'react';
import PropTypes from 'prop-types';
import {isCurrentlyActive} from '../../util/booking-details/booking-details';

export const BookingIcon = (props) => {
	const {booking} = props,
		isActive = isCurrentlyActive(booking),
		bookingImage = isActive ? 'active' : 'future',
		bookingImageSource = `/img/icons/bookings/${bookingImage}.png`,
		bookingImageSourceSet = `/img/icons/bookings/${bookingImage}@2x.png 2x, /img/icons/bookings/${bookingImage}@3x.png 3x`;
	return (
		<div className="booking-item-icon">
			<img src={bookingImageSource} srcSet={bookingImageSourceSet}/>
		</div>
	);
};

BookingIcon.props = {
	booking: PropTypes.object.isRequired
};