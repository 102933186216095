import React from 'react';

export const getVesselIcon = (type) => {
	const vesselImageSource = `/img/icons/vessels/${type}.png`,
		vesselImageSourceSet = `/img/icons/vessels/${type}@2x.png 2x, /img/icons/vessels/${type}@3x.png 3x`;
	return (
		<div className="vessel-item-icon">
			<img src={vesselImageSource} srcSet={vesselImageSourceSet}/>
		</div>
	);
};