import {without} from 'lodash';
import {gettext} from '../util/l10n';
export const VESSELS_LOADED = '@@mah-maps/VESSELS_LOADED';
export const SINGLE_VESSEL_LOADED = '@@mah-maps/SINGLE_VESSEL_LOADED';
export const CURRENT_VESSEL_CHANGED = '@@mah-maps/CURRENT_VESSEL_CHANGED';
export const VESSEL_REMOVED = '@@mah-maps/VESSEL_REMOVED';
export const SINGLE_VESSEL_RESET = '@@mah-maps/SINGLE_VESSEL_RESET';
export const SAVE_VESSEL_FAILED = '@@mah-maps/SAVE_VESSEL_FAILED';
export const VESSEL_CHECK_INS_LOADED = '@@mah-maps/VESSEL_CHECK_INS_LOADED';

export const FIELDS_NAMES = ['name', 'reg_code',
	'size_unit', 'length', 'width', 'draught',
	'flag', 'type', 'key_home_harbour', 'home_harbour'];

export const REQUIRED_FIELDS_NAMES = without(FIELDS_NAMES, 'key_home_harbour', 'home_harbour');

export const FIELDS_MEASURES_NAMES = ['length', 'width', 'draught'];

export const REQUIRED_FIELDS_LABELS = {
	length: gettext('Length'),
	width: gettext('Width'),
	draught: gettext('Draught'),
	name: gettext('Vessel name'),
	reg_code: gettext('Registration code'),
	flag: gettext('Country'),
	type: gettext('Type')
};