import {
	CHECKED_IN_HARBOURS_LOADED,
	CHECKED_IN_HARBOURS_RESET,
	HARBOUR_ACTIVATED,
	HARBOUR_BERTHS_LOADED,
	HARBOUR_DEACTIVATED,
	HARBOUR_SERVICES_LOADED,
	HARBOURS_LOADED,
	HOME_HARBOUR_LOADED,
	HOME_HARBOUR_RESET,
	NEARBY_HARBOUR_LOADED,
	SEARCH_CENTER_OBTAINED
} from '../constants/harbours';
import {boundsToRadius, distanceBetween} from '../util/geo/geo-utils';
import {findHarbours, storeBounds} from '../util/cache/bounds-cache';
import {
	fetchHarbourBerths,
	fetchHarbourServices,
	fetchVesselCheckIns,
	searchDefaultHarbours,
	searchHarbours
} from '../mah-api/index';
import {isEmpty, map, noop} from 'lodash';
import {findHarbourById, findHarboursById} from '../mah-api/harbours';
import * as logger from '../util/logger';
import GeoPoint from '../util/geo/geo-point';
import {getCurrentPosition} from '../util/position';
import {searchCachedHarbours, storeCachedSearchResults} from '../util/cache/harbours-cache';
import {push} from 'react-router-redux';
import {lastChecksInLoaded} from './vessels';

export const harboursLoaded = (harbours) => {
	return {
		type: HARBOURS_LOADED,
		payload: {
			harbours
		}
	};
};

const homeHarbourLoaded = (harbour) => {
	return {
		type: HOME_HARBOUR_LOADED,
		payload: {
			harbour
		}
	};
};

const harbourIsActivated = (harbour, type) => {
	return {
		type: HARBOUR_ACTIVATED,
		payload: {
			type,
			harbour
		}
	};
};

const harbourServicesLoaded = (services) => {
	return {
		type: HARBOUR_SERVICES_LOADED,
		payload: {
			services
		}
	};
};

const harbourBerthLoaded = (berths) => {
	return {
		type: HARBOUR_BERTHS_LOADED,
		payload: {
			berths
		}
	};
};

export const harbourActivated = (harbour, type = 'default') => {
	return (dispatch) => {
		dispatch(harbourIsActivated(harbour, type));

		fetchHarbourBerths(harbour.id)
			.then(harbourBerth => {
				dispatch(harbourBerthLoaded(harbourBerth));
			})
			.catch(noop);

		fetchHarbourServices(harbour.id)
			.then(harbourServices => {
				dispatch(harbourServicesLoaded(harbourServices));
			})
			.catch(noop);
	};
};

export const showHarbourDetails = (harbour, type) => {
	return (dispatch) => {
		dispatch(push('/'));
		dispatch(harbourActivated(harbour, type));
	};
};

export const deactivateHarbourMarker = () => {
	return {
		type: HARBOUR_DEACTIVATED,
		payload: {}
	};
};

export const resetHomeHarbour = () => {
	return {
		type: HOME_HARBOUR_RESET,
		payload: {}
	};
};

const checkedInHarboursLoaded = (harbours) => {
	return {
		type: CHECKED_IN_HARBOURS_LOADED,
		payload: {
			harbours
		}
	};
};

const checkedInHarboursReset = () => {
	return {
		type: CHECKED_IN_HARBOURS_RESET,
		payload: []
	};
};

const nearbyHarboursLoaded = (harbours) => {
	return {
		type: NEARBY_HARBOUR_LOADED,
		payload: {
			harbours
		}
	};
};

const searchCenterObtained = (position) => {
	return {
		type: SEARCH_CENTER_OBTAINED,
		payload: {
			position
		}
	};
};

export const fetchHarbours = (bounds) => {
	return (dispatch) => {
		let cachedHarbours = findHarbours(bounds);
		if (cachedHarbours) {
			return dispatch(harboursLoaded(cachedHarbours));
		}

		const fetchQueryDetails = boundsToRadius(bounds);
		searchHarbours(fetchQueryDetails.center.lat.toFixed(5), fetchQueryDetails.center.lng.toFixed(5), fetchQueryDetails.radius.toFixed(0))
			.then(harbours => {
				storeBounds(bounds, harbours);
				return dispatch(harboursLoaded(harbours));
			})
			.catch(noop);
	};
};

export const loadHomeHarbour = (homeHarbourId) => {
	return (dispatch) => {
		findHarbourById(homeHarbourId)
			.then(harbour => dispatch(homeHarbourLoaded(harbour)))
			.catch(err => logger.error(err));
	};
};

export const loadCheckedInHarbour = (vesselId) => {
	return (dispatch) => {
		fetchVesselCheckIns(vesselId)
			.then((checkInsList) => {
				dispatch(lastChecksInLoaded(checkInsList));
				if (isEmpty(checkInsList)) {
					return Promise.resolve([]);
				}
				const harboursIdsToCheckIn = map(checkInsList, 'key_harbour');
				return findHarboursById(harboursIdsToCheckIn);
			})
			.then((harbours) => {
				if (isEmpty(harbours)) {
					dispatch(checkedInHarboursReset());
					return;
				}
				dispatch(checkedInHarboursLoaded(harbours));
			})
			.catch(err => logger.error(err));
	};
};

const distanceToHarbour = (from) => {
	return (harbour) => {
		const hasDistance = !(isEmpty(from) || isEmpty(harbour));
		return hasDistance ?
			distanceBetween(from, new GeoPoint(harbour.lat_degrees, harbour.lon_degrees))
			: 0;
	};
};

const sortHarbours = (harbours, point) => {
	if (!point) {
		return harbours;
	}
	const calculateDistance = distanceToHarbour(point);
	return harbours.sort((harbourA, harbourB) => {
		const distanceA = calculateDistance(harbourA),
			distanceB = calculateDistance(harbourB);
		return parseInt(distanceA) - parseInt(distanceB);
	});
};

export const activateHarbourMarker = (marker) => {
	return (dispatch) => {
		findHarbourById(marker.key)
			.then((harbour) => {
				dispatch(harbourActivated(harbour, marker.type));
			})
			.catch(err => logger.error(err));
	};
};

export const loadNearbyHarbours = () => {
	return (dispatch) => {
		let point = null;
		getCurrentPosition()
			.then(position => {
				dispatch(searchCenterObtained(position));
				point = new GeoPoint(position.coords.latitude, position.coords.longitude);
				return searchCachedHarbours(point, 0);
			})
			.catch(err => {
				logger.error(err);
				return searchCachedHarbours(null, 0);
			})
			.then((cachedHarbours) => {
				if (cachedHarbours) {
					return cachedHarbours;
				}
				return point ? searchHarbours(point.lat.toFixed(5), point.lng.toFixed(5), 0) : searchDefaultHarbours();
			})
			.then(harbours => {
				const sortedHarbours = sortHarbours(harbours, point);
				storeCachedSearchResults(point, 0, sortedHarbours);
				return dispatch(nearbyHarboursLoaded(sortedHarbours));
			})
			.catch(err => logger.error(err));
	};
};