import {isBoolean, isDate, isEmpty, isNumber} from 'lodash';

export const isEmptyField = (fieldData) => {
	if (isBoolean(fieldData)) {
		return !fieldData;
	}
	if (isNumber(fieldData)) {
		return false;
	}
	if (isDate(fieldData)) {
		return false;
	}
	return isEmpty(fieldData);
};