import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {push} from 'react-router-redux';
import {isEmpty} from 'lodash';
import AddVessel from './add-vessel';
import VesselsList from './vessels-list';

class VesselsMenu extends Component {
	constructor(props, context) {
		super(props, context);
		this.handleAddVesselClick = this.handleAddVesselClick.bind(this);
	}

	handleAddVesselClick(e) {
		e.preventDefault();
		const {push} = this.props;
		push('/vessel/add');
	}

	render() {
		const {vessels} = this.props;
		const hasVessels = vessels && !isEmpty(vessels),
			vesselMenu = hasVessels ? (<VesselsList/>) : (<AddVessel handleAddVessel={this.handleAddVesselClick}/>);
		return (
			<div>
				{vesselMenu}
			</div>
		);
	}
}

VesselsMenu.propTypes = {
	vessels: PropTypes.array,
	push: PropTypes.func.isRequired
};

const mapStateToProps = (state) => ({
	vessels: state.vessels.list || []
});

export default connect(mapStateToProps, {push})(VesselsMenu);