import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {Link} from 'react-router';
import {connect} from 'react-redux';
import {gettext} from '../../../util/l10n';
import {isEmpty} from 'lodash';

class ForgotPasswordPageSuccess extends Component {
	constructor(props) {
		super(props);
		this.state = {};
		this.validatePage = this.validatePage.bind(this);
	}

	validatePage() {
		const {email} = this.props;

		if (isEmpty(email)) {
			this.props.push('/login');
		}
	}

	componentWillMount() {
		this.validatePage();
	}

	componentWillReceiveProps() {
		this.validatePage();
	}

	render() {
		const {email} = this.props;

		return (
			<div className="forgot-password">
				<div className="forgot-password-header">
					<Link to="/login">
						<button className="menu-button">
							<i className="material-icons md-white md-24">arrow_back</i>
						</button>
					</Link>
					<div className="forgot-password-title">
						{gettext('Password recovery')}
					</div>
				</div>
				<form>
					<fieldset>
						<div className="forgot-password-form container">
							<div className="form-group row forgot-password-description">
								{gettext('An email with instructions how to reset your password was sent to:')}
							</div>
							<div className="form-group row forgot-password-email">
								{email}
							</div>
						</div>
					</fieldset>
				</form>
			</div>
		);
	}
}

ForgotPasswordPageSuccess.propTypes = {
	email: PropTypes.string
};

const mapStateToProps = (state) => {
	return {
		email: state.account.passwordRequestedFor || ''
	};
};

export const ForgotPasswordSuccess = connect(mapStateToProps, {})(ForgotPasswordPageSuccess);