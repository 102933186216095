import {
	CHECK_IN_CURRENT_STATE,
	CHECK_IN_FAILED,
	CHECK_IN_FIELD_NAMES,
	CHECK_OUT_FAILED
} from '../constants/check-in-out';
import {MODAL_DEACTIVATED} from '../constants/modal';
import {assign, omit, reduce} from 'lodash';

const cleanCheckInState = () => {
	const fields = [].concat(CHECK_IN_FIELD_NAMES),
		checkInDefaults = reduce(fields, (state, field) => {
			state[field] = '';
			return state;
		}, {});
	checkInDefaults.crew_total = 1;
	return checkInDefaults;
};

const checkInOut = (state = {checkInState: cleanCheckInState()}, action) => {
	const updatedState = omit(assign({}, state), 'check_in_errors', 'check_out_errors', 'current', 'current_checkout');
	switch (action.type) {
		case CHECK_IN_FAILED:
			return assign(updatedState, {
				check_in_errors: action.payload.error
			});
		case CHECK_OUT_FAILED:
			return assign(updatedState, {
				check_out_errors: action.payload.error,
				current_checkout: action.payload.checkOutData
			});
		case CHECK_IN_CURRENT_STATE:
			return assign(updatedState, {
				checkInState: action.payload.data
			});
		case MODAL_DEACTIVATED:
			return assign(updatedState, {
				checkInState: cleanCheckInState()
			});
	}
	return updatedState;
};

export default checkInOut;