/* globals require */
const client = require('braintree-web/client'),
	hostedFields = require('braintree-web/hosted-fields');

import {getCustomerPaymentToken} from '../mah-api/index';

export const getHostedFieldsInstance = (fields) => {
	return getCustomerPaymentToken()
		.then(authorization => {
			return new Promise((resolve, reject) => {
				client.create({authorization}, (err, clientInstance) => {
					if (err) {
						reject(err);
						return;
					}
					resolve(clientInstance);
				});
			});
		})
		.then(client => {
			return new Promise((resolve, reject) => {
				hostedFields.create({
					client,
					fields
				}, (err, hostedFieldsInstance) => {
					if (err) {
						reject(err);
						return;
					}
					resolve(hostedFieldsInstance);
				});
			});
		});
};