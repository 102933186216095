const SUPPORTED_CURRENCIES = {
	'EUR': '€',
	'USD': '$'
};

export const getCurrency = (code) => {
	if (!code) {
		return code;
	}

	const upperCode = code.toUpperCase();
	return SUPPORTED_CURRENCIES[upperCode] || code;
};