import moment from 'moment';
import {SERVER_FORMAT} from '../formatter';

export const forVesselInHarbour = (vessel, harbour) => {
	return (booking) => booking.key_harbour === harbour.id && booking.key_user_vessel === vessel.id;
};

export const isCurrentlyActive = (booking) => {
	const {date_to} = booking,
		to = moment(date_to, SERVER_FORMAT),
		today = moment();

	return today.isSameOrBefore(to) && isNowOrFuture(booking);
};

export const isNowOrFuture = (booking) => {
	const {date_from} = booking,
		from = moment(date_from, SERVER_FORMAT),
		today = moment();

	return today.isSameOrAfter(from);
};