import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {first, isArray, isEmpty, isInteger, isNumber} from 'lodash';
import {gettext} from '../../../util/l10n';
import {isEmptyField} from '../../../util/field-util';

class SimpleInputField extends Component {
	constructor(props, context) {
		super(props, context);
		this.state = {
			passwordIsVisible: false,
			focused: false
		};
		this.pureValueHighlighter = this.pureValueHighlighter.bind(this);
		this.togglePasswordVisibility = this.togglePasswordVisibility.bind(this);
		this.handleChange = this.handleChange.bind(this);
		this.onBlur = this.onBlur.bind(this);
		this.onFocus = this.onFocus.bind(this);
		this.calculateFieldType = this.calculateFieldType.bind(this);
		this.displayErrors = this.displayErrors.bind(this);
		this.formatValue = this.formatValue.bind(this);
	}

	onBlur() {
		this.setState({focused: false});
	}

	onFocus() {
		this.setState({focused: true});
	}

	formatValue(value) {
		if (isNumber(value) && !isInteger(value)) {
			return value.toFixed(3);
		}
		return value;
	}

	handleChange(e) {
		this.props.handleChange(e.target.value);
	}

	togglePasswordVisibility() {
		this.setState({
			passwordIsVisible: !this.state.passwordIsVisible
		});
	}

	calculateFieldType() {
		if (!this.props.hasSecureValue || this.state.passwordIsVisible) {
			return this.props.inputFieldType || 'text';
		}
		return 'password';
	}

	pureValueHighlighter() {
		if (!this.props.hasSecureValue) {
			return '';
		}
		const passwordVisibility = this.state.passwordIsVisible ? 'md-active' : 'md-inactive',
			visibility = this.state.passwordIsVisible ? 'visibility_off' : 'visibility',
			visibilityClassName = `material-icons ${passwordVisibility} toggle-password-visibility`;
		return (
			<i onClick={this.togglePasswordVisibility}
			   className={visibilityClassName}>{visibility}</i>
		);
	}

	displayErrors() {
		const {errorWrapperClassNames, errorMessages} = this.props,
			errorMessage = isArray(errorMessages) ? first(errorMessages) : errorMessages,
			errorTextClassName = 'errors-text' + (errorWrapperClassNames ? ` ${errorWrapperClassNames}` : ''),
			errorIconClassName = 'errors-icon' + (errorWrapperClassNames ? ` ${errorWrapperClassNames}` : '');

		return (
			<div>
				<p className={errorTextClassName} data-tip={gettext(errorMessage)}>{gettext(errorMessage)}</p>
				<p className={errorIconClassName} data-tip={gettext(errorMessage)}>
					<i className="material-icons md-error md-18">report_problem</i>
				</p>
			</div>
		);
	}

	render() {
		const elementKey = 'input-field-' + this.props.name,
			hasError = !isEmpty(this.props.errorMessages) ? ' has-mah-error' : '',
			customClassNames = !isEmpty(this.props.classNames) ? this.props.classNames : '',
			isFocusedClassName = this.state.focused && !this.props.isReadOnly ? 'is-focused' : '',
			isEmptyClassName = isEmptyField(this.props.value) ? 'is-empty' : '',
			fieldsGroupClassName = `form-group ${hasError} ${customClassNames} ${isEmptyClassName} ${isFocusedClassName} label-floating`,
			isReadOnly = this.props.isReadOnly ? {readOnly: 'readOnly'} : {};

		let fieldValue = this.formatValue(this.props.value);

		return (
			<div className={fieldsGroupClassName}>
				<label htmlFor={elementKey} className="control-label">{gettext(this.props.fieldLabel)}</label>
				<input type={this.calculateFieldType()} value={fieldValue} className="form-control"
					   id={elementKey} onChange={this.handleChange} onFocus={this.onFocus} onBlur={this.onBlur}
					   {...isReadOnly}/>

				{this.pureValueHighlighter()}

				{this.displayErrors()}
			</div>
		);
	}
}

SimpleInputField.propTypes = {
	name: PropTypes.string.isRequired,
	fieldLabel: PropTypes.string.isRequired,
	value: PropTypes.any,
	handleChange: PropTypes.func.isRequired,
	hasSecureValue: PropTypes.bool,
	errorMessages: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
	classNames: PropTypes.string,
	errorWrapperClassNames: PropTypes.string,
	inputFieldType: PropTypes.string,
	isReadOnly: PropTypes.bool
};

export default SimpleInputField;