import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {gettext} from '../../../util/l10n';
import {connect} from 'react-redux';
import {push} from 'react-router-redux';

class NoVesselModal extends Component {
	constructor(props) {
		super(props);
		this.checkEscClicked = this.checkEscClicked.bind(this);
		this.onSubmit = this.onSubmit.bind(this);
		this.onReset = this.onReset.bind(this);
	}

	onSubmit(e) {
		e.preventDefault();
		const {push} = this.props;
		push('/vessel/add');
	}

	onReset(e) {
		e.preventDefault();
		const {hideModal} = this.props;
		hideModal();
	}

	checkEscClicked(e) {
		if (e.which === 27) {
			this.onReset(e);
		}
	}

	render() {
		return (
			<div className="no-vessel-warning-modal">
				<div className="no-vessel-warning-modal-header">
					<div>{gettext('No vessel selected')}</div>
				</div>
				<form className="no-vessel-warning-modal-body"
					  onSubmit={this.onSubmit}
					  onKeyUp={this.checkEscClicked}
					  onReset={this.onReset}>
					<div className="row">
						{gettext('In order to check-in, you need a vessel')}
					</div>
					<div className="row no-vessel-warning-action-buttons">
						<button type="reset" className="btn btn-wout-back available">{gettext('Cancel')}</button>
						<button type="submit" className="btn btn-wout-back available">{gettext('Add vessel')}</button>
					</div>
				</form>
			</div>
		);
	}
}

NoVesselModal.modalClassNames = 'small-modal';

NoVesselModal.propTypes = {
	hideModal: PropTypes.func,
	push: PropTypes.func
};


const mapStateToProps = () => {
		return {};
	},
	mapDispatchToProps = {
		push
	},
	NoVesselAvailableModal = connect(mapStateToProps, mapDispatchToProps)(NoVesselModal);

export {NoVesselAvailableModal};