import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {isEmpty} from 'lodash';
import {loadInvoice} from '../../../actions';
import {Loading} from '../../../components';
import base64 from '../../../util/base64';
import {push} from 'react-router-redux';
import {gettext} from '../../../util/l10n';

class InvoicePage extends Component {
	constructor(props) {
		super(props);
		this.loadInvoiceAttempt = this.loadInvoiceAttempt.bind(this);
		this.isLoadSucceed = this.isLoadSucceed.bind(this);
		this.isLoading = this.isLoading.bind(this);
	}

	isLoading() {
		const {invoiceDetails, loadError} = this.props;
		return isEmpty(invoiceDetails) && isEmpty(loadError);
	}

	isLoadSucceed() {
		const {invoiceDetails, loadError} = this.props;
		return !isEmpty(invoiceDetails) && isEmpty(loadError);
	}

	componentWillMount() {
		this.loadInvoiceAttempt();
	}

	componentDidUpdate() {
		const {loadError, push} = this.props,
			hasError = !isEmpty(loadError);
		if (hasError) {
			push('/');
		}
	}

	loadInvoiceAttempt() {
		const {params = {}, loadInvoice} = this.props,
			{invoiceHash} = params;

		if (!isEmpty(invoiceHash)) {
			loadInvoice(invoiceHash);
		}
	}

	invoiceData() {
		const {invoiceDetails} = this.props,
			html = base64.decode(invoiceDetails ? invoiceDetails.html : null);

		return (
			<div className="invoice-details-data">
				<div className="invoice-details-print">
					<img src="/img/icons/print.png"
						 srcSet="/img/icons/print@2x.png 2x"
						 title={gettext('Print invoice')}
						 onClick={() => window.print()}/>
				</div>
				<div dangerouslySetInnerHTML={{__html: html}}/>
			</div>
		);
	}

	render() {
		const isLoadSucceed = this.isLoadSucceed(),
			InvoiceDetails = isLoadSucceed ?
				this.invoiceData() :
				<div className="invoice-details-data"/>,
			loadingClassName = this.isLoading() ? ' loading' : '',
			loadedClassName = isLoadSucceed ? ' data-loaded' : '',
			invoiceDetailsClasses = `invoice-details ${loadedClassName} ${loadingClassName}`;

		return (
			<div className={invoiceDetailsClasses}>
				<Loading radiusMax={10} radiusMin={6} color="#254ea2" className="invoice-details-loading"/>
				{InvoiceDetails}
			</div>
		);
	}
}

InvoicePage.propTypes = {
	invoiceDetails: PropTypes.object,
	loadError: PropTypes.any,
	loadInvoice: PropTypes.func.isRequired
};

const mapStateToProps = (state) => {
	return {
		invoiceDetails: state.invoice.details || {},
		loadError: state.invoice.error || ''
	};
};

const mapDispatchToProps = {
	push,
	loadInvoice
};

export const InvoicePageContainer = connect(mapStateToProps, mapDispatchToProps)(InvoicePage);