'use strict';

import {find} from 'lodash';
import GeoPoint from './geo-point';

const EARTH_RADIUS = 6371e3,
	METERS_IN_NAUTICAL_MILE = 1852,
	toRad = (degrees) => degrees * Math.PI / 180,
	toDegrees = (radians) => radians * (180 / Math.PI);

export const findOuterBounds = (forBounds, coverBoundsList = []) => {
	if (coverBoundsList.length < 1) {
		return null;
	}
	return find(coverBoundsList, (coverBounds) => {
		return coverBounds.contains(forBounds);
	});
};

export const isPointInBounds = (point, bounds) => {
	const {ne, sw} = bounds,
		{lat, lng} = point;

	const lngInRange = ne.lng < sw.lng ? lng >= sw.lng || lng <= ne.lng : lng >= sw.lng && lng <= ne.lng,
		latInRange = lat >= sw.lat && lat <= ne.lat;

	return lngInRange && latInRange;
};

export const boundsToRadius = (bounds) => {
	const center = middlePoint(bounds.ne, bounds.sw),
		nw = new GeoPoint(bounds.ne.lat, bounds.sw.lng),
		se = new GeoPoint(bounds.sw.lat, bounds.ne.lng),
		radius = Math.max(
			distanceBetween(center, bounds.ne),
			distanceBetween(center, bounds.sw),
			distanceBetween(center, se),
			distanceBetween(center, nw)
		);
	return {center, radius};
};

export const middlePoint = (point1, point2) => {
	const dLon = toRad(point2.lng - point1.lng),
		Bx = Math.cos(toRad(point2.lat)) * Math.cos(dLon),
		By = Math.cos(toRad(point2.lat)) * Math.sin(dLon),
		centerLat = toDegrees(Math.atan2(
			Math.sin(toRad(point1.lat)) + Math.sin(toRad(point2.lat)),
			Math.sqrt(
				(Math.cos(toRad(point1.lat)) + Bx) *
				(Math.cos(toRad(point1.lat)) + Bx) + By * By))),
		centerLng = point1.lng + toDegrees(Math.atan2(By, Math.cos(toRad(point1.lat)) + Bx));

	return new GeoPoint(centerLat, centerLng);
};

export const distanceBetween = (point1, point2) => {
	const p1latInRad = toRad(point1.lat),
		p2latInRad = toRad(point2.lat),
		deltaLatInRad = toRad(point2.lat - point1.lat),
		deltaLngInRad = toRad(point2.lng - point1.lng),
		a = Math.sin(deltaLatInRad / 2) * Math.sin(deltaLatInRad / 2) +
			Math.cos(p1latInRad) * Math.cos(p2latInRad) *
			Math.sin(deltaLngInRad / 2) * Math.sin(deltaLngInRad / 2),
		c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
	return EARTH_RADIUS * c;
};

export const distanceInNauticalMiles = (point1, point2) => {
	return (distanceBetween(point1, point2) / METERS_IN_NAUTICAL_MILE).toFixed(1);
};

export const bearingBetween = (point1, point2) => {
	let lat1 = toRad(point1.lat),
		lat2 = toRad(point2.lat),
		deltaLon = toRad(point2.lng - point1.lng),
		x = Math.sin(deltaLon) * Math.cos(lat2),
		y = Math.cos(lat1) * Math.sin(lat2) - (Math.sin(lat1) * Math.cos(lat2) * Math.cos(deltaLon));

	return (toDegrees(Math.atan2(x, y)) + 360) % 360;
};