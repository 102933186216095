import React, {Component} from 'react';
import PropTypes from 'prop-types';
import DayPicker, {DateUtils} from 'react-day-picker';
import {gettext} from '../../../util/l10n';
import moment from 'moment';

const WEEKDAYS_SHORT = ['S', 'M', 'T', 'W', 'T', 'F', 'S'];

class DatePickerModal extends Component {
	constructor(props) {
		super(props);
		const {from = new Date(), to = new Date()} = props;
		this.state = {
			from,
			to
		};
		this.checkEscClicked = this.checkEscClicked.bind(this);
		this.handleSuccess = this.handleSuccess.bind(this);
		this.handleCancel = this.handleCancel.bind(this);
		this.handleDayClick = this.handleDayClick.bind(this);
		this.isDaySelected = this.isDaySelected.bind(this);
		this.dayModifiers = this.dayModifiers.bind(this);
	}

	componentWillReceiveProps(nextProps) {
		const {from = new Date(), to = new Date()} = nextProps;

		this.setState({from, to});
	}

	dayModifiers() {
		return {
			'first-selected-day': (day) => {
				const {from} = this.state;

				return DateUtils.isSameDay(day, from);
			},
			'last-selected-day': (day) => {
				const {to} = this.state;

				return DateUtils.isSameDay(day, to);
			}
		};
	}

	handleDayClick(day) {
		if (moment(day).isBefore(moment())) {
			return;
		}
		this.setState({to: day});
	}

	isDaySelected(day) {
		const {from, to} = this.state;

		return DateUtils.isDayInRange(day, {from, to});
	}

	handleSuccess(e) {
		e.preventDefault();
		const {onSuccess} = this.props,
			{to} = this.state;
		onSuccess(to);
	}

	handleCancel(e) {
		e.preventDefault();
		const {onCancel} = this.props;
		onCancel();
	}

	checkEscClicked(e) {
		if (e.which === 27) {
			this.handleCancel(e);
		}
	}

	render() {
		const {header} = this.props;
		return (
			<div className="generic-modal-window">
				<div className="generic-modal-window-header-container">
					<div className="generic-modal-window-header-content">
						{header}
					</div>
				</div>
				<DayPicker
					onDayClick={this.handleDayClick}
					disabledDays={DateUtils.isPastDay}
					weekdaysShort={WEEKDAYS_SHORT}
					firstDayOfWeek={1}
					enableOutsideDays
					modifiers={this.dayModifiers()}
					selectedDays={this.isDaySelected}
				/>
				<form className="generic-modal-window-body container" onKeyUp={this.checkEscClicked}
					  onSubmit={this.handleSuccess}>
					<div className="row generic-modal-window-buttons">
						<button className="btn btn-wout-back available" onClick={this.handleCancel}>
							{gettext('Cancel')}
						</button>
						<button type="submit" className="btn btn-wout-back available">{gettext('Ok')}</button>
					</div>
				</form>
			</div>
		);
	}
}

DatePickerModal.propTypes = {
	header: PropTypes.string.isRequired,
	from: PropTypes.object,
	to: PropTypes.object,
	onCancel: PropTypes.func.isRequired,
	onSuccess: PropTypes.func.isRequired
};

export {DatePickerModal};