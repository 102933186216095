import {createStore} from 'redux';
import reducers from '../reducers';
import middleware from '../middleware';

const configureStore = (initialState) => {
	return createStore(
		reducers,
		initialState,
		middleware
	);
};

export default configureStore;