import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {find, has, partial} from 'lodash';
import {push} from 'react-router-redux';
import {connect} from 'react-redux';
import {gettext} from '../../../util/l10n';
import {addNewVessel, changeActiveVessel, removeVessel} from '../../../actions';
import {itemsList, VesselItem} from '../../../components';

class VesselsListPage extends Component {
	constructor(props) {
		super(props);

		this.getVesselType = this.getVesselType.bind(this);
		this.removeVessel = this.removeVessel.bind(this);
		this.setActiveVessel = this.setActiveVessel.bind(this);
		this.setDeleteResults = this.setDeleteResults.bind(this);
	}

	getVesselType(type) {
		const vesselType = find(this.props.vessel_type, {code: type});

		return vesselType ? vesselType.value : type;
	}

	setActiveVessel(vesselId) {
		const {push, changeActiveVessel} = this.props;

		changeActiveVessel(vesselId);
		push('/');
	}

	setDeleteResults(vesselId, result) {
		const {hideModal, removeVessel} = this.props;
		hideModal();
		if (result === 'confirm') {
			removeVessel(vesselId);
		}
	}

	removeVessel({id, name}) {
		const {showModal} = this.props;
		showModal('deleteConfirmation', {
			setDeleteResults: partial(this.setDeleteResults, id),
			Header: () => <div>Delete {name}?</div>,
			confirmText: gettext('Delete'),
			cancelText: gettext('Cancel')
		});
	}

	render() {
		const {vessels, classNames} = this.props,
			listClasses = `${classNames}`;

		return (
			<ul className={listClasses}>
				{vessels.map(vessel => {
					const vesselKeyId = `vessel-${vessel.id}`;
					return (
						<VesselItem key={vesselKeyId}
									setActiveVessel={this.setActiveVessel}
									getVesselType={this.getVesselType}
									removeVessel={this.removeVessel}
									{...vessel}/>
					);
				})}
			</ul>
		);
	}
}

VesselsListPage.propTypes = {
	classNames: PropTypes.string,
	removeVessel: PropTypes.func.isRequired,
	vessels: PropTypes.array,
	vessel_type: PropTypes.array,
	push: PropTypes.func.isRequired,
	changeActiveVessel: PropTypes.func.isRequired,
	showModal: PropTypes.func.isRequired,
	hideModal: PropTypes.func.isRequired
};

const mapStateToProps = (state) => {
	return {
		vessels: state.vessels.list || [],
		vessel_type: has(state, 'classifier.vessel_type') ? state.classifier.vessel_type : []
	};
};

const mapDispatchToProps = {
	removeVessel,
	push,
	changeActiveVessel
};

const itemsListProps = {
	itemsListTitle: gettext('Vessels'),
	addNewListItem: {
		type: 'action',
		action: addNewVessel
	}
};

const ConnectedItemsList = connect(mapStateToProps, mapDispatchToProps)(VesselsListPage);

export const VesselsList = itemsList(ConnectedItemsList, itemsListProps);