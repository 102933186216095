import {doJsonpGet} from '../util/fetch';
import {assignIn} from 'lodash';
import qs from 'querystring';
import config from '../config';

const defaultParams = {
		addressdetails: 1,
		zoom: 1,
		format: 'json'
	},
	findCountryByLocation = (geoPoint) => {
		const {key, url} = config.LOCATIONIQ,
			params = assignIn({}, defaultParams, {key, lat: geoPoint.lat, lon: geoPoint.lng}),
			options = {
				jsonpCallback: 'json_callback'
			};
		return doJsonpGet(url + '?' + qs.stringify(params), options).then(data => data.address);
	};

export {findCountryByLocation};