import {INVOICE_LOAD_FAILED, INVOICE_LOAD_SUCCEED} from '../constants/invoice';
import {loadInvoiceDetails} from '../mah-api/index';

const invoiceLoadFailed = (response) => {
	return {
		type: INVOICE_LOAD_FAILED,
		payload: {
			error: response.error
		}
	};
};

const invoiceLoadSucceed = (invoice) => {
	return {
		type: INVOICE_LOAD_SUCCEED,
		payload: {
			invoice
		}
	};
};

export const loadInvoice = (invoiceHash) => {
	return (dispatch) => {
		loadInvoiceDetails(invoiceHash)
			.then(invoice => dispatch(invoiceLoadSucceed(invoice)))
			.catch(e => dispatch(invoiceLoadFailed(e.response)));
	};
};