import {AUTO_COMPLETE_LOADED} from '../constants/autocomplete';
import {LOCATION_CHANGE} from 'react-router-redux';
import {assign} from 'lodash';

const autocomplete = (state = {}, action) => {
	switch (action.type) {
		case AUTO_COMPLETE_LOADED:
			return assign({}, state, {[action.payload.type]: action.payload.valuesList});
		case LOCATION_CHANGE:
			return {};
	}
	return state;
};

export default autocomplete;