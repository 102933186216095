import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {gettext} from '../../../util/l10n';

class AddVessel extends Component {
	constructor(props, context) {
		super(props, context);
	}

	render() {
		const {handleAddVessel} = this.props;
		return (
			<div className="btn-group btn-group-justified vessel-menu-group">
				<button onClick={handleAddVessel} type="button"
						className="btn btn-default vessel-menu-button">
					<span className="vessel-dropdown-current-value">{gettext('Add vessel')}</span>
					<i className="material-icons md-24 md-white">add_box</i>
				</button>
			</div>
		);
	}
}

AddVessel.propTypes = {
	handleAddVessel: PropTypes.func.isRequired
};

export default AddVessel;