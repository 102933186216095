import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';

class OAuthLoginPage extends Component {
	constructor(props) {
		super(props);
		this.oAuthLoginAttempt = this.oAuthLoginAttempt.bind(this);
	}

	oAuthLoginAttempt() {
		const {opener = {}} = window,
			{oAuthResultHandler} = opener,
			{oAuthLoginResults} = this.props;

		if (!oAuthResultHandler) {
			return window.close();
		}

		oAuthResultHandler(oAuthLoginResults);
	}

	componentDidMount() {
		this.oAuthLoginAttempt();
	}

	render() {
		return (
			<div/>
		);
	}
}

OAuthLoginPage.propTypes = {
	oAuthLoginResults: PropTypes.object
};

const mapStateToProps = (state, ownProps) => {
	return {
		oAuthLoginResults: ownProps.location.query
	};
};

export const OAuthLoginPageContainer = connect(mapStateToProps)(OAuthLoginPage);