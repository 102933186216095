import {REGISTRATION_FAILED, REGISTRATION_IN_PROGRESS, REGISTRATION_SUCCESS} from '../constants/user-details';
import {registerUser as registrationRequest} from '../mah-api';
import {push} from 'react-router-redux';

const registrationInProgress = () => {
	return {
		type: REGISTRATION_IN_PROGRESS,
		payload: {}
	};
};

const registrationSuccess = (user) => {
	return {
		type: REGISTRATION_SUCCESS,
		payload: {user}
	};
};

const registrationFailed = (errorDetails) => {
	return {
		type: REGISTRATION_FAILED,
		payload: errorDetails.validation_errors
	};
};

export const registerUser = (regInfo) => {
	return dispatch => {
		dispatch(registrationInProgress());
		return registrationRequest(regInfo)
			.then(user => {
				dispatch(registrationSuccess(user));
				dispatch(push('/register_ok'));
			})
			.catch(error => dispatch(registrationFailed(error.response)));
	};
};