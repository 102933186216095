import {CLASSIFIER_LOADED} from '../constants/classifier';
import {assign} from 'lodash';

const classifier = (state = {}, action) => {
	switch (action.type) {
		case CLASSIFIER_LOADED:
			return assign({}, state, action.payload);
	}
	return state;
};

export default classifier;