import {assign, assignIn, cloneDeep, omit} from 'lodash';
import {
	CREDIT_CARD_ADD_FAILED,
	CREDIT_CARD_CLEAN_ERROR,
	CREDIT_CARD_REMOVED,
	CREDIT_CARDS_LOADED,
	PAYMENT_TOKEN_FETCHED,
	STORE_CURRENT_CARD
} from '../constants/credit-cards';
import {MODAL_DEACTIVATED} from '../constants/modal';

const creditCards = (state = {list: null, token: null, saveErrors: null}, action) => {
	switch (action.type) {
		case CREDIT_CARD_REMOVED: {
			const {creditCardToken} = action.payload,
				existingList = state.list || [],
				list = existingList.reduce((list, current) => {
					if (current.token !== creditCardToken) {
						list.push(cloneDeep(current));
					}
					return list;
				}, []);

			return assignIn({list}, omit(state, 'list'));
		}
		case CREDIT_CARDS_LOADED: {
			const {creditCards} = action.payload;

			return assignIn({list: creditCards}, omit(state, 'list'));
		}
		case PAYMENT_TOKEN_FETCHED: {
			const {token} = action.payload;

			return assignIn({token}, omit(state, 'token'));
		}
		case CREDIT_CARD_ADD_FAILED: {
			const {saveErrors} = action.payload;

			return assignIn({}, state, {saveErrors});
		}
		case STORE_CURRENT_CARD:
			return assign({}, omit(state, 'saveErrors'), {currentCard: action.payload.card});
		case CREDIT_CARD_CLEAN_ERROR:
			return assignIn({}, omit(state, 'saveErrors'));
		case MODAL_DEACTIVATED:
			return assign({}, omit(state, 'saveErrors', 'currentCard'));
	}
	return state;
};

export default creditCards;