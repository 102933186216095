import {MAP_CENTER_CHANGED, POSITION_OBTAINED, ZOOM_CHANGED} from '../constants/maps';
import {fetchHarbours} from './harbours';
import GeoBounds from '../util/geo/geo-bounds';
import {isEmpty, noop} from 'lodash';

export const positionObtained = (position) => {
	return {
		type: POSITION_OBTAINED,
		payload: {
			position
		}
	};
};

export const boundsChanged = (bounds) => {
	return (dispatch) => {
		dispatch(fetchHarbours(GeoBounds.fromGoogleLatLngBounds(bounds)));
	};
};

export const zoomChanged = (zoom) => {
	return {
		type: ZOOM_CHANGED,
		payload: {
			zoom
		}
	};
};

export const mapCenterChanged = (center) => {
	const position = {coords: {latitude: center.lat(), longitude: center.lng()}};
	return {
		type: MAP_CENTER_CHANGED,
		payload: {
			position
		}
	};
};

export const obtainCurrentPosition = () => {
	return (dispatch, getState) => {
		const {maps} = getState();
		if (!isEmpty(maps.obtainedCoordinates)) {
			return;
		}
		navigator.geolocation.getCurrentPosition((position) => {
			dispatch(positionObtained(position));
		}, error => noop(error));
	};
};