import {doDelete, doGet, doPost, doPut, getAllPages} from '../util/fetch';
import {assignIn, find, isArray, omit, reduce} from 'lodash';
import config from '../config';

const extractClassifier = (data) => find(omit(data, ['debug', 'env']), isArray);

export const loginAttempt = (username, password) => {
	return doPost(config.API_URL + '/auth/login', {username, password}).then(data => data.user);
};

export const logoutUser = () => doDelete(config.API_URL + '/auth/logout');

export const fetchUserSelf = () => doGet(config.API_URL + '/auth/self').then(data => data.user);

export const updateUserDetails = (userDetails) => {
	return doPut(config.API_URL + '/user/profile', userDetails).then(data => data.user);
};

export const updateUserPassword = (passwordData) => {
	// eslint-disable-next-line no-unused-vars
	return doPut(config.API_URL + '/user/password', passwordData).then(data => {
	});
};

export const registerUser = (regInfo) => {
	const registrationInformation = {user: {}};
	assignIn(registrationInformation.user, regInfo);
	return doPost(config.API_URL + '/auth/register', registrationInformation).then(data => data.user);
};

export const passwordRecoveryAttempt = (email) => {
	const userInfo = {
		username: email,
		consumer: 'sailor'
	};

	return doPost(config.API_URL + '/auth/password/restore/request', userInfo).then(data => data.status_message);
};

export const passwordRestoreValidate = ({restore_key}) => {
	const recoveryData = {
		restore_key,
		consumer: 'sailor'
	};
	return doPost(config.API_URL + '/auth/password/restore/validate', recoveryData).then(data => data.status_message);
};

export const passwordReset = ({restore_key, new_password, confirm_password}) => {
	const resetData = {
		restore_key,
		new_password,
		confirm_password,
		consumer: 'sailor'
	};
	return doPut(config.API_URL + '/auth/password/restore/reset', resetData).then(data => data.status_message);
};

export const searchDefaultHarbours = () => {
	const includeTest = config.env !== 'live' ? '&include_test=1' : '',
		fetchUrl = `/harbour/search?perPage=100${includeTest}`;
	return getAllPages(config.API_URL + fetchUrl)
		.then(results => reduce(results, (harbours, response) => harbours.concat(response.harbours), []));
};

export const searchHarbours = (lat, lng, radius) => {
	const includeTest = config.env !== 'live' ? '&include_test=1' : '',
		fetchUrl = `/harbour/search?radius=${radius}&lat=${lat}&lon=${lng}&perPage=100${includeTest}`;
	return getAllPages(config.API_URL + fetchUrl)
		.then(results => reduce(results, (harbours, response) => harbours.concat(response.harbours), []));
};

export const searchHarboursByFirstLetters = (letters) => {
	const includeTest = config.env !== 'live' ? '&include_test=1' : '',
		fetchUrl = `/harbour/search?op=a&q=${letters}&perPage=10${includeTest}`;
	return getAllPages(config.API_URL + fetchUrl)
		.then(results => reduce(results, (harbours, response) => harbours.concat(response.harbours), []));
};

export const fetchHarbourDetails = (harbourId) => doGet(config.API_URL + `/harbour/${harbourId}`)
	.then(data => data.harbour);

export const fetchHarbourServices = (harbourId) => doGet(config.API_URL + `/harbour/${harbourId}/service`)
	.then(data => data.harbour_servicies);

export const fetchHarbourBerths = (harbourId) => doGet(config.API_URL + `/harbour/${harbourId}/berths/free`)
	.then(data => data.harbour);

export const fetchClassifier = (classifierName) =>
	doGet(config.API_URL + `/classifier/${classifierName}`).then(data => extractClassifier(data));

export const fetchUserVessels = () => doGet(config.API_URL + '/user/vessel').then(data => data.user_vessels);

export const createVessel = (vesselDetails) => {
	return doPost(config.API_URL + '/user/vessel/', vesselDetails).then(data => data.user_vessel);
};

export const updateVessel = (vesselDetails) => {
	const vesselId = vesselDetails.id;
	return doPut(config.API_URL + `/user/vessel/${vesselId}/`, omit(vesselDetails, ['id']))
		.then(data => data.user_vessel);
};

export const removeVessel = (vesselId) => doDelete(config.API_URL + `/user/vessel/${vesselId}`);

export const fetchVesselDetails = (vesselId) => doGet(config.API_URL + `/user/vessel/${vesselId}`)
	.then(data => data.user_vessel);

export const fetchVesselCheckIns = (vesselId) => doGet(config.API_URL + `/user/booking/check-in/list/vessel/${vesselId}`)
	.then(data => data.check_in_info_list);

export const checkInVessel = (checkInData) => {
	return doPost(config.API_URL + '/user/booking/check-in', {checkin: checkInData})
		.then(data => data.harbour_journal);
};

export const checkOutVessel = (checkOutData) => {
	return doPost(config.API_URL + '/user/booking/check-out', {checkout: checkOutData})
		.then(data => data.harbour_journal);
};

export const activateUser = (activation_token) => {
	// eslint-disable-next-line no-unused-vars
	return doPut(config.API_URL + '/auth/activate', {activation_token}).then(data => {
	});
};

export const resendActivation = (activationData) => {
	// eslint-disable-next-line no-unused-vars
	return doPost(config.API_URL + '/auth/activate/resend', activationData).then(data => {
	});
};

export const calculateOrder = (bookingData) => {
	return doPost(config.API_URL + '/user/booking/reserve', bookingData).then(data => data.booking_price);
};

export const calculateBooking = (bookingData) => {
	return doPost(config.API_URL + '/user/booking/calculate', bookingData).then(data => data.booking_price);
};

export const submitPayment = (paymentDetails) => {
	return doPost(config.API_URL + '/payment/order/submit', paymentDetails).then(data => data.harbour_journal);
};

export const getCountryByCode = (code) => {
	return doGet(config.API_URL + `/classifier/country/${code}`).then(data => data.country);
};

const wrapWithCustomerExistCheck = (requestMethod, url, ...other) => {
	return requestMethod(url, ...other)
		.catch(error => {
			if (error.status === 404) {
				return doPost(config.API_URL + '/payment/customer')
					.then(() => {
						return requestMethod(url, ...other);
					});
			}
			throw error;
		});
};

export const removeCreditCard = (creditCardToken) => wrapWithCustomerExistCheck(doDelete,
	config.API_URL + `/payment/customer/card/${creditCardToken}`);

export const loadUserCreditCards = () => wrapWithCustomerExistCheck(doGet,
	config.API_URL + '/payment/customer/cards').then(data => data.credit_card_details);

export const getCustomerPaymentToken = () => wrapWithCustomerExistCheck(doPost,
	config.API_URL + '/payment/token')
	.then(data => data.key);

export const storeCreditCard = (nonce) => wrapWithCustomerExistCheck(doPost,
	config.API_URL + '/payment/customer/card', {nonce})
	.then(data => data.credit_card_details);

export const socialLoginAttempt = (provider, loginData) => {
	// eslint-disable-next-line no-unused-vars
	return doPost(config.API_URL + `/auth/login/social/${provider}`, loginData).then(data => {
	});
};

export const loadActiveBookings = () => doGet(config.API_URL + '/user/booking/actual').then(data => data.bookings);

export const loadInvoiceDetails = (invoiceHash) => {
	return doGet(config.API_URL + `/invoice/html/${invoiceHash}`).then(data => data.invoice);
};