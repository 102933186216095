import React, {Component} from 'react';
import {connect} from 'react-redux';
import LoginForm from '../components/login/user-login-form';
import {login} from '../actions';
import {defer} from 'lodash';

class LoginPage extends Component {
	constructor(props) {
		super(props);
		this.onLoginAttempt = this.onLoginAttempt.bind(this);
	}

	onLoginAttempt(username, password) {
		const {dispatch, nextPath} = this.props;
		dispatch(login(username, password, () => {
			defer(() => {
				document.location = nextPath;
			});
		}));
	}

	render() {
		return (
			<LoginForm onLoginAttempt={this.onLoginAttempt}/>
		);
	}
}

const mapStateToProps = (state) => {
	return {
		nextPath: state.redirects.pathname || '/'
	};
};

const LoginPageContainer = connect(mapStateToProps)(LoginPage);
export {LoginPageContainer};