import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
const LONG_FORMAT = 'DD.MM.YYYY';
const SHORT_FORMAT = 'DD.MM';
const format = (date) => {
	const momentDate = moment(date),
		today = moment(),
		isSameYear = momentDate.isSame(today, 'year'),
		useFormat = isSameYear ? SHORT_FORMAT : LONG_FORMAT;

	return momentDate.format(useFormat);
};

export const BookingTime = (props) => {
	const {text, date} = props;

	return (
		<div className="booking-time">
			<div className="booking-time-title">
				{text}
			</div>
			<div className="booking-time-date">
				{format(date)}
			</div>
		</div>
	);
};

BookingTime.props = {
	text: PropTypes.string,
	date: PropTypes.oneOf([PropTypes.string, PropTypes.object])
};