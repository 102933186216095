export const LOGIN_ATTEMPT = '@@mah-user/LOGIN_ATTEMPT';
export const LOGIN_ATTEMPT_SUCCESS = '@@mah-user/LOGIN_ATTEMPT_SUCCESS';
export const LOGIN_ATTEMPT_FAILURE = '@@mah-user/LOGIN_ATTEMPT_FAILURE';
export const USER_LOGGED_OUT = '@@mah-user/USER_LOGGED_OUT';
export const REGISTRATION_IN_PROGRESS = '@@mah-user/REGISTRATION_IN_PROGRESS';
export const REGISTRATION_FAILED = '@@mah-user/REGISTRATION_FAILED';
export const REGISTRATION_SUCCESS = '@@mah-user/REGISTRATION_SUCCESS';
export const USER_UPDATE_FAILED = '@@mah-user/USER_UPDATE_FAILED';
export const PASSWORD_RECOVERY_REQUESTED = '@@mah-user/PASSWORD_RECOVERY_REQUESTED';
export const PASSWORD_RECOVERY_FAILED = '@@mah-user/PASSWORD_RECOVERY_FAILED';
export const PASSWORD_RECOVERY_VALIDATED = '@@mah-user/PASSWORD_RECOVERY_VALIDATED';
export const PASSWORD_RECOVERY_ERRORS = '@@mah-user/PASSWORD_RECOVERY_ERRORS';
export const PASSWORD_RECOVERY_COMPLETED = '@@mah-user/PASSWORD_RECOVERY_COMPLETED';
export const PASSWORD_UPDATE_FAILED = '@@mah-user/PASSWORD_UPDATE_FAILED';
export const PASSWORD_UPDATE_SUCCESS = '@@mah-user/PASSWORD_UPDATE_SUCCESS';
