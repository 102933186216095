import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {getCreditCardIcon} from '../../../components/credit-card-icon/credit-card-icon';
import {isFunction} from 'lodash';

class CreditCardInfo extends Component {
	constructor(props) {
		super(props);
		this.handleSelectCardClick = this.handleSelectCardClick.bind(this);
	}

	handleSelectCardClick(e, card) {
		e.preventDefault();
		const {cardSelected} = this.props;
		if (isFunction(cardSelected)) {
			cardSelected(card);
		}
	}

	render() {
		const {card, outerTag} = this.props,
			{last4, imageUrl, details} = card,
			cardNumber = last4 ? `**** ${last4}` : `**** **${details.lastTwo}`,
			creditCardIcon = imageUrl ?
				getCreditCardIcon(imageUrl, 'credit-card-icon') :
				<div className="credit-card-icon">{details.cardType}</div>,
			OuterTag = outerTag ? `${outerTag}` : 'li';

		return (
			<OuterTag className="credit-card-item">
				<a href="#" onClick={(e) => this.handleSelectCardClick(e, card)}>
					<div className="credit-card-number">
						{cardNumber}
					</div>
					{creditCardIcon}
				</a>
			</OuterTag>
		);
	}
}

CreditCardInfo.propTypes = {
	card: PropTypes.object,
	cardSelected: PropTypes.func,
	outerTag: PropTypes.string
};

export {CreditCardInfo};