import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {Link} from 'react-router';
import {connect} from 'react-redux';
import {goBack} from 'react-router-redux';
import {gettext} from '../../../util/l10n';

class PrivacyPage extends Component {
	constructor(props) {
		super(props);
		this.goBack = this.goBack.bind(this);
	}

	goBack(e) {
		this.props.goBack();
		e.preventDefault();
	}

	render() {
		return (
			<div className="static-content">
				<div className="static-content-header">
					<Link onClick={this.goBack} to="#">
						<button className="menu-button">
							<i className="material-icons md-white md-24">arrow_back</i>
						</button>
					</Link>
					<div className="static-content-title">
						{gettext('Privacy Policy')}
					</div>
				</div>
				<div className="static-content-details">
					<h4>Privacy Policy</h4>
					<p>The users of the Marina Ahoy service grant their consent for the processing of personal data
						during the installation of the Marina Ahoy app by marking the checkbox &quot;I agree with the
						Terms
						and Conditions and Privacy Policy&quot;.</p>
					<p>Marina Ahoy Ltd. only collects and processes the personal data submitted by the users in the
						course of installation and use of the Marina Ahoy app. A app user disclosure of his or her
						geographical location data is a precondition for using the Marina Ahoy service.</p>
					<p>We explain below how Marina Ahoy collects and uses personal data with the Marina Ahoy app.</p>
					<ol>
						<li className="list-major">
							<span>What personal data we collect and process?</span>
							<ul>
								<li>Name, telephone number, e-mail address and payment information of the app user;</li>
								<li>Geographical location of the app user, the time and place using marina Ahoy app;</li>
								<li>Identification data of the device on which the Marina Ahoy app has been installed;</li>
								<li>Name, telephone number, e-mail address of the marina managers signed up for marina management service.</li>
								<li>Cookies of the marinaahoy.com website save the website user&apos;s geographic location data.</li>
							</ul>
						</li>
						<li className="list-major">
							<span>The purpose of collecting personal data</span>
							<ul>
								<li>We collect and process personal data to allow users book, chek-in, using marina services and pay mobile by using the Marina Ahoy app;</li>
								<li>The data of geographic location is used to filter nearest marina service providers while travelling and enabling one click check-in;</li>
								<li>We forward the users name, telephone number, e-mail address along with the data of the vessel to the marina service provider for offering services ordered through the Marina Ahoy app;</li>
								<li>We use the personal data of the app user to resolve any service quality issues between Marina Ahoy and the marina service provider;</li>
								<li>We use contact details to notify customers of updates to the Marina Ahoy service;</li>
								<li>We collect geographic location data of the Marina Ahoy app users to improve the network of marinas connected with the Marina Ahoy platform.</li>
							</ul>
						</li>
						<li className="list-major">
							<span>The method of collecting personal data</span>
							<ul>
								<li>We collect data on the names, telephone numbers and e-mail addresses of Marina Ahoy users during the process of the Marina Ahoy app installation;</li>
								<li>We only collect data on the geographic location if the Marina Ahoy app has been activated;</li>
								<li>The collection of geographic location data stops after the Marina Ahoy app is closed in the user&apos;s device.</li>
							</ul>
						</li>
						<li className="list-major">
							<span>Disclosure of personal data to third parties</span>
							<ul>
								<li>The personal data of Marina Ahoy app users is only disclosed to those marina service providers who have activated the Marina Ahoy marina management service; in this case, they will see the name, telephone number along with the vessel data of the user who reserved a berth;</li>
								<li>The feedback on the quality of the marina service is anonymous and marina service providers do not receive information on the names and telephone numbers of the persons who provided feedback;</li>
								<li>We will not disclose the personal data or geographic locations of Marina Ahoy users to any third parties or public authorities, except if the person requesting data has a legal basis for doing so pursuant to the legislation of the country of location.</li>
							</ul>
						</li>
						<li className="list-major">
							<span>Security and deletion of data</span>
							<ul>
								<li>Any personal data collected in the course of providing the Marina Ahoy service is stored in the data centers located on the territory of a EU Member State. Only authorised employees of Marina Ahoy Ltd. have access to the personal data and they may access the data only for the purpose of resolving issues associated with the use of the Marina Ahoy service;</li>
								<li>If a Marina Ahoy user would like to delete the personal data collected via the app, he or she can do so by submitting a written request to our customer support via <a target="_blank" href="mailto:ahoy@marinaahoy.com">ahoy@marinaahoy.com</a>.</li>
							</ul>
						</li>
						<li className="list-major">
							<span>Direct marketing</span>
							<ul>
								<li>We only use your e-mail address for sharing our service related marketing messages.If you no longer wish to receive direct marketing messages, please click the &quot;Unsubscribe&quot; link in the footer of our e-mail.</li>
							</ul>
						</li>
						<li className="list-major">
							<span>Personal data of marina managers</span>
							<ul>
								<li>Marina Ahoy app users see the contact information of a marina manager linked to a certain marina. Name, e-mail address and telephone number of the marina manager are listed among the marina details.</li>
								<li>Marina Ahoy Ltd. contact marina managers only for user related issue solving, support and updates notifications.</li>
							</ul>
						</li>
					</ol>
				</div>
			</div>
		);
	}
}

PrivacyPage.propTypes = {
	goBack: PropTypes.func.isRequired
};

const mapStateToProps = () => {
	return {};
};

const mapDispatchToProps = {
	goBack
};

export const Privacy = connect(mapStateToProps, mapDispatchToProps)(PrivacyPage);