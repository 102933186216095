/* globals require */
import {applyMiddleware} from 'redux';
import thunkMiddleware from 'redux-thunk';
import {browserHistory} from 'react-router';
import {routerMiddleware} from 'react-router-redux';
import config from '../config';

let middleware = [routerMiddleware(browserHistory), thunkMiddleware];
if (config.env === 'dev') {
	middleware = [...middleware, require('redux-logger').createLogger()];
}

export default applyMiddleware(...middleware);