import React from 'react';
import SimpleInputField from './simple-input';
import SingleCheckboxField from './single-checkbox';
import DateTimePickerField from './date-time-picker';
import SelectInputField from './select-input';
import AutoCompleteInputField from './autocomplete-input';
import {isFunction} from 'lodash';

const fieldByType = (fieldType, props, keyPrefix = '') => {
	const fieldKey = `${keyPrefix}-${fieldType}-${props.key}`,
		{showModal, hideModal} = props;
	switch (fieldType) {
		case 'text':
		case 'number':
			return <SimpleInputField key={fieldKey} inputFieldType={fieldType} {...props}/>;
		case 'checkbox':
			return <SingleCheckboxField key={fieldKey} {...props}/>;
		case 'password':
			return <SimpleInputField key={fieldKey} hasSecureValue={true} {...props}/>;
		case 'drop-down':
			return <SelectInputField key={fieldKey} {...props}/>;
		case 'auto-fill':
			return <AutoCompleteInputField key={fieldKey} {...props}/>;
		case 'datetime':
		case 'date':
		case 'datetime-local':
			if (isFunction(showModal) && isFunction(hideModal)) {
				return <DateTimePickerField key={fieldKey} inputFieldType={fieldType} {...props}/>;
			}
			return <SimpleInputField key={fieldKey} inputFieldType={fieldType} {...props}/>;
		default:
			return <SimpleInputField key={fieldKey} {...props}/>;
	}
};

export default fieldByType;