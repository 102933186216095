import {TOGGLE_MENU} from '../constants/iteractions';
import {LOCATION_CHANGE} from 'react-router-redux';

const menu = (state = {hasMenu: false}, action) => {
	switch (action.type) {
		case TOGGLE_MENU:
			return {hasMenu: !state.hasMenu};
		case LOCATION_CHANGE:
			return {hasMenu: false};
	}
	return state;
};

export default menu;