import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {isEmpty} from 'lodash';
import {gettext} from '../../util/l10n';
import SimpleInputField from '../core/fields/simple-input';

const loginFields = [
	{name: 'email', fieldLabel: 'email address'},
	{name: 'password', fieldLabel: 'password', hasSecureValue: true}
];

class UserLoginForm extends Component {
	constructor(props, context) {
		super(props, context);
		this.state = {
			email: this.props.email,
			password: ''
		};
		this.handleFieldChange = this.handleFieldChange.bind(this);
		this.onSubmit = this.onSubmit.bind(this);
	}

	handleFieldChange(propertyName) {
		return (value) => {
			return this.setState({[propertyName]: value});
		};
	}

	onSubmit(e) {
		e.preventDefault();
		const {email, password} = this.state;
		this.props.onLoginAttempt(email, password);
	}

	render() {
		const signInButtonIsActive = !isEmpty(this.state.email) && !isEmpty(this.state.password) ? 'available' : '',
			signInAttributes = {},
			signInButtonClasses = `btn btn-block ${signInButtonIsActive}`,
			hasError = !isEmpty(this.props.loginError) ? ' has-mah-error' : '',
			errorMessages = hasError ? this.props.loginError : '';

		if (!signInButtonIsActive) {
			signInAttributes.disabled = 'disabled';
		}

		return (
			<form onSubmit={this.onSubmit} className="user-login-form">
				<fieldset>
					{loginFields.map((field) => {
						return (
							<SimpleInputField key={field.name} {...field}
											  value={this.state[field.name]}
											  handleChange={this.handleFieldChange(field.name)}
											  errorMessages={errorMessages}/>
						);
					})}
					<div className="form-group">
						<button {...signInAttributes} type="submit"
								className={signInButtonClasses}>{gettext('Sign in')}</button>
					</div>
				</fieldset>
			</form>
		);
	}
}

UserLoginForm.propTypes = {
	onLoginAttempt: PropTypes.func.isRequired,
	loginError: PropTypes.string
};

const mapStateToProps = (state) => {
	return {
		loginError: state.auth.loginCredentialsIncorrect
	};
};

const UserLoginFormConnected = connect(mapStateToProps)(UserLoginForm);

export default UserLoginFormConnected;