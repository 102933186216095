import {assignIn} from 'lodash';
import {
	CHECK_IN_CURRENT_STATE,
	CHECK_IN_FAILED,
	CHECK_IN_SUCCESS,
	CHECK_OUT_FAILED,
	CHECK_OUT_SUCCESS
} from '../constants/check-in-out';
import {checkInVessel, checkOutVessel} from '../mah-api/index';
import {changeActiveVessel} from './vessels';
import {isEmptyField} from '../util/field-util';
import {gettext} from '../util/l10n';
import {harbourActivated, locationUpdated} from './index';
import GeoPoint from '../util/geo/geo-point';

export const setCurrentCheckInState = (data) => {
	return {
		type: CHECK_IN_CURRENT_STATE,
		payload: {
			data
		}
	};
};

const checkInFailed = (errorDetails) => {
	return {
		type: CHECK_IN_FAILED,
		payload: {
			error: errorDetails.status_message
		}
	};
};

const checkInSuccess = () => {
	return {
		type: CHECK_IN_SUCCESS,
		payload: {}
	};
};
const checkOutFailed = (errorDetails, checkOutData) => {
	return {
		type: CHECK_OUT_FAILED,
		payload: {
			error: errorDetails.status_message,
			checkOutData
		}
	};
};

const checkOutSuccess = (harbourJournal) => {
	return {
		type: CHECK_OUT_SUCCESS,
		payload: {
			harbourJournal
		}
	};
};

const checkOutValidation = (checkOutData) => {
	if (isEmptyField(checkOutData.key_next_harbour) && isEmptyField(checkOutData.next_harbour)) {
		return Promise.reject({
			response: {status_message: gettext('Next Harbour is required')}
		});
	}
	return Promise.resolve();
};

export const completeCheckIn = (harbourJournal, harbour) => {
	return (dispatch) => {
		if (harbourJournal) {
			dispatch(changeActiveVessel(harbourJournal.booking.key_user_vessel));
		}
		dispatch(checkInSuccess());
		dispatch(harbourActivated(harbour, 'checked-in'));
		const harbourCenter = new GeoPoint(harbour.lat_degrees, harbour.lon_degrees);
		dispatch(locationUpdated(harbourCenter.toBrowserPosition()));
	};
};

export const checkInAttempt = (checkInData, harbour) => {
	return (dispatch) => {
		let dataForCheckIn = assignIn({}, checkInData);
		if (dataForCheckIn.key_previous_harbour) {
			delete dataForCheckIn.previous_harbour;
		}
		return checkInVessel(dataForCheckIn)
			.then(harbourJournal => {
				dispatch(completeCheckIn(harbourJournal, harbour));
			})
			.catch(error => dispatch(checkInFailed(error.response)));
	};
};

const completeCheckOut = (harbourJournal) => {
	return (dispatch) => {
		let vesselId = harbourJournal.booking.key_user_vessel;
		dispatch(changeActiveVessel(vesselId));
		dispatch(checkOutSuccess(harbourJournal));
	};
};

export const checkOutAttempt = (checkOutData) => {
	return (dispatch) => {
		let dataForCheckOut = assignIn({}, checkOutData);
		if (dataForCheckOut.key_next_harbour) {
			delete dataForCheckOut.next_harbour;
		}
		return checkOutValidation(checkOutData)
			.then(() => checkOutVessel(dataForCheckOut))
			.then(harbourJournal => {
				dispatch(completeCheckOut(harbourJournal));
			})
			.catch(error => dispatch(checkOutFailed(error.response, checkOutData)));
	};
};