import React, {Component} from 'react';
import PropTypes from 'prop-types';

class App extends Component {
	constructor(props) {
		super(props);
	}

	render() {
		const {children} = this.props;
		return (
			<div>
				{children}
			</div>
		);
	}
}

App.propTypes = {
	children: PropTypes.node
};

export {App as App};
