import React from 'react';
import {assignIn, first, isArray, isEmpty} from 'lodash';
import fieldByType from '../../../components/core/fields/fields-factory';

const ACCOUNT_FIELDS = [
	[{name: 'firstname', fieldLabel: 'First name', type: 'text'}],
	[{name: 'lastname', fieldLabel: 'Last name', type: 'text'}],
	[{name: 'username', fieldLabel: 'Email address', type: 'text', isReadOnly: true}],
	[
		{name: 'phone', fieldLabel: 'Phone number', type: 'text'}
	],
	[{name: 'country_iso3', fieldLabel: 'Country', type: 'drop-down'}],
	[
		{name: 'size_unit', fieldLabel: 'Units', type: 'drop-down'},
		{name: 'currency', fieldLabel: 'Currency', type: 'drop-down'}
	]
];

const FIELDS_ROWS = 12;

const valuesListByName = (fieldName) => {
	switch (fieldName) {
		case 'country_iso3':
			return 'country';
		default:
			return fieldName;
	}
};

const accountFields = (options, state) => {
	const {
		handleFieldChange, valuesLists, save_errors
	} = options;
	return ACCOUNT_FIELDS.map((fieldsRow, rowNumber) => {
		const cols = Math.ceil(FIELDS_ROWS / fieldsRow.length),
			classNames = `col-xs-${cols}`,
			rowKey = `fields-row-${rowNumber}`;
		return (
			<div key={rowKey} className="row">
				{fieldsRow.map((field) => {
					const key = field.name,
						handleChange = handleFieldChange(field.name),
						errorMessages = isEmpty(save_errors[field.name]) ? '' :
							isArray(save_errors[field.name]) ? first(save_errors[field.name]) : save_errors[field.name],
						valuesList = valuesLists[valuesListByName(field.name)],
						hasValuesList = !isEmpty(valuesList),
						value = state[field.name] || (hasValuesList ? first(valuesList).value : ''),
						displayText = state[field.textName],
						handleTextChange = handleFieldChange(field.textName),
						errorWrapperClassNames = 'col-xs-12';

					const fieldProps = assignIn({}, field, {
						classNames,
						key,
						handleChange,
						handleTextChange,
						errorMessages,
						valuesList,
						value,
						displayText,
						errorWrapperClassNames
					});
					return fieldByType(field.type, fieldProps, 'account');
				})}
			</div>
		);
	});
};

export default accountFields;