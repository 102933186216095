import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {isEmpty, partial} from 'lodash';
import {Link} from 'react-router';
import {connect} from 'react-redux';
import {gettext} from '../../../util/l10n';
import {push} from 'react-router-redux';
import {harbourIcon as harbourIconImage, HarbourListItem} from '../../../components';
import {loadNearbyHarbours, showHarbourDetails} from '../../../actions';
import {harbourDetailsFor} from '../../../util/harbour-details/harbour-details';

class HarboursListPage extends Component {
	constructor(props) {
		super(props);
		this.harboursByType = this.harboursByType.bind(this);
	}

	componentDidMount() {
		const {loadNearbyHarbours} = this.props;
		loadNearbyHarbours();
	}

	harboursByType(type) {
		const harboursByType = this.props[type],
			{currentLocation, showHarbourDetails} = this.props;
		if (isEmpty(harboursByType)) {
			return '';
		}

		return harboursByType.map(harbourByType => {
			const harbourKeyId = `harbour-${type}-${harbourByType.id}`,
				harbourIcon = harbourIconImage(type, harbourByType, currentLocation),
				harbourDetails = harbourDetailsFor(type, harbourByType, currentLocation),
				harbourClicked = partial(showHarbourDetails, harbourByType, type);

			return (
				<HarbourListItem key={harbourKeyId}
								 harbourIcon={harbourIcon}
								 id={harbourByType.id}
								 name={harbourByType.name}
								 harbourDetails={harbourDetails}
								 harbourClicked={harbourClicked}
				/>
			);
		});
	}

	render() {
		const {home, currentLocation, harbours, showHarbourDetails} = this.props,
			checkedIn = this.props['checked-in'],
			hasMyHarbours = !isEmpty(home) || !isEmpty(checkedIn),
			myHarboursGroupClass = hasMyHarbours ? 'harbours-items-group' : 'harbours-items-group no-items',
			hasNearbyHarbours = !isEmpty(harbours),
			nearbyHarboursGroupClass = hasNearbyHarbours ? 'harbours-items-group' : 'harbours-items-group no-items';

		return (
			<div className="harbours-list">
				<div className="harbours-list-header">
					<div className="harbours-list-header-left">
						<Link to="/">
							<button className="back-button">
								<i className="material-icons md-white md-24">arrow_back</i>
							</button>
						</Link>
						<div className="harbours-list-title">
							{gettext('Harbours list')}
						</div>
					</div>
					<Link className="harbours-list-header-right" to="/harbours/search">
						<button className="search-button">
							<i className="material-icons md-white md-24">search</i>
						</button>
					</Link>
				</div>
				<div className="harbours-list-items">
					<ul className="harbours-items">
						<li className={myHarboursGroupClass}>
							{gettext('MY HARBOURS')}
						</li>
						{this.harboursByType('home')}
						{this.harboursByType('checked-in')}

						<li className={nearbyHarboursGroupClass}>
							{gettext('NEARBY HARBOURS')}
						</li>
						{harbours.map((harbour) => {
							const harbourKeyId = `harbour-${harbour.id}`,
								harbourIcon = harbourIconImage('direction', harbour, currentLocation),
								harbourDetails = harbourDetailsFor('direction', harbour, currentLocation),
								harbourClicked = partial(showHarbourDetails, harbour);
							return (
								<HarbourListItem key={harbourKeyId}
												 harbourIcon={harbourIcon}
												 id={harbour.id}
												 name={harbour.name}
												 harbourDetails={harbourDetails}
												 harbourClicked={harbourClicked}
								/>
							);
						})}
					</ul>
				</div>
			</div>
		);
	}
}

HarboursListPage.propTypes = {
	push: PropTypes.func.isRequired,
	harbours: PropTypes.array,
	home: PropTypes.array,
	'checked-in': PropTypes.array,
	currentLocation: PropTypes.object,
	showHarbourDetails: PropTypes.func.isRequired
};

const mapStateToProps = (state) => {
	return {
		harbours: state.harbours.nearby || [],
		home: state.harbours.home || [],
		'checked-in': state.harbours['checked-in'] || [],
		currentLocation: state.maps.currentLocation || {}
	};
};

const mapDispatchToProps = {
	push,
	loadNearbyHarbours,
	showHarbourDetails
};

export const HarboursList = connect(mapStateToProps, mapDispatchToProps)(HarboursListPage);