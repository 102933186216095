import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {first, isArray, isEmpty} from 'lodash';
import {gettext} from '../../../util/l10n';
import {isEmptyField} from '../../../util/field-util';

class AutoCompleteInputField extends Component {
	constructor(props, context) {
		super(props, context);
		this.state = {
			hideAutoComplete: false,
			focused: false
		};
		this.handleChange = this.handleChange.bind(this);
		this.displayErrors = this.displayErrors.bind(this);
		this.selectAutoComplete = this.selectAutoComplete.bind(this);
		this.onBlur = this.onBlur.bind(this);
		this.onFocus = this.onFocus.bind(this);
	}

	onBlur() {
		this.setState({focused: false});
	}

	onFocus() {
		this.setState({focused: true});
	}

	componentDidMount() {
		this.setState({
			hideAutoComplete: true
		});
	}

	selectAutoComplete(e, autoCompleteValue) {
		e.preventDefault();
		this.setState({
			hideAutoComplete: true
		});
		const {handleChange, handleTextChange} = this.props;
		handleChange(autoCompleteValue.id);
		handleTextChange(autoCompleteValue.name);
	}

	handleChange(e) {
		const value = e.target.value,
			{
				autoCompleteType, requestAutoComplete, cleanAutoComplete,
				handleChange, handleTextChange
			} = this.props;
		this.setState({
			hideAutoComplete: false
		});
		if (value.length > 2) {
			requestAutoComplete(autoCompleteType, value);
		} else {
			cleanAutoComplete(autoCompleteType);
		}
		handleChange('');
		handleTextChange(value);
	}

	displayErrors() {
		const {errorWrapperClassNames, errorMessages} = this.props;
		const errorMessage = isArray(errorMessages) ? first(errorMessages) : errorMessages,
			errorTextClassName = 'errors-text' + (errorWrapperClassNames ? ` ${errorWrapperClassNames}` : ''),
			errorIconClassName = 'errors-icon' + (errorWrapperClassNames ? ` ${errorWrapperClassNames}` : '');

		return (
			<div>
				<p className={errorTextClassName} data-tip={gettext(errorMessage)}>{gettext(errorMessage)}</p>
				<p className={errorIconClassName} data-tip={gettext(errorMessage)}>
					<i className="material-icons md-error md-18">report_problem</i>
				</p>
			</div>
		);
	}

	render() {
		const {
				autoCompleteType, name, autocomplete, value,
				errorMessages, classNames, displayText, fieldLabel
			} = this.props,
			elementKey = 'input-field-' + name,
			autoCompleteValues = autocomplete[autoCompleteType] || [],
			displayAutoComplete = !!autoCompleteValues.length && !this.state.hideAutoComplete,
			autoCompleteHasValues = displayAutoComplete ? 'auto-complete-items' : '',
			isEmptyClassName = isEmptyField(value) && isEmpty(displayText) ? 'is-empty' : '',
			isFocusedClassName = this.state.focused ? 'is-focused' : '',
			hasError = !isEmpty(errorMessages) ? ' has-mah-error' : '',
			customClassNames = !isEmpty(classNames) ? classNames : '',
			fieldsGroupClassName = `form-group ${hasError} ${autoCompleteHasValues}` +
				` ${customClassNames} ${isFocusedClassName} ${isEmptyClassName} label-floating`;

		return (
			<div className={fieldsGroupClassName}>
				<label htmlFor={elementKey} className="control-label">{gettext(fieldLabel)}</label>
				<input type="text" autoComplete="off" value={displayText} className="form-control"
					   id={elementKey} onChange={this.handleChange}
					   onFocus={this.onFocus} onBlur={this.onBlur}/>
				<input type="hidden" value={value}/>
				<div className="col-xs-12 auto-complete-items">
					<ul>
						{
							autoCompleteValues.map((autoCompleteValue) => {
								return (
									<li key={autoCompleteValue.id}>
										<a href="javascript:void(0);"
										   onClick={(e) => this.selectAutoComplete(e, autoCompleteValue)}>
											{autoCompleteValue.name}
										</a>
									</li>
								);
							})
						}
					</ul>
				</div>
				{this.displayErrors()}
			</div>
		);
	}
}

AutoCompleteInputField.propTypes = {
	name: PropTypes.string.isRequired,
	fieldLabel: PropTypes.string.isRequired,
	value: PropTypes.any,
	displayText: PropTypes.any,
	handleChange: PropTypes.func.isRequired,
	handleTextChange: PropTypes.func.isRequired,
	errorMessages: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
	classNames: PropTypes.string,
	errorWrapperClassNames: PropTypes.string,
	requestAutoComplete: PropTypes.func.isRequired,
	cleanAutoComplete: PropTypes.func.isRequired,
	autocomplete: PropTypes.object.isRequired,
	autoCompleteType: PropTypes.string.isRequired
};

export default AutoCompleteInputField;