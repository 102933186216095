import React from 'react';
import {assignIn, first, isArray, isEmpty, noop} from 'lodash';
import fieldByType from '../../../components/core/fields/fields-factory';
import {CHECK_OUT_FIELDS} from '../../../constants/check-in-out';

const checkOutFields = (options, state) => {
	const {
		handleFieldChange, save_errors, requestAutoComplete,
		cleanAutoComplete, autocomplete
	} = options;

	return CHECK_OUT_FIELDS.map((fieldsRow, rowNumber) => {
		const classNames = 'col-xs-12', rowKey = `fields-row-${rowNumber}`;
		return (
			<div key={rowKey} className="row">
				{fieldsRow.map((field) => {
					const key = field.name,
						handleChange = handleFieldChange(field.name),
						errorMessages = isEmpty(save_errors[field.name]) ? '' :
							isArray(save_errors[field.name]) ? first(save_errors[field.name]) : save_errors[field.name],
						value = state[field.name],
						isAutoFill = field.type === 'auto-fill',
						displayText = isAutoFill ? state[field.textName] : '',
						handleTextChange = isAutoFill ? handleFieldChange(field.textName) : noop,
						errorWrapperClassNames = 'col-xs-12';

					const fieldProps = assignIn({}, field, {
						classNames,
						key,
						handleChange,
						handleTextChange,
						errorMessages,
						value,
						displayText,
						errorWrapperClassNames,
						requestAutoComplete,
						cleanAutoComplete,
						autocomplete
					});
					return fieldByType(field.type, fieldProps, 'check-out');
				})}
			</div>
		);
	});
};

export {checkOutFields};