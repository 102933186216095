export const HARBOURS_LOADED = '@@mah-maps/HARBOURS_LOADED';
export const HOME_HARBOUR_LOADED = '@@mah-maps/HOME_HARBOUR_LOADED';
export const CHECKED_IN_HARBOURS_LOADED = '@@mah-maps/CHECKED_IN_HARBOURS_LOADED';
export const NEARBY_HARBOUR_LOADED = '@@mah-maps/NEARBY_HARBOUR_LOADED';
export const CHECKED_IN_HARBOURS_RESET = '@@mah-maps/CHECKED_IN_HARBOURS_RESET';
export const HOME_HARBOUR_RESET = '@@mah-maps/HOME_HARBOUR_RESET';
export const HARBOUR_ACTIVATED = '@@mah-maps/HARBOUR_ACTIVATED';
export const HARBOUR_SERVICES_LOADED = '@@mah-maps/HARBOUR_SERVICES_LOADED';
export const HARBOUR_BERTHS_LOADED = '@@mah-maps/HARBOUR_BERTHS_LOADED';
export const HARBOUR_DEACTIVATED = '@@mah-maps/HARBOUR_DEACTIVATED';
export const HARBOUR_DETAILS_TOGGLE = '@@mah-maps/HARBOUR_DETAILS_TOGGLE';
export const SEARCH_CENTER_OBTAINED = '@@mah-maps/SEARCH_CENTER_OBTAINED';