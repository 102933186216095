import React from 'react';
import {connect} from 'react-redux';
import {isEmpty} from 'lodash';
import {validateLoggedOut, validateLogin} from '../../actions';

const requireAuthentication = (Component, displayInCaseOf) => {

	class AuthenticatedComponent extends React.Component {

		componentWillMount() {
			this.checkAuth();
		}

		componentWillReceiveProps() {
			this.checkAuth();
		}

		isNotAuthenticated() {
			return !this.props.isAuthenticated && !(this.state && this.state.authInProgress);
		}

		checkAuth() {
			if (this.isNotAuthenticated()) {
				this.setState({authInProgress: true});
				const validator = displayInCaseOf ? validateLogin : validateLoggedOut;
				this.props.dispatch(validator(this.props.location.pathname));
			}
		}

		render() {
			return (
				<div>
					{this.props.isAuthenticated === displayInCaseOf ? <Component {...this.props}/> : null}
				</div>
			);

		}
	}

	const mapStateToProps = (state) => ({
		isAuthenticated: !isEmpty(state.auth.user)
	});

	return connect(mapStateToProps)(AuthenticatedComponent);
};

export {requireAuthentication};