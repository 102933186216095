import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {push} from 'react-router-redux';
import {find, first} from 'lodash';
import {changeActiveVessel, toggleMenu} from '../../../actions';
import AddVessel from './add-vessel';
import {getVesselIcon} from '../../vessel-icon/vessel-icon';
import {gettext} from '../../../util/l10n';

class VesselsList extends Component {
	constructor(props, context) {
		super(props, context);
		this.state = {
			dropDownActive: false
		};
		this.handleDropDownActivated = this.handleDropDownActivated.bind(this);
		this.handleSelectVesselClick = this.handleSelectVesselClick.bind(this);
		this.handleAddVesselClick = this.handleAddVesselClick.bind(this);
	}

	handleAddVesselClick(e) {
		e.preventDefault();
		const {push} = this.props;
		push('/vessel/add');
	}

	handleDropDownActivated(e) {
		e.preventDefault();
		this.setState({dropDownActive: !this.state.dropDownActive});
	}

	handleSelectVesselClick(vesselId) {
		const {changeActiveVessel, toggleMenu} = this.props;
		return (e) => {
			e.preventDefault();
			this.setState({dropDownActive: false});
			changeActiveVessel(vesselId);
			toggleMenu();
		};
	}

	render() {
		const {vessels, activeVesselId} = this.props,
			currentVessel = find(vessels, (vessel) => vessel.id === activeVesselId) || first(vessels),
			vesselsListClassName = 'dropdown-menu vessels-list-menu' + (this.state.dropDownActive ? ' activated' : '');

		if (!currentVessel) {
			return (<AddVessel handleAddVessel={this.handleAddVesselClick}/>);
		}

		return (
			<div className="btn-group btn-group-justified vessel-menu-group">
				<button onClick={this.handleDropDownActivated} type="button"
						className="btn btn-default vessel-menu-button">
					<span className="vessel-dropdown-current-value">{currentVessel.name}</span>
					<i className="material-icons md-24 md-white">arrow_drop_down</i>
				</button>
				<ul className={vesselsListClassName}>
					{vessels.map(vessel => {
						const vesselIcon = getVesselIcon(vessel.type),
							vesselKey = `vessel-${vessel.id}`;
						return (
							<li className="vessel-list-item" key={vesselKey}>
								<a href="#" onClick={this.handleSelectVesselClick(vessel.id)}>
									{vesselIcon}
									<div className="vessel-list-item-name">{vessel.name} ({vessel.reg_code})</div>
								</a>
							</li>
						);
					})}
					<li className="vessel-list-item">
						<a className="add-vessel-link" href="#" onClick={this.handleAddVesselClick}>
							<span>{gettext('Add vessel')}</span>
							<i className="material-icons md-24 md-white">add_box</i>
						</a>
					</li>
				</ul>
			</div>
		);
	}
}

VesselsList.propTypes = {
	vessels: PropTypes.array,
	activeVesselId: PropTypes.number,
	changeActiveVessel: PropTypes.func.isRequired,
	toggleMenu: PropTypes.func.isRequired,
	push: PropTypes.func.isRequired
};

const mapStateToProps = (state) => {
	return {
		vessels: state.vessels.list || [],
		activeVesselId: state.vessels.activeVesselId
	};
};

const mapDispatchToProps = {
	changeActiveVessel,
	toggleMenu,
	push
};

export default connect(mapStateToProps, mapDispatchToProps)(VesselsList);