import React, {Component} from 'react';
import {gettext} from '../../util/l10n';

class ActivationSucceed extends Component {
	constructor(props) {
		super(props);
	}

	render() {
		return (
			<div className="activation-succeed-section text-center">
				<div className="mail-icon">
					<i className="material-icons md-80 md-green">check_circle</i>
				</div>
				<div className="after-activation-instructions">
					<div>
						{gettext('Activation complete.')}
						<br/>
						{gettext('Click button below to Login.')}
					</div>
				</div>
			</div>
		);
	}
}

ActivationSucceed.propTypes = {};

export {ActivationSucceed};