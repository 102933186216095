import {COUNTRY_OBTAINED, LOCATION_UPDATED} from '../constants/location';
import {getCurrentPosition, getPositionPoint} from '../util/position';
import {findCountryByLocation} from '../util/location-iq';
import GeoPoint from '../util/geo/geo-point';
import {getCountryByCode} from '../mah-api/index';
import * as logger from '../util/logger';

const toGeoPoint = (coords) => {
	const {lat, lng} = getPositionPoint(coords);
	return new GeoPoint(lat, lng);
};

export const locationUpdated = (position) => {
	return {
		type: LOCATION_UPDATED,
		payload: {
			position
		}
	};
};

const userCountryObtained = (country) => {
	return {
		type: COUNTRY_OBTAINED,
		payload: {
			country
		}
	};
};

export const locateMe = () => {
	return (dispatch) => {
		getCurrentPosition()
			.then(position => dispatch(locationUpdated(position)))
			.catch(err => logger.error(err));
	};
};

export const detectUserCountry = () => {
	return (dispatch) => {
		getCurrentPosition()
			.then(position => toGeoPoint(position.coords))
			.then(geoPoint => findCountryByLocation(geoPoint))
			.then(address => getCountryByCode(address.country_code))
			.then(country => dispatch(userCountryObtained(country)))
			.catch(err => logger.error(err));
	};
};