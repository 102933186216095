import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {Link} from 'react-router';
import {connect} from 'react-redux';
import {goBack} from 'react-router-redux';
import {gettext} from '../../../util/l10n';

class TermsPage extends Component {
	constructor(props) {
		super(props);
		this.goBack = this.goBack.bind(this);
	}

	goBack(e) {
		this.props.goBack();
		e.preventDefault();
	}

	render() {
		return (
			<div className="static-content">
				<div className="static-content-header">
					<Link onClick={this.goBack} to="#">
						<button className="menu-button">
							<i className="material-icons md-white md-24">arrow_back</i>
						</button>
					</Link>
					<div className="static-content-title">
						{gettext('Terms & Conditions')}
					</div>
				</div>
				<div className="static-content-details">
					<h4>Marina Ahoy Terms and Conditions</h4>
					<p>Marina Ahoy is a self- service platform for using harbour services online. It communicates
						check-in and booking requests made from harbours customer through Marina Ahoy app to the harbour
						service providers who have been registered as users of the Marina Ahoy service. The private
						limited company Marina Ahoy OÜ (registry code 12911764), founded in the Republic of Estonia, is
						the holder of rights and service provider of the Marina Ahoy services.</p>
					<ol>
						<li className="list-major">
							<span>Using the Marina Ahoy app</span>
							<ol>
								<li>
									The use of the Marina Ahoy app requires registration of a user account. During the
									registration process, the name and mobile number of the Marina Ahoy service user is
									linked to the respective user account and added to the database.
								</li>
								<li>
									Any complaints and proposals for improving the service can be sent to our support
									team via email <a href="mailto:ahoy@marinaahoy.com">ahoy@marinaahoy.com</a>
								</li>
							</ol>
						</li>
						<li className="list-major">
							<span>Marina Ahoy credit card payment conditions</span>
							<ol>
								<li>The user of the Marina Ahoy app can pay for the harbour service with a credit card
									payment, which requires the prior activation of the credit card data within the app
									and acceptance of Marina Ahoy Terms and Conditions.
								</li>
								<li>When making credit card payments, the receiver of the payment is Marina Ahoy OÜ, who
									forwards the received payment to the harbour service provider.
								</li>
								<li>Using Marina Ahoy is free for harbour customers. The total price displayed for the
									app users before check-in is not different from the price at the harbour. The
									service fee, which includes payment commission fees, incl. Visa / Mastercard service
									fees is commissioned from the harbour service provider by Marina Ahoy when
									processing the payments.
								</li>
								<li>The intermediation of credit card payments in the Marina Ahoy app is carried out
									through PayPal (Braintree Payment Services) pursuant to the following conditions
									<a href="https://www.braintreepayments.com/en-ee/legal/payment-services-agreement"
									   target="_blank">https://www.braintreepayments.com/en-ee/legal/payment-services-agreement</a>
								</li>
								<li>Marina Ahoy shall be responsible for the functioning of credit card payments and
									offers card owners support in solving problems. The resolution of credit card
									payment related disputes also takes place through Marina Ahoy. The contact for
									Marina Ahoy credit card payment support service is: <a
										href="mailto:ahoy@marinaahoy.com">ahoy@marinaahoy.com</a>. Marina Ahoy
									shall resolve all credit card payment related complaints and applications within two
									business days.
								</li>
								<li>When offering credit card payments, Marina Ahoy acts as the economic agent of
									harbour service providers by intermediating payments made in the Marina Ahoy app.
									The obligation of the harbour customer in front of the harbour service provider
									shall be considered fulfilled as of the moment when the payment order is made for
									the payment of funds into the Marina Ahoy bank account. Marina Ahoy shall not be
									responsible for the exercise of the harbour customer's payment order.
								</li>
							</ol>
						</li>
						<li className="list-major">
							<span>Using the self-service in harbours</span>
							<ol>
								<li>Harbour service providers using Marina Ahoy list information about their harbour
									(description, services, rules, contacts) for the app users. The information is
									managed by the
									Harbour service providers.
								</li>
								<li>Harbour service providers manage harbour customers pre-paid long-term bookings to
									enable check-in &amp; out for harbour customers by skipping the payment request.
									After the
									harbour service provider adds an entry of a pre-paid booking using Marina Ahoy
									service, the
									vessel profile for the user account associated to the entries e-mail address is
									created
									automatically.
								</li>
								<li>After the sign-up process, Marina Ahoy app user adds a vessel and payment details
									for
									check-in &amp; out and paying mobile.
								</li>
								<li>Harbour pricelists are managed by harbour service providers. The total price
									relevant for
									the size of the added vessel and length of the stay is calculated automatically and
									displayed
									for the app user before the check in.
								</li>
								<li>When the vessel is checked in and paid or checked out the service is considered
									successful.
								</li>
							</ol>
						</li>
						<li className="list-major">
							<span>Use of the Marina Ahoy app</span>
							<ol>
								<li>Marina Ahoy is a self-service tool for harbour customers to check-in and pay for
									harbour
									services via Marina Ahoy app.
								</li>
								<li>The use of the Marina Ahoy app is based on a non-exclusive licence issued by Marina
									Ahoy OÜ. The licence agreement is valid for a non-limited period and is free of
									charge for
									the harbour customer. In case of any faults in the software, we shall endeavour to
									correct
									them as soon as possible, but please keep in mind that the functioning of the app
									may be
									restricted due to occasional technical errors and we are not able to guarantee
									unlimited
									faultless functioning of the app at all times. We shall also accept no liability for
									any losses
									incurred as a consequence of the Marina Ahoy app not functioning or not being usable
									in the
									desired manner.
								</li>
								<li>As the Marina Ahoy app is a means of communication between harbour customers and
									harbour service providers, Marina Ahoy cannot influence or take any responsibility
									for the
									quality or defects of the service. For this reason, we are unable to guarantee
									consistently
									accurate and faultless provision of harbour services located via Marina Ahoy. For
									resolving
									complaints please contact our support team (referred to in clause 1.2).
								</li>
								<li>The Marina Ahoy app does not constitute an offer or brokerage of harbour services
									for
									customers. The Marina Ahoy app is not a means for organising the provision of
									harbour
									services. It is also not an agency service for finding customers for harbour service
									providers.
								</li>
								<li>The consumer's right of refund (withdrawal) is not applied to Marina Ahoy app
									orders.
								</li>
							</ol>
						</li>
						<li className="list-major">
							<span>By registering an account with Marina Ahoy, a harbour customer shall accept the following conditions</span>
							<ol>
								<li>Marina Ahoy shall have the right to add the personal data of the app user to the
									Marina
									Ahoy database and to forward the personal data to harbour service providers in
									accordance
									with Marina Ahoy's Privacy Policy (<a href="http://www.marinaahoy.com/terms-privacy"
									                                      target="_blank">http://www.marinaahoy.com/terms-privacy</a>).
								</li>
								<li>Marina Ahoy shall have a right to make unilateral amendments to the Terms and
									Conditions and Privacy Policy and to relinquish the database to third parties. We
									shall notify
									the users of changes to Terms and Conditions and Privacy Policy.
								</li>
								<li>Marina Ahoy shall be entitled to transfer the database of personal data to third
									parties
									without prior notification of the app users. In case of a transfer of the business
									or the
									database, the rights and conditions arising from this licence agreement shall be
									transferred
									as well.
								</li>
								<li>Marina Ahoy shall be entitled to forward personal data and bank data to credit card
									payment intermediaries.
								</li>
								<li>Marina Ahoy has the right to send marketing messages and authentication codes
									through SMS messages.
								</li>
							</ol>
						</li>
						<li className="list-major">
							<span>Good practice of using the Marina Ahoy app</span>
							<ol>
								<li>As Marina Ahoy is not a provider or broker of the harbour services, we are unable to
									influence the quality of the harbour service. Any issues with defects or quality of
									the harbour
									service shall be resolved in accordance with the rules and regulations of the
									harbour service
									provider or the relevant supervisory authority.
								</li>
								<li>We expect that the users of the Marina Ahoy app use the app in good faith and are
									respectful of the harbours who offer their services through Marina Ahoy.
								</li>
							</ol>
						</li>
					</ol>
				</div>
			</div>
		);
	}
}

TermsPage.propTypes = {
	goBack: PropTypes.func.isRequired
};

const mapStateToProps = () => {
	return {};
};

const mapDispatchToProps = {
	goBack
};

export const Terms = connect(mapStateToProps, mapDispatchToProps)(TermsPage);