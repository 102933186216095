import {assignIn, cloneDeep, omit} from 'lodash';
import {MAP_CENTER_CHANGED, POSITION_OBTAINED, ZOOM_CHANGED} from '../constants/maps';
import {LOCATION_UPDATED} from '../constants/location';
import {getPositionPoint} from '../util/position';

const defaultState = {
	coordinates: null,
	mapCenter: {
		lat: 59.439974,
		lng: 24.749931
	},
	obtainedCoordinates: null,
	zoom: 9
};

const maps = (state = defaultState, action) => {
	let centerUpdateTime = new Date().getTime(),
		coordinates, obtainedCoordinates;
	switch (action.type) {
		case POSITION_OBTAINED: {
			let currentLocation = getPositionPoint(action.payload.position.coords);
			coordinates = getPositionPoint(action.payload.position.coords);
			obtainedCoordinates = cloneDeep(coordinates);
			return assignIn({}, state,
				{currentLocation, coordinates, centerUpdateTime, obtainedCoordinates});
		}
		case LOCATION_UPDATED:
			coordinates = getPositionPoint(action.payload.position.coords);
			obtainedCoordinates = cloneDeep(coordinates);
			return assignIn({}, state,
				{coordinates, centerUpdateTime, obtainedCoordinates});
		case MAP_CENTER_CHANGED: {
			const mapCenter = getPositionPoint(action.payload.position.coords);
			return assignIn({}, omit(state, 'coordinates'), {mapCenter});
		}
		case ZOOM_CHANGED:
			return assignIn({}, state, {zoom: action.payload.zoom});
	}
	return state;
};

export default maps;