import {HARBOUR_DEACTIVATED, HARBOUR_DETAILS_TOGGLE} from '../constants/harbours';

const harbourDetails = (state = {showHarbourDetails: false}, action) => {
	switch (action.type) {
		case HARBOUR_DETAILS_TOGGLE:
			return {showHarbourDetails: !state.showHarbourDetails};
		case HARBOUR_DEACTIVATED:
			return {showHarbourDetails: false};
	}
	return state;
};

export default harbourDetails;