import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {assignIn, first, isEmpty, isEqual, pick, reduce, transform} from 'lodash';
import {Link} from 'react-router';
import {connect} from 'react-redux';
import {gettext} from '../../../util/l10n';
import {
	cleanAutoComplete,
	loadVesselDetails,
	requestAutoComplete,
	resetVesselDetails,
	saveVesselAttempt
} from '../../../actions';
import {goBack, push} from 'react-router-redux';
import vesselFields from './vessel-fields';
import {mapClassifiersToLists} from '../../../util/classifier';
import {isEmptyField} from '../../../util/field-util';
import ReactTooltip from 'react-tooltip';
import {FIELDS_MEASURES_NAMES, FIELDS_NAMES, REQUIRED_FIELDS_NAMES} from '../../../constants/vessels';

const METER_TO_FOOT = 3.28084;

class VesselEditPage extends Component {
	constructor(props) {
		super(props);
		this.state = {};
		this.goBack = this.goBack.bind(this);
		this.ensureVesselLoaded = this.ensureVesselLoaded.bind(this);
		this.handleFieldChange = this.handleFieldChange.bind(this);
		this.onSubmit = this.onSubmit.bind(this);
		this.clearState = this.clearState.bind(this);
		this.getCleanState = this.getCleanState.bind(this);
		this.buildFieldsValuesLists = this.buildFieldsValuesLists.bind(this);
		this.getDefaultValueFromClassifier = this.getDefaultValueFromClassifier.bind(this);
		this.getMissingClassifiers = this.getMissingClassifiers.bind(this);
		this.vesselDataFilled = this.vesselDataFilled.bind(this);
		this.handleMeasurementUnitChanges = this.handleMeasurementUnitChanges.bind(this);
	}

	getDefaultValueFromClassifier(classifiers, classifierName, parameterName = classifierName) {
		if (!isEmpty(classifiers) && !isEmpty(classifiers[classifierName])) {
			return {
				[parameterName]: first(classifiers[classifierName]).value
			};
		}
		return {};
	}


	getCleanState(cleanText) {
		const cleanState = transform(FIELDS_NAMES, (cleanState, stateKey) => {
			cleanState[stateKey] = '';
			return cleanState;
		}, {});
		if (cleanText) {
			cleanState.text_key_home_harbour = '';
		}
		return cleanState;
	}

	clearState(cleanText) {
		this.setState(this.getCleanState(cleanText));
	}

	getMissingClassifiers(forState, mappedClassifiers, userDefaults) {
		let missingClassifiers = {};
		if (isEmpty(forState.flag)) {
			assignIn(missingClassifiers, this.getDefaultValueFromClassifier(mappedClassifiers, 'country', 'flag'));
		}
		if (isEmpty(forState.type)) {
			assignIn(missingClassifiers, this.getDefaultValueFromClassifier(mappedClassifiers, 'vessel_type', 'type'));
		}
		if (isEmpty(forState.size_unit)) {
			if (userDefaults.size_unit) {
				assignIn(missingClassifiers, {
					size_unit: userDefaults.size_unit
				});
			} else {
				assignIn(missingClassifiers, this.getDefaultValueFromClassifier(mappedClassifiers, 'size_unit'));
			}
		}
		return missingClassifiers;
	}

	componentWillReceiveProps(nextProps) {
		const {vessel, user} = nextProps,
			currentVessel = this.props.vessel,
			vesselCalculatedProps = {
				text_key_home_harbour: vessel.harbourDetails && vessel.harbourDetails.name ||
				vessel.text_key_home_harbour ||
				vessel.home_harbour
			},
			mappedClassifiers = mapClassifiersToLists(nextProps.classifiers);
		const isVesselChanged = !isEqual(vessel, currentVessel),
			vesselsEmpty = isEmpty(vessel) && isEmpty(currentVessel) && isEmpty(this.state),
			isNewVessel = isEmpty(vessel),
			userDefaults = pick(user, ['size_unit']);

		if (!(isVesselChanged || vesselsEmpty)) {
			const missingClassifiers = this.getMissingClassifiers(this.state, mappedClassifiers, userDefaults);
			this.setState(missingClassifiers);
			return;
		}

		let newState = {};

		if (!isNewVessel) {
			newState = assignIn({}, vessel, vesselCalculatedProps);
		} else {
			newState = this.getCleanState(true);
		}

		assignIn(newState, this.getMissingClassifiers(newState, mappedClassifiers, userDefaults));

		if (newState.size_unit !== 'M') {
			assignIn(newState, this.handleMeasurementUnitChanges('size_unit', newState.size_unit, newState));
		}

		this.setState(newState);
	}

	buildFieldsValuesLists() {
		return mapClassifiersToLists(this.props.classifiers);
	}

	handleMeasurementUnitChanges(propertyName, value, state, reverse = false) {
		var changes = {};
		if (propertyName !== 'size_unit') {
			return;
		}
		let convert = (val) => parseFloat(val);
		if (value === 'I' && !reverse) {
			convert = (val) => parseFloat(val) * METER_TO_FOOT;
		} else {
			convert = (val) => parseFloat(val) / METER_TO_FOOT;
		}
		FIELDS_MEASURES_NAMES.forEach((fieldName) => {
			let currentValue = state[fieldName];
			changes[fieldName] = convert(currentValue);
		});
		return changes;
	}

	handleFieldChange(propertyName) {
		return (value) => {
			this.setState({[propertyName]: value});
			const measurementChanges = this.handleMeasurementUnitChanges(propertyName, value, this.state);
			if (!isEmpty(measurementChanges)) {
				this.setState(measurementChanges);
			}
		};
	}

	ensureVesselLoaded() {
		const {vessel, params = {}, loadVesselDetails, resetVesselDetails} = this.props;
		const {vesselId} = params;

		if (!vesselId) {
			resetVesselDetails();
			return;
		}
		if (!isEmpty(vessel) && parseInt(vesselId, 10) === vessel.id) {
			return;
		}

		loadVesselDetails(vesselId);
	}

	componentDidMount() {
		this.ensureVesselLoaded();
	}

	goBack(e) {
		e.preventDefault();
		this.props.goBack();
	}

	onSubmit(e) {
		e.preventDefault();
		let vesselDetails = assignIn({}, this.state);
		if (vesselDetails.size_unit !== 'M') {
			const measurementChanges = this.handleMeasurementUnitChanges('size_unit', vesselDetails.size_unit, this.state, true);
			vesselDetails = assignIn(vesselDetails, measurementChanges);
		}
		vesselDetails.home_harbour = vesselDetails.text_key_home_harbour;
		this.props.saveVesselAttempt(vesselDetails);
	}

	vesselDataFilled() {
		return reduce(REQUIRED_FIELDS_NAMES, (dataFilled, fieldName) => {
			return dataFilled && !isEmptyField(this.state[fieldName]);
		}, true);
	}

	render() {
		const {vesselId} = this.props.params,
			{vessel, requestAutoComplete, autocomplete, cleanAutoComplete, save_errors} = this.props,
			valuesLists = this.buildFieldsValuesLists();
		const vesselText = !isEmpty(vesselId) ? vessel.name : gettext('Add vessel'),
			saveVesselText = !isEmpty(vesselId) ? gettext('Save vessel') : gettext('Add vessel'),
			saveVesselIsAvailable = this.vesselDataFilled() ? 'available' : '',
			saveVesselClassNames = `btn btn-block ${saveVesselIsAvailable}`;
		return (
			<div className="vessel-edit">
				<div className="vessel-edit-header">
					<Link onClick={this.goBack} to="#">
						<button className="menu-button">
							<i className="material-icons md-white md-24">arrow_back</i>
						</button>
					</Link>
					<div className="vessel-edit-title">
						{vesselText}
					</div>
				</div>
				<form onSubmit={this.onSubmit}>
					<fieldset>
						<div className="vessel-edit-form container">
							{vesselFields({
								handleFieldChange: this.handleFieldChange,
								valuesLists, requestAutoComplete,
								autocomplete, cleanAutoComplete,
								save_errors
							}, this.state)}
							<input type="hidden" name="vessel_id" value={vesselId}/>
							<div className="form-group row">
								<button type="submit" className={saveVesselClassNames}>{saveVesselText}</button>
							</div>
						</div>
					</fieldset>
				</form>
				<ReactTooltip type="error" event="click" globalEventOff="mouseout"/>
			</div>
		);
	}
}

VesselEditPage.propTypes = {
	goBack: PropTypes.func.isRequired,
	push: PropTypes.func.isRequired,
	loadVesselDetails: PropTypes.func.isRequired,
	resetVesselDetails: PropTypes.func.isRequired,
	saveVesselAttempt: PropTypes.func.isRequired,
	vessel: PropTypes.object,
	classifiers: PropTypes.object.isRequired,
	autocomplete: PropTypes.object.isRequired,
	user: PropTypes.object
};

const mapStateToProps = (state) => {
	return {
		vessel: state.vessels.current || {},
		classifiers: {
			size_unit: state.classifier.size_unit || [],
			country: state.classifier.country || [],
			vessel_type: state.classifier.vessel_type || []
		},
		autocomplete: state.autocomplete || {},
		save_errors: state.vessels.vessel_save_errors || {},
		user: state.auth.user || {}
	};
};

const mapDispatchToProps = {
	goBack,
	push,
	loadVesselDetails,
	resetVesselDetails,
	requestAutoComplete,
	cleanAutoComplete,
	saveVesselAttempt
};

export const VesselEdit = connect(mapStateToProps, mapDispatchToProps)(VesselEditPage);