const ORIGINAL_SMALL_MARKER = {width: 64, height: 88},
	SCALED_SMALL_MARKER = {width: ORIGINAL_SMALL_MARKER.width / 2, height: ORIGINAL_SMALL_MARKER.height / 2},
	ORIGINAL_LARGE_MARKER = {width: 128, height: 176},
	SCALED_LARGE_MARKER = {width: ORIGINAL_LARGE_MARKER.width / 2, height: ORIGINAL_LARGE_MARKER.height / 2},
	TOP_PADDING = {small: 8, default: 16};

const basicIcon = (iconUrl, size = 'small') => {
	const iconSize = size === 'small' ? ORIGINAL_SMALL_MARKER : ORIGINAL_LARGE_MARKER,
		scaledSize = size === 'small' ? SCALED_SMALL_MARKER : SCALED_LARGE_MARKER,
		topPadding = TOP_PADDING[size] || TOP_PADDING['default'];
	return {
		url: iconUrl,
		size: iconSize,
		scaledSize: scaledSize,
		origin: {x: 0, y: 0},
		anchor: {x: scaledSize.width / 2, y: scaledSize.height - topPadding}
	};
};

const imageLinkBySize = (iconType, size = 'small') => {
	const multiplier = size === 'small' ? '' : '@2x';
	return `/img/icons/${iconType}${multiplier}.png`;
};

const defaultHarbourImage = (size = 'small') => {
	return basicIcon(imageLinkBySize('location_regular', size), size);
};

const homeHarbourImage = (size = 'small') => {
	return basicIcon(imageLinkBySize('location_onaleave', size), size);
};

const checkedInHarbourImage = (size = 'small') => {
	return basicIcon(imageLinkBySize('location_checkedin', size), size);
};

export const harbourImageByType = (type, size = 'small') => {
	switch (type) {
		case 'home':
			return homeHarbourImage(size);
		case 'checked-in':
			return checkedInHarbourImage(size);
		default:
			return defaultHarbourImage(size);
	}
};