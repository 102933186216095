import React from 'react';
import {IndexRoute, Route, Router} from 'react-router';
import {isEmpty} from 'lodash';
import {
	AboutPage,
	AccountEditPage,
	ActivationPage,
	AddCreditCardModal,
	App,
	ChangePasswordModal,
	CheckInModal,
	CheckOutModal,
	DatePickerModal,
	DeleteConfirmationModal,
	ForgotPassword,
	ForgotPasswordSuccess,
	HarbourServicesPage,
	HarboursListPage,
	HarboursSearchPage,
	InvoicePage,
	LoginPage,
	NoVesselAvailableModal,
	OAuthLoginPageContainer,
	PasswordRecovery,
	PasswordRecoverySuccess,
	PrivacyPage,
	ProcessOrderModal,
	RegisterPage,
	RegisterUserOkPage,
	TermsPage,
	UserHomePage,
	UserPaymentMethods,
	VesselEditPage,
	VesselsListPage
} from '../containers';
import {authorizationComponent, modalReadyPage, pageWithMenu, requireAuthentication} from '../components';

const redirectUserToHome = (store) => {
	return (location, replace) => {
		const {auth} = store.getState();
		if (!isEmpty(auth.user)) {
			replace('/');
		}
	};
};

export default (history, store) => {
	const AccountEditWithModal = modalReadyPage(AccountEditPage, {
		changePassword: ChangePasswordModal
	});
	const UserHomeWithModal = modalReadyPage(UserHomePage, {
		checkIn: CheckInModal,
		checkOut: CheckOutModal,
		datePicker: DatePickerModal,
		processOrderModal: ProcessOrderModal,
		addCreditCard: AddCreditCardModal,
		noVesselWarning: NoVesselAvailableModal
	});
	const UserPaymentMethodsWithModal = modalReadyPage(UserPaymentMethods, {
		addCreditCard: AddCreditCardModal,
		deleteConfirmation: DeleteConfirmationModal
	});
	const VesselsListWithModal = modalReadyPage(VesselsListPage, {
		deleteConfirmation: DeleteConfirmationModal
	});
	const UserHome = requireAuthentication(pageWithMenu(UserHomeWithModal), true);
	const VesselsList = requireAuthentication(pageWithMenu(VesselsListWithModal), true);
	const VesselAddEdit = requireAuthentication(VesselEditPage, true);
	const AccountEdit = requireAuthentication(pageWithMenu(AccountEditWithModal), true);
	const PaymentMethods = requireAuthentication(pageWithMenu(UserPaymentMethodsWithModal), true);
	const HarboursList = requireAuthentication(HarboursListPage, true);
	const HarboursSearch = requireAuthentication(HarboursSearchPage, true);
	const HarbourServices = requireAuthentication(HarbourServicesPage, true);
	const About = pageWithMenu(AboutPage);
	const toHome = redirectUserToHome(store);
	const UserLoginPage = requireAuthentication(authorizationComponent(LoginPage), false);
	const UserRegistrationPage = requireAuthentication(authorizationComponent(RegisterPage), false);
	const ForgotPasswordPage = requireAuthentication(ForgotPassword, false);
	const PasswordRecoveryPage = requireAuthentication(PasswordRecovery, false);
	const PasswordRecoverySuccessPage = requireAuthentication(PasswordRecoverySuccess, false);
	const ForgotPasswordSuccessPage = requireAuthentication(ForgotPasswordSuccess, false);
	const UserRegistrationSuccessPage = requireAuthentication(RegisterUserOkPage, false);
	return (
		<Router history={history}>
			<Route path="/" component={App}>
				<IndexRoute component={UserHome}/>
				<Route path="/vessels" component={VesselsList}/>
				<Route path="/vessel/edit/:vesselId" component={VesselAddEdit}/>
				<Route path="/vessel/add" component={VesselAddEdit}/>
				<Route path="/harbours" component={HarboursList}/>
				<Route path="/harbours/search" component={HarboursSearch}/>
				<Route path="/harbour/services" component={HarbourServices}/>
				<Route path="/account" component={AccountEdit}/>
				<Route path="/payment-methods" component={PaymentMethods}/>
			</Route>
			<Route path="/about" component={About}/>
			<Route path="/terms" component={TermsPage}/>
			<Route path="/privacy" component={PrivacyPage}/>
			<Route path="/login" component={UserLoginPage}
				   onEnter={toHome}/>
			<Route path="/register" component={UserRegistrationPage}
				   onEnter={toHome}/>
			<Route path="/register_ok" component={UserRegistrationSuccessPage}
				   onEnter={toHome}/>
			<Route path="/auth/activate" component={ActivationPage}/>
			<Route path="/auth/password/restore/" component={PasswordRecoveryPage}/>
			<Route path="/auth/password/restore/complete" component={PasswordRecoverySuccessPage}/>
			<Route path="/forgot-password" component={ForgotPasswordPage}/>
			<Route path="/password-recovery-email-sent" component={ForgotPasswordSuccessPage}/>
			<Route path="/social-login/" component={OAuthLoginPageContainer}/>
			<Route path="/invoice/:invoiceHash" component={InvoicePage}/>
			<Route path="*" component={UserHome}/>
		</Router>
	);
};