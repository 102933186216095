import {INVOICE_LOAD_FAILED, INVOICE_LOAD_SUCCEED} from '../constants/invoice';
import {assign, omit} from 'lodash';

const invoice = (state = {}, action) => {
	const updatedState = omit(assign({}, state), 'details', 'error');
	switch (action.type) {
		case INVOICE_LOAD_FAILED:
			return assign(updatedState, {
				error: action.payload.error
			});
		case INVOICE_LOAD_SUCCEED:
			return assign(updatedState, {
				details: action.payload.invoice
			});
	}
	return updatedState;
};

export default invoice;