import moment from 'moment';
import {isEmptyField} from './field-util';

export const SERVER_FORMAT = 'YYYY-MM-DD HH:ss:00';

const formatWithMoment = (date, format, toUtc) => {
	if (isEmptyField(date)) {
		return '';
	}
	let dateMoment = moment(date);
	if (toUtc) {
		dateMoment = dateMoment.utc();
	}

	return dateMoment.isValid() ? dateMoment.format(format) : '';
};

export const shortDateFormat = (date, format = 'DD.MM.YYYY') => {
	return formatWithMoment(date, format);
};

export const longDateFormat = (date, format = 'LL') => {
	return formatWithMoment(date, format);
};

export const formatPrice = (price) => {
	if (isEmptyField(price)) {
		return '';
	}
	return Number(price).toFixed(2);
};

export const toMoment = (date, useUtc) => {
	let currentMoment = useUtc ? moment().utc() : moment();

	if (isEmptyField(date)) {
		return currentMoment;
	}

	let dateMoment = moment.isMoment(date) ? date : moment(date);
	if (useUtc) {
		dateMoment = dateMoment.utc();
	}

	return dateMoment.isValid() ? dateMoment : currentMoment;
};