import React, {Component} from 'react';
import {connect} from 'react-redux';
import UserRegistrationForm from '../components/registration/registration';
import {registerUser} from '../actions';

class RegisterUserPage extends Component {
	constructor(props) {
		super(props);
		this.onRegistrationAttempt = this.onRegistrationAttempt.bind(this);
	}

	onRegistrationAttempt(regInfo) {
		const {dispatch} = this.props;
		dispatch(registerUser(regInfo));
	}

	render() {
		return (
			<UserRegistrationForm onRegistrationAttempt={this.onRegistrationAttempt}/>
		);
	}
}

export const RegisterUserPageContainer = connect()(RegisterUserPage);