import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {isNull, partial} from 'lodash';
import {connect} from 'react-redux';
import {gettext} from '../../../util/l10n';
import {loadUserCreditCards, removeCreditCard} from '../../../actions';
import {itemsList, Loading, PaymentMethodItem} from '../../../components';

class PaymentMethods extends Component {
	constructor(props) {
		super(props);
		this.removeCreditCard = this.removeCreditCard.bind(this);
		this.setDeleteResults = this.setDeleteResults.bind(this);
		this.itemsList = this.itemsList.bind(this);
		this.loadingIndicator = this.loadingIndicator.bind(this);
	}

	componentDidMount() {
		this.props.loadUserCreditCards();
	}

	setDeleteResults(creditCardToken, result) {
		const {hideModal, removeCreditCard} = this.props;
		hideModal();
		if (result === 'confirm') {
			removeCreditCard(creditCardToken);
		}
	}

	removeCreditCard(creditCardToken, last4) {
		const {showModal} = this.props;
		showModal('deleteConfirmation', {
			setDeleteResults: partial(this.setDeleteResults, creditCardToken),
			Header: () => <div>Delete {`**** ${last4}`}?</div>,
			confirmText: gettext('Delete'),
			cancelText: gettext('Cancel')
		});
	}

	loadingIndicator() {
		return (
			<Loading style={{top: '50%', width: '100%'}} radiusMax={10} radiusMin={6} color="#254ea2"
					 className="col-md-12"/>
		);
	}

	itemsList() {
		const {creditCards, classNames} = this.props,
			listClasses = `${classNames}`;

		return (
			<ul className={listClasses}>
				{creditCards.map(card => {
					const creditCardKey = `credit-card-${card.token}`;
					return (
						<PaymentMethodItem key={creditCardKey}
										   removeCreditCard={this.removeCreditCard}
										   {...card}/>
					);
				})}
			</ul>
		);
	}

	render() {
		const {creditCards} = this.props;

		return isNull(creditCards) ? this.loadingIndicator() : this.itemsList();
	}
}

PaymentMethods.propTypes = {
	classNames: PropTypes.string,
	removeCreditCard: PropTypes.func.isRequired,
	loadUserCreditCards: PropTypes.func.isRequired,
	creditCards: PropTypes.array,
	showModal: PropTypes.func.isRequired,
	hideModal: PropTypes.func.isRequired
};

const mapStateToProps = (state) => {
	return {
		creditCards: state.creditCards.list
	};
};

const mapDispatchToProps = {
	loadUserCreditCards,
	removeCreditCard
};

const itemsListProps = {
	itemsListTitle: gettext('Payment methods'),
	addNewListItem: {
		type: 'modal',
		modal: 'addCreditCard'
	}
};

const ConnectedItemsList = connect(mapStateToProps, mapDispatchToProps)(PaymentMethods);

export const UserPaymentMethods = itemsList(ConnectedItemsList, itemsListProps);