import {
	ACTIVE_BOOKINGS_LOADED,
	BOOKING_CALCULATED,
	BOOKING_CALCULATION_FAILED,
	RESET_BOOKING_PRICE
} from '../constants/booking';
import {MODAL_DEACTIVATED} from '../constants/modal';
import {assign, omit} from 'lodash';
import {gettext} from '../util/l10n';

const booking = (state = {activeBookings: [], bookingPrice: {currency: '', price: 0}}, action) => {
	switch (action.type) {
		case ACTIVE_BOOKINGS_LOADED:
			return assign({}, state, {activeBookings: action.payload.activeBookings});
		case RESET_BOOKING_PRICE: {
			const {currency} = action.payload;

			return assign({}, state, {bookingPrice: {currency, price: 0}});
		}
		case BOOKING_CALCULATION_FAILED: {
			const {currency, error = {}} = action.payload,
				{validation_errors, status_message = gettext('Unable to calculate booking price')} = error;

			return assign({}, state, {
				bookingPrice: {currency, price: 0},
				calculateErrors: validation_errors || {end_date_time: status_message}
			});
		}
		case BOOKING_CALCULATED: {
			const {bookingPrice} = action.payload;

			return assign({}, state, {bookingPrice});
		}
		case MODAL_DEACTIVATED: {
			const {bookingPrice} = state,
				{currency} = bookingPrice,
				price = 0;

			return assign({bookingPrice: {currency, price}}, omit(state, 'bookingPrice', 'calculateErrors'));
		}
	}
	return state;
};

export default booking;