import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {harbourIcon as harbourIconImage} from '../';
import {gettext} from '../../util/l10n';
import {harbourDetailsFor} from '../../util/harbour-details/harbour-details';
import {vesselFitIntoHarbour} from '../../util/vessel-fit-util';
import {Link} from 'react-router';
import {serviceIcon} from '../service-icon/service-icon';
import {isEmpty} from 'lodash';
import {CHECK_IN_TYPES} from '../../constants/check-in-out';
import {BookingIcon} from '../booking-icon/booking-icon';
import {BookingTime} from '../booking-time/booking-time';

const vesselFitWarning = gettext(`Your vessel might not fit into the harbor.
Please contact the harbour master.`);

class HarbourInformation extends Component {
	constructor(props) {
		super(props);
		this.extractHarbourAbout = this.extractHarbourAbout.bind(this);
		this.extractHarbourRules = this.extractHarbourRules.bind(this);
		this.extractVesselFitWarning = this.extractVesselFitWarning.bind(this);
		this.extractPaymentInformation = this.extractPaymentInformation.bind(this);
		this.listAvailableServices = this.listAvailableServices.bind(this);
		this.extractBerthInformation = this.extractBerthInformation.bind(this);
		this.extractHarbourContactInformation = this.extractHarbourContactInformation.bind(this);
		this.isCheckedInHarbour = this.isCheckedInHarbour.bind(this);
		this.harbourContact = this.harbourContact.bind(this);
		this.doCheckIn = this.doCheckIn.bind(this);
		this.doCheckOut = this.doCheckOut.bind(this);
		this.getCheckInType = this.getCheckInType.bind(this);
		this.showNoVesselWarning = this.showNoVesselWarning.bind(this);
		this.doesSupportBooking = this.doesSupportBooking.bind(this);
		this.extractHarbourBookingInformation = this.extractHarbourBookingInformation.bind(this);
	}

	doesSupportBooking() {
		const {harbour} = this.props,
			{features = {}} = harbour,
			{fast_booking = {value: false}, normal_booking = {value: false}}
				= features;

		return fast_booking.value || normal_booking.value;
	}

	getCheckInType() {
		const {hasActiveBooking} = this.props,
			supportsBooking = this.doesSupportBooking();

		if (hasActiveBooking || !supportsBooking) {
			return CHECK_IN_TYPES.FAST;
		}

		return CHECK_IN_TYPES.BOOKING_BASED;
	}

	doCheckOut(e) {
		e.preventDefault();
		e.stopPropagation();
		const {showModal, harbour} = this.props;
		showModal('checkOut', {harbour, closeOnOutsideClick: false});
	}

	doCheckIn(e) {
		e.preventDefault();
		e.stopPropagation();
		const {showModal, harbour, vesselsList} = this.props,
			checkInType = this.getCheckInType();

		if (isEmpty(vesselsList)) {
			return this.showNoVesselWarning();
		}

		showModal('checkIn', {harbour, checkInType, closeOnOutsideClick: false});
	}

	showNoVesselWarning() {
		const {showModal} = this.props;

		showModal('noVesselWarning', {closeOnOutsideClick: false});
	}

	isCheckedInHarbour() {
		const {harbour} = this.props;
		return harbour.type === 'checked-in';
	}

	harbourContact(property, icon, isUrl = false, prefix = '') {
		const {harbour} = this.props,
			contactDetail = harbour[property],
			emptyData = isEmpty('' + contactDetail),
			contactInfo = isUrl ? (
				<Link target="_blank" to={prefix + contactDetail}>
					{contactDetail}
				</Link>
			) : contactDetail;
		if (!contactDetail || emptyData) {
			return '';
		}
		return (
			<div className="harbour-information-body-contact-sub-section">
				<div className="harbour-item-icon">
					<i className="material-icons md-24 md-white">{icon}</i>
				</div>
				<div className="harbour-information-body-contact-detail">
					{contactInfo}
				</div>
			</div>
		);
	}

	extractHarbourContactInformation() {
		const phone = this.harbourContact('phone', 'phone', true, 'tel:'),
			email = this.harbourContact('email', 'email', true, 'mailto:'),
			web = this.harbourContact('homepage', 'public', true),
			radio = this.harbourContact('radio_frequency', 'router');

		if (isEmpty(phone) && isEmpty(email) && isEmpty(web) && isEmpty(radio)) {
			return '';
		}

		return (
			<div className="harbour-information-body-contact">
				<div className="harbour-information-body-contact-header">
					{gettext('Contact')}
				</div>
				{phone}
				{email}
				{radio}
				{web}
			</div>
		);
	}

	extractBerthInformation() {
		const {harbourBerths} = this.props;

		if (!harbourBerths.berth_count && !harbourBerths.berth_reserved) {
			return '';
		}

		let freeBerth = Math.max(harbourBerths.berth_count - harbourBerths.berth_reserved, 0);
		if (isNaN(freeBerth)) {
			freeBerth = gettext('N/A');
		}

		return (
			<div className="harbour-information-body-section harbour-information-berth-information">
				<div className="harbour-information-body-sub-section">
					<div className="harbour-information-body-section-header">
						{gettext('Berths')}
					</div>
					<div className="harbour-information-sub-section-data">
						{harbourBerths.berth_count || gettext('N/A')}
					</div>
				</div>
				<div className="harbour-information-body-sub-section">
					<div className="harbour-information-body-section-header">
						{gettext('Free')}
					</div>
					<div className="harbour-information-sub-section-data">
						{freeBerth}
					</div>
				</div>
			</div>
		);
	}

	listAvailableServices() {
		const {harbourServices} = this.props;

		if (!harbourServices.length) {
			return '';
		}

		return (
			<div id="services-list" className="harbour-information-body-section">
				<div className="harbour-information-body-section-header">
					{gettext('Available Services')}
				</div>
				<div className="harbour-information-body-section-content harbour-information-services-section-content">
					<div className="harbour-information-services-section-icons">
						{harbourServices.map(service => serviceIcon(service.code))}
					</div>
					<div className="harbour-information-services-see-link">
						<Link to="/harbour/services" className="btn btn-primary harbour-information-services-link">
							{gettext('See All')}
						</Link>
					</div>
				</div>
			</div>
		);
	}

	extractPaymentInformation() {
		const {harbour} = this.props,
			isCheckedInHarbour = this.isCheckedInHarbour(harbour);
		if (!isCheckedInHarbour || !harbour.paymentInformation) {
			return '';
		}
		return (
			<div className="harbour-information-payment-section">
				<div className="harbour-information-payment-section-main">
					<div className="harbour-information-payment-section-main-header">
						{gettext('To Pay On Check Out')}
					</div>
					<div className="harbour-information-payment-section-main-price">
						&euro; {parseFloat(229).toFixed(2)}
					</div>
				</div>
				<div className="harbour-information-payment-section-details">
					<Link to="/" className="btn btn-primary harbour-information-payment-link">
						{gettext('Details')}
					</Link>
				</div>
			</div>
		);
	}

	extractHarbourBookingInformation() {
		const {activeBookings} = this.props;

		if (!activeBookings.length) {
			return '';
		}

		return (
			<div className="harbour-information-bookings-list">
				{activeBookings.map(booking => {
					const {id, date_from, date_to} = booking,
						bookingKey = `harbour-booking-${id}`;

					return <div className="harbour-information-booking-details" key={bookingKey}>
						<BookingIcon booking={booking}/>
						<BookingTime text={gettext('check in')} date={date_from}/>
						<BookingTime text={gettext('check out')} date={date_to}/>
					</div>;
				})}
			</div>
		);
	}

	extractVesselFitWarning() {
		const {harbour, currentVessel} = this.props,
			vesselFits = vesselFitIntoHarbour(currentVessel, harbour),
			isCheckedInHarbour = this.isCheckedInHarbour(harbour);
		if (vesselFits || isCheckedInHarbour) {
			return '';
		}
		return (
			<div className="harbour-information-fit-warning">
				<div className="harbour-item-icon">
					<i className="material-icons md-24 md-red">warning</i>
				</div>
				<div className="harbour-information-fit-warning-text">
					{vesselFitWarning}
				</div>
			</div>
		);
	}

	extractHarbourAbout() {
		const {harbour} = this.props,
			{description} = harbour;
		if (!description) {
			return '';
		}
		return (
			<div className="harbour-information-body-section">
				<div className="harbour-information-body-section-header">
					{gettext('About')}
				</div>
				<div className="harbour-information-body-section-content"
				     dangerouslySetInnerHTML={{__html: description}}>

				</div>
			</div>
		);
	}

	extractHarbourRules() {
		const {harbour} = this.props,
			{rules_txt} = harbour;
		if (!rules_txt) {
			return '';
		}
		return (
			<div className="harbour-information-body-section">
				<div className="harbour-information-body-section-header">
					{gettext('Harbour Rules')}
				</div>
				<div className="harbour-information-body-section-content"
				     dangerouslySetInnerHTML={{__html: rules_txt}}>

				</div>
			</div>
		);
	}

	render() {
		const {harbour, currentLocation, showMore, toggleHarbourDetails} = this.props,
			informationClass = 'harbour-information' + (showMore ? '' : ' more-inactive'),
			harbourIcon = harbourIconImage(harbour.type, harbour, currentLocation),
			harbourDetails = harbourDetailsFor(harbour.type, harbour, currentLocation),
			isCheckedInHarbour = this.isCheckedInHarbour(harbour),
			actionText = isCheckedInHarbour ? gettext('Check Out') : gettext('Check In'),
			checkoutHarbourClass = isCheckedInHarbour ? 'red-button' : 'available',
			actionButtonClass = `btn btn-block ${checkoutHarbourClass}`,
			checkInOutAction = isCheckedInHarbour ? this.doCheckOut : this.doCheckIn,
			harbourAbout = this.extractHarbourAbout(),
			harbourRules = this.extractHarbourRules(),
			vesselFitWarning = this.extractVesselFitWarning(),
			harbourBookingsInformation = this.extractHarbourBookingInformation(),
			paymentInformation = this.extractPaymentInformation(),
			availableServices = this.listAvailableServices(),
			berthInformation = this.extractBerthInformation(),
			harbourContactInformation = this.extractHarbourContactInformation();

		return (
			<div className={informationClass}>
				<div className="harbour-information-header" onClick={toggleHarbourDetails}>
					<div className="harbour-information-basic">
						<div className="harbour-information-icon">
							{harbourIcon}
						</div>
						<div className="harbour-item-details">
							<div className="harbour-item-name">
								{harbour.name}
							</div>
							<div className="harbour-item-type">
								{harbourDetails}
							</div>
						</div>
					</div>
					<div className="harbour-information-action">
						<button onClick={checkInOutAction} className={actionButtonClass}>{actionText}</button>
					</div>
				</div>
				<div className="harbour-information-body">
					{harbourBookingsInformation}
					{vesselFitWarning}
					{paymentInformation}
					{harbourAbout}
					{harbourRules}
					{availableServices}
					{berthInformation}
					{harbourContactInformation}
				</div>
			</div>
		);
	}
}

HarbourInformation.propTypes = {
	harbour: PropTypes.object,
	harbourServices: PropTypes.array,
	harbourBerths: PropTypes.object,
	currentLocation: PropTypes.object,
	currentVessel: PropTypes.object,
	showModal: PropTypes.func.isRequired,
	showMore: PropTypes.bool,
	toggleHarbourDetails: PropTypes.func.isRequired,
	hasActiveBooking: PropTypes.bool.isRequired,
	vesselsList: PropTypes.array.isRequired,
	activeBookings: PropTypes.array.isRequired
};

export {HarbourInformation};