export const getCurrentPosition = () => {
	return new Promise((resolve, reject) => {
		navigator.geolocation.getCurrentPosition((position) => {
			resolve(position);
		}, (error) => {
			reject(error);
		});
	});
};

export const getPositionPoint = (coords) => {
	return {lat: coords.latitude, lng: coords.longitude};
};